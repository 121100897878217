import React from 'react'
import { Modal } from 'antd'
import "./FullHeightModal.css"


const FullScreenModal = ({ open, onClose, title, content,footer }) => {
    let bodyStyle = { borderTop: '1px solid #DBDCDE', width: '100%', position: 'absolute', zIndex: '9999', top: 75, right: 0 }
    if (title === '') {
        bodyStyle = { width: '100%', position: 'absolute', zIndex: '9999', top: 75, right: 0 }
    }
    return (
        <Modal
            open={open}
            onCancel={onClose}
            footer={footer}
            width="80%"
            style={{ top: 0 }}
            bodyStyle={{ display: 'flex', flexDirection: 'column' }}
            title={title}
            wrapClassName='full-height-modal'
            classNames={{
                content: 'Full_modal_content',
                body: 'Full_modal_body'
            }}
        >
            <div style={bodyStyle}></div>
            {content}
      
        </Modal>
    )
}

export default FullScreenModal
