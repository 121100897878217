import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { Form, Input, Button, Col, Row, Modal, Table, Tag, Select, Radio, Popover, Popconfirm, message, } from 'antd'
import { useSelector, useDispatch, } from 'react-redux'
import { InfoCircleTwoTone } from "@ant-design/icons"
import { Link, useHistory, useLocation } from "react-router-dom"
import './index.css'
import StepOne from './component/step/stepOne'
import StepTwo from './component/step/stepTwo'
import StepThree from './component/step/stepThree'
import Achievement from './component/step/Achievement'
import StepFour from './component/step/stepFour'
import Footer from './component/footer'
import StepFive from './component/step/stepFive'
import Lenders from '../Management/Lenders'
import Feature from './component/step/features'
export default function ManagerSetUp(props) {
    const { open, step, showAchievement, showLenders, showPricing, selectLenders } = useSelector(state => {


        return state.manager
    })
    const [isFourText, setFourText] = useState(false)
    const history = useHistory()
    const dispatch = useDispatch()
    const stepComponent = [
        <StepOne></StepOne>,
        <StepTwo></StepTwo>,
        <StepThree></StepThree>,
        <StepFour currentProvider={(e) => {

            setFourText(e)
        }}></StepFour>,
        <StepFive></StepFive>
    ]
    useEffect(() => {
        if (open) {
            history.push('/app/welcome')
        }
        // 
    }, [open])
    const sendMsgToSlack = () => {

        let token = sessionStorage.getItem("ZeitroA")

        fetch("/los/addlenderrequest", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
            },
        }).then((response) => {
            if (response.status !== 200) {
                console.log(
                    "/los/addlenderrequest: Looks like there was a problem. Status Code: " +
                    response.status
                )
                return
            }
        })
    }
    return (
        <>
            {
                <Modal
                    maskClosable={false}
                    wrapClassName={showLenders || showPricing ? 'modal_warp' : ''}
                    classNames={{
                        content: showLenders || showPricing ? 'modal_content' : 'onboarding',
                    }} open={open} width={showLenders ? '80%' : showPricing ? '80%' : '993px'}
                    style={{
                        top
                            : '10%'
                    }}
                    footer={selectLenders ? <Footer isFourText={isFourText} ></Footer> : <div>
                        <div className='lendersLine' style={{ bottom: 83 }}></div>
                        <div className="d-flex align-items-center justify-content-between">
                            <div>
                                <Popconfirm
                                    id="addLenderRequest"
                                    title="Would you like to send a request to add lenders to the Zeitro team?"
                                    description="After submitting your request, Zeitro will reach out to you with instructions on how to add additional lenders to the system. We may require your assistance in obtaining the necessary information for these lenders."
                                    onConfirm={() => {
                                        sendMsgToSlack()
                                        message.success("Your request has been sent, and the Zeitro team will contact you soon.")
                                    }}
                                    onCancel={() => { }}
                                    okText="Send request"
                                    placement="top"
                                    overlayStyle={{ width: 400 }}
                                    cancelText="Cancel"
                                    icon={<InfoCircleTwoTone />}
                                ><a variant="link" style={{ textDecoration: "underline", color: "#325CEB", fontWeight: 500, fontSize: 16 }}>Can't find your lender? </a></Popconfirm>
                            </div>

                            <Button className="addLenderButton" onClick={() => {
                                const Submit = document.querySelector('#addLenderButton')

                                Submit.click()

                            }}>
                                Add
                            </Button>

                        </div>
                    </div>
                    } onCancel={() => {
                        if (step === 0 && showLenders) {
                            dispatch({ type: "SHOW_LENDERS", data: false })
                            dispatch({ type: "SELECT_LENDERS", data: true })
                            return
                        }
                        dispatch({ type: "OPEN", data: false })
                        dispatch({ type: "SELECT_LENDERS", data: true })
                    }}>
                    <div style={{
                        height: (showLenders || showPricing || step == 1) ? '100%' : showAchievement ? 530 : 540,
                        overflowY: 'auto',
                        overflowX: 'hidden'
                    }}>
                        {
                            showPricing ? <Feature></Feature> : <>
                                {
                                    showAchievement ?
                                        <Achievement ></Achievement>
                                        : <>
                                            {
                                                showLenders ? <div className='Achievement'><Lenders isManager={true} ></Lenders></div> : stepComponent[step]
                                            }
                                        </>
                                }
                            </>
                        }
                    </div>


                </Modal>}


        </>


    )
}