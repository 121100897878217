import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux'
import { Modal, Pagination, Button, Image, Dropdown, Row, Col } from 'antd';
import { CloseOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import Interview1003View from "../Interview1003View/Interview1003View";
import { cloneDeep } from 'lodash';
import { Document, Page, pdfjs } from 'react-pdf';
import { updateApplicationByPath } from '../Store/actions'
import './VerifyModal.css';

const mapStateToProps = (state) => {
    return {
        application: state.application,
        progress: state.progress,
        borrower: state.borrower,
    }
}
const mapDispatchToProps = (dispatch) => ({
    updateApplicationByPath: (path, value) => {
        dispatch(updateApplicationByPath(path, value))
    }
})
export default function VerifyModal(props) {
    pdfjs.GlobalWorkerOptions.workerSrc =
        `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const {
        mode, data, open, file, pageNumber, numPages, scale, loanid, onCancel, extractedData, mappingData, complete, loadSuccess, handlePage, handleZoom, manualVerify, handleDocument
    } = props;
    const [hoveredKey, setHoveredKey] = useState(null)
    const [updatedKeys, setUpdatedKeys] = useState({})
    const [data1, setData1] = useState({})
    const [data2, setData2] = useState({})
    const [currentData2, setCurrentData2] = useState({})
    const [initialData2, setInitialData2] = useState({})
    const [isUpdateAll, setIsUpdateAll] = useState(false)
    const [pageWidth, setPageWidth] = useState(0)
    const [changeSaved, setChangeSaved] = useState(false)
    const containerRef = useRef(null)
    useEffect(() => {
        if (open) {
            if (mode === 'smart') {
                const extractedResult = mapData(mappingData, extractedData, extractedData[0].extractedData, 'DocPath')
                const mappingResult = mapData(mappingData, extractedData, props.application, 'StatePath')
                setData1(extractedResult)
                setData2(mappingResult)
                setCurrentData2(mappingResult)
                setInitialData2(mappingResult)
                setHoveredKey({})
                setUpdatedKeys({})
                setIsUpdateAll(false)
            } else {
                const handleResize = () => {
                    if (containerRef.current) {
                        setPageWidth(containerRef.current.offsetWidth - 16)
                    }
                }
                setTimeout(handleResize, 0)
                window.addEventListener('resize', handleResize)
                return () => {
                    window.removeEventListener('resize', handleResize)
                }
            }
        }
    }, [open])

    const verifyCancel = () => {
        onCancel()
    }
    const getValueByPath = (obj, path, defaultValue = undefined) => {
        const parts = path.split('.')
        let result = obj
        for (let part of parts) {
            if (result && typeof result === 'object' && part in result) {
                result = result[part]
            } else {
                return defaultValue
            }
        }
        if (result && typeof result === 'object' && !Array.isArray(result)) {
            return defaultValue
        }
        return result
    }
    const mapData = (mapping, extractedData, source, sourceType) => {
        const result = {}
        Object.keys(mapping).forEach(key => {
            if (typeof mapping[key] === 'object' && mapping[key] !== null) {
                if (mapping[key][sourceType]) {
                    let value = getValueByPath(source, mapping[key][sourceType])
                    if (mapping[key].ValueType && mapping[key].ValueType === 'intstring') {
                        value = parseInt(value)
                    } 
                    result[key] = value
                } else {
                    result[key] = mapData(mapping[key], extractedData, source, sourceType)
                }
            }
        })
        return result
    }
    const countDiscrepancies = (data1, data2) => {
        let count = 0
        const recursiveCount = (obj1, obj2) => {
            Object.keys(obj1).forEach(key => {
                if (typeof obj1[key] === 'object' && obj1[key] !== null) {
                    recursiveCount(obj1[key], obj2[key])
                } else if (obj1[key] !== obj2[key]) {
                    count++
                }
            })
        }
        recursiveCount(data1, data2)
        return count
    }
    const getNestedValue = (obj, path) => {
        return path.reduce((acc, key) => acc && acc[key], obj)
    }

    const setNestedValue = (obj, path, value) => {
        let target = obj
        path.forEach((key, idx) => {
            if (idx === path.length - 1) {
                target[key] = value
            } else {
                target = target[key]
            }
        })
    }
    const updateAll = () => {
        const updatedKeys = {}
        const updatedData2 = cloneDeep(data1)
        const recursiveUpdate = (obj1, obj2, path = []) => {
            Object.keys(obj1).forEach(key => {
                const currentPath = [...path, key]
                const pathString = currentPath.join('.')
                if (typeof obj1[key] === 'object' && obj1[key] !== null) {
                    recursiveUpdate(obj1[key], obj2[key], currentPath)
                } else if (obj1[key] !== obj2[key]) {
                    updatedKeys[pathString] = true
                }
            })
        }
        setIsUpdateAll(true)
        recursiveUpdate(data1, data2)
        setUpdatedKeys(updatedKeys)
        setCurrentData2(updatedData2)
    }

    const undoAll = () => {
        setIsUpdateAll(false)
        setUpdatedKeys({})
        setCurrentData2(cloneDeep(initialData2))
    }
    const handleVerify = () => {
        complete(currentData2, updatedKeys)
    }
    const manualComplete = () => {
        manualVerify()
    }
    const saveProgress = () => {
        setChangeSaved(true)
        setTimeout(() => setChangeSaved(false), 3000)
    }
    const arrayBufferToBlob = (buffer, type) => {
        return new Blob([buffer], { type })
    }
    const createImageURLFromBase64 = (arrayBuffer, type) => {
        const blob = arrayBufferToBlob(arrayBuffer, type)
        return URL.createObjectURL(blob)
    }
    const onDocumentLoadSuccess = (page) => {
        loadSuccess(page)
    }
    const onChangePage = (page, pageSize) => {
        handlePage(page, pageSize)
    }
    const changeZoom = (type) => {
        if (type === 'add') {
            handleZoom(+(Math.min(scale + 0.1, 3).toFixed(1)));
        } else {
            handleZoom(+(Math.max(scale - 0.1, 0.5).toFixed(1)));
        }
    }
    const handleDoc = (e) => {
        handleDocument(e.key)
    }
    const RecursiveTable = ({ data1, data2, level = 0, path = [], hoveredKey, setHoveredKey }) => {
        return (
            <div>
                {Object.keys(data1).map(key => {
                    const isTitle = typeof data1[key] === 'object' && data1[key] !== null
                    const isUpdated = data1[key] !== data2[key]
                    const currentPath = [...path, key]
                    const pathString = currentPath.join('.')
                    const handleMouseEnter = () => {
                        setHoveredKey(pathString)
                    }
            
                    const handleMouseLeave = () => {
                        setHoveredKey(null)
                    }

                    const handleUpdateClick = () => {
                        setHoveredKey(null)
                        setUpdatedKeys(prev => ({ ...prev, [pathString]: true }))
                        setCurrentData2(prev => {
                            const updatedData2 = cloneDeep(prev)
                            setNestedValue(updatedData2, currentPath, data1[key])
                            return updatedData2
                        })
                    }

                    const handleUndoClick = () => {
                        setUpdatedKeys(prev => ({ ...prev, [pathString]: false }))
                        setCurrentData2(prev => {
                            const updatedData2 = cloneDeep(prev)
                            setNestedValue(updatedData2, currentPath, getNestedValue(initialData2, currentPath))
                            return updatedData2
                        })
                    }
        
                    const displayValue = hoveredKey === pathString ? data1[key] : data2[key]
                    const displayStyle = hoveredKey === pathString ? { fontStyle: 'italic', color: '#325CEB'} : { fontStyle: 'italic' }
                    return (
                        <div key={key}>
                            <div style={updatedKeys[pathString] ? {alignItems: 'flex-start'} : {}} className={`field-row ${isTitle ? 'title-row' : ''} ${isUpdated && !isTitle ? 'update-row' : ''}`}>
                                <div className="field-key" style={{ textIndent: `${level * 38}px` }}>
                                    {key}
                                </div>
                                {isTitle ? null : (
                                    <>
                                        <div className="field-value">{data1[key]}</div>
                                        <div className="field-value">
                                            <div style={displayStyle}>{displayValue}</div>
                                            {updatedKeys[pathString] && <div className='undo'>Updated with document data<span onClick={handleUndoClick}>Undo</span></div>}
                                        </div>
                                        <div className='field-action'>
                                            {isUpdated && (
                                                <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleUpdateClick} className='update-btn'>Update</div>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                            {isTitle && (
                                <RecursiveTable data1={data1[key]} data2={data2[key]} level={level + 1} path={currentPath} hoveredKey={hoveredKey} setHoveredKey={setHoveredKey} updatedKeys={updatedKeys} setUpdatedKeys={setUpdatedKeys} />
                            )}
                        </div>
                    )
                })}
            </div>
        )
    }
    const footer = [
        <Button className="verify-cancel-btn" onClick={verifyCancel}>Back</Button>,
        mode === 'smart' ? (
            countDiscrepancies(data1, currentData2) < countDiscrepancies(data1, initialData2) ? <Button className="verify-complete-btn" type="primary" onClick={handleVerify}>Complete verification</Button> : <Button className="verify-skip-btn" type="link" onClick={handleVerify}>Dismiss suggestions and complete verification</Button>
        ) : (
            <div style={{display: 'flex', alignItems: 'center'}}>{changeSaved ? <div className='change-saved'>Changes saved to 1003<img src='/images/document/change-saved.svg' /></div> : <Button style={{marginRight: 30}} className="verify-skip-btn" type="link" onClick={saveProgress}>Save progress</Button>}<Button className="verify-complete-btn" type="primary" onClick={manualComplete}>Complete verification</Button></div>
        )
    ]
    return (
        <>
            <Modal
                className='document-verify-modal'
                maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.85)' }}
                // destroyOnClose
                maskClosable={true}
                width='90%'
                open={open}
                title=""
                onCancel={verifyCancel}
                footer={footer}
            >
                <div className='verify-modal-top'><div>Document verification | {data?.taskName}</div>{mode === 'manual' && <Button className="verify-skip-btn" type="link" onClick={() => handleDoc({key: 'resubmission'})}>Request document resubmission</Button>}</div>
                {
                    mode === 'smart' ? (
                        <div className='verify-modal-content'>
                            <div className='verify-title'>Our AI checked for discrepancies between the document data and the 1003 application form.</div>
                            <div style={countDiscrepancies(data1, currentData2) === 0 ? {backgroundColor: '#E1F2E2'} : {}} className='update-action-bar'>
                                <div className='action-bar-l'>
                                    <img src={`/images/document/verify-${countDiscrepancies(data1, currentData2) === 0 ? 'passed' : 'difference'}.svg`} />
                                    {countDiscrepancies(data1, currentData2) === 0 ? <div style={{color: '#2E605A'}}>All information is correct</div> : <div>{countDiscrepancies(data1, currentData2)} discrepancies were detected. Update the 1003 application form’s data?</div>}
                                </div>
                                {
                                    countDiscrepancies(data1, currentData2) === 0 ? (
                                        isUpdateAll ? <div className='undo-all'><span>All updates applied</span><div className='undo' onClick={undoAll}>Undo</div></div> : null 
                                    ) : (
                                        <div className='update-all' onClick={updateAll}>Update all</div>
                                    )
                                }
                                
                            </div>
                            <div className='update-table-header'>
                                <div className='header-col'></div>
                                <div className='header-col doc'>Document data</div>
                                <div className='header-col application'>1003 Application Form</div>
                                <div style={{width: 95}}></div>
                            </div>
                            <div className='update-table'>
                                <RecursiveTable 
                                    data1={data1} 
                                    data2={currentData2} 
                                    hoveredKey={hoveredKey} 
                                    setHoveredKey={setHoveredKey} 
                                    updatedKeys={updatedKeys} 
                                    setUpdatedKeys={setUpdatedKeys}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className='manual-modal-content'>
                            <Row>
                                <Col style={{position: 'relative'}} span={12}>
                                    <div className='document-preview-content' ref={containerRef}>
                                        <div style={{ width: pageWidth * scale }} className='preview-box'>
                                            {
                                                data?.MimeType === "application/pdf" ? (
                                                    <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                                                        <Page pageNumber={pageNumber} width={ pageWidth * scale } />
                                                    </Document>
                                                ) : (
                                                    <Image
                                                        preview={false}
                                                        width={pageWidth * scale}
                                                        src={createImageURLFromBase64(file, data?.MimeType )}
                                                    />
                                                )

                                            }
                                        </div>
                                    </div>
                                    <div className='document-preview-bottom-bar'>
                                        <div className="document-preview-pagination">
                                            <Pagination size='small' simple current={data?.MimeType === "application/pdf" ? pageNumber : 1} pageSize={1} total={data?.MimeType === "application/pdf" ? numPages : 1} onChange={onChangePage} />
                                        </div>
                                        <div className="document-preview-scale">
                                            <div className='zoom-min'><MinusOutlined style={{ fontSize: 16 }} onClick={() => changeZoom('min')} /></div>
                                            <div className='zoom-value'>{Math.round(scale * 100)}%</div>
                                            <div className='zoom-add'><PlusOutlined style={{ fontSize: 16 }} onClick={() => changeZoom('add')} /></div>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className='form-1003-title'>1003 Application Form</div>
                                    <Interview1003View 
                                        // key={loanid} 
                                        // borrowerid={this.props.borrower.id}
                                        mode='document'
                                        handingOff={false} 
                                        handingOffType={false}
                                    />
                                </Col>
                            </Row>
                        </div>
                    )
                }
            </Modal>
        </>
    );
}
VerifyModal = connect(mapStateToProps, mapDispatchToProps)(VerifyModal);