import React, { useState, useEffect, useRef, forwardRef } from 'react'
import { Row, Col, Form, Input, Upload, Button, Spin, message, Tooltip } from 'antd'
import CropperModal from '../../../Photo/CropperModal'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux'

import "./Company.css"
const { TextArea } = Input
const PopUpCompany = forwardRef(({ info, finish, change, changeProgress }, ref) => {
    const [form] = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage()
    const [loading, setLoading] = useState(false)
    const [cropperVisible, setCropperVisible] = useState(false)
    const [imageSrc, setImageSrc] = useState('')
    const [showUploadBtn, setShowUploadBtn] = useState(false)
    const { step } = useSelector(state => {
        console.log(state.manager)

        return state.manager
    })
    const dispatch = useDispatch()
    useEffect(() => {
        getCompanyProfile()
    }, [])
    const getCompanyProfile = () => {
        setLoading(true)
        let token = sessionStorage.getItem("ZeitroA")
        fetch("/los/getcompanyprofilesetup", {
            cache: 'no-cache',
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
        }).then(response => {
            if (!response.ok) {
                setLoading(false)
            } else {
                response.json().then(js => {
                    setLoading(false)
                    let data = js

                    let formdata = {
                        CompanyNMLS: data.CompanyNMLS,
                        companyWebsite: data.Name,
                        companyLink: data.Link,
                        banner: data.Banner,
                        companyIntroduction: data.Introduction,
                        companyAddress: data.Address,
                        companyPhone: data.Phone,
                        noreplyEmail: data.NoreplyEmail,
                        supportEmail: data.SupportEmail,
                    }

                    form.setFieldsValue(formdata)
                })
            }
        }).catch(error => {
            setLoading(false)
            console.log("Revalidate catch error " + error)
        })
    }

    const setCompanyProfile = (data) => {

        setLoading(true)
        let token = sessionStorage.getItem("ZeitroA")

        fetch('/los/updatecompanyprofilesetup', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + token,
            },
        }).then(
            response => {
                setLoading(false)
                if (response.status !== 200) {
                    return
                }
                getCompanyProfile()
                message.success('Update Success')
                dispatch({ type: "STEP", data: step + 1 })
            }
        ).catch(err => {
            setLoading(false)
            console.log('Fetch Error :-S', err)
        })
    }
    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
        }
    }
    const readFileAsDataURL = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = (e) => resolve(e.target.result)
            // reader.onload = (e) => resolve(e.target.result.replace("data:", "").replace(/^.+,/, ""))
            reader.onerror = (error) => reject(error)
        })
    }

    const handleUploadChange = async ({ file, fileList }) => {
        if (file.status === 'done') {
            const base64Data = await readFileAsDataURL(file.originFileObj)
            const newFileList = fileList.map((f) => {
                if (f.uid === file.uid) {
                    return { ...f, url: base64Data }
                }
                return f
            })
            form.setFieldsValue({
                file: newFileList,
            })
            setImageSrc(base64Data)
            setCropperVisible(true)
        } else if (file.status === 'error') {

        }
    }
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e?.fileList
    }
    const handleCustomRequest = (options) => {
        const { onSuccess } = options
        onSuccess()
    }
    const uploadBtn = () => {
        return showUploadBtn && <div className='upload-btn'><img src='/images/upload-img.svg' /></div>
    }
    const handleCropper = () => {
        const base64Data = form.getFieldValue('file')[0].url
        setImageSrc(base64Data)
        setCropperVisible(true)
    }
    const itemRender = (originNode, file, fileList, actions) => (
        <div style={{ marginTop: '6px', display: 'flex' }}>
            <img onClick={handleCropper} className='profile-photo' src={file.url}></img>
            <img className='remove-photo' src='/images/remove.svg' onClick={() => { actions.remove(file) }} />
        </div>
    )
    const handleValuesChange = async (changedValues, allValues) => {
        if (changedValues.file && changedValues.file.length > 0) {
            setShowUploadBtn(false)
        } else {
            setShowUploadBtn(true)
        }
    }
    const validateForm = () => {


        form.validateFields().then((values) => {


            let data = {
                Name: values.companyWebsite,
                Banner: values.banner,
                Introduction: values.companyIntroduction,
                Link: values.companyLink,
                CompanyNMLS: values.CompanyNMLS,
                Address: values.companyAddress,
                Phone: values.companyPhone,
                NoreplyEmail: values.noreplyEmail,
                SupportEmail: values.supportEmail,
            }


            setCompanyProfile(data)
        }).catch((errorInfo) => {

            console.log('Validation failed:', errorInfo)
        })
    }
    const handleSave = (croppedImage) => {
        const formValues = form.getFieldValue()
        let imgList = form.getFieldValue('file')
        const newFileList = imgList.map((f) => {
            return { url: croppedImage }
        })
        form.setFieldsValue({
            file: newFileList,
        })
    }
    return (
        <div className="company-info-setup ">
            {contextHolder}
            <Spin spinning={loading}>
                <div className='form-wrap'>
                    <Form
                        className='company-info-form company-info-form-popUp'
                        layout='vertical'
                        form={form}
                        onFinish={validateForm}
                        onValuesChange={handleValuesChange}
                        validateMessages={validateMessages}
                        size="large"
                    >
                        <Row gutter={32}>
                            <Col span={12} sm={12}>
                                <Form.Item
                                    name="companyWebsite"
                                    label="Company name"
                                >
                                    <Input placeholder="" maxLength={40} />
                                </Form.Item>
                            </Col>
                            <Col span={12} sm={12}>
                                <Form.Item
                                    name="CompanyNMLS"
                                    label="Company NMLS"
                                >
                                    <Input placeholder="" maxLength={200} />
                                </Form.Item>

                            </Col>

                        </Row>
                        <Row gutter={32}>
                            <Col span={12} sm={12}>
                                <Form.Item
                                    name="companyLink"
                                    label="website link"
                                >
                                    <Input placeholder="" maxLength={200} />
                                </Form.Item>

                            </Col>
                            <Col span={12} sm={12}>
                                <Form.Item
                                    name="companyPhone"
                                    label="Company phone number"
                                >
                                    <Input placeholder="" maxLength={20} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item
                            name="companyAddress"
                            label="Company address"
                        >
                            <Input placeholder="" maxLength={200} />
                        </Form.Item>
                        <Row gutter={32}>
                            <Col span={12} sm={12}>
                                <Form.Item
                                    name="noreplyEmail"
                                    label={<div className='d-flex align-items-center'> No-reply email

                                        <Tooltip placement="topLeft" title={
                                            <div style={{ padding: 8 }}>
                                                Your company's noreply email is the address used for sending automated emails that don't require a response. It's typically used for notifications and updates.
                                            </div>
                                        }>
                                            <QuestionCircleOutlined style={{ marginLeft: 10 }} />
                                        </Tooltip>
                                    </div>}
                                >
                                    <Input placeholder="" maxLength={200} />
                                </Form.Item>
                            </Col>
                            <Col span={12} sm={12}>
                                <Form.Item
                                    name="supportEmail"
                                    label={<div className='d-flex align-items-center'> Support email

                                        <Tooltip placement="topLeft" title={<div style={{ padding: 8 }}>
                                            Your company's support email is the address where customers can reach out for assistance, questions, or support inquiries.
                                        </div>} >
                                            <QuestionCircleOutlined style={{ marginLeft: 10 }} />
                                        </Tooltip>
                                    </div>}
                                >
                                    <Input placeholder="" maxLength={200} />
                                </Form.Item>
                            </Col>
                        </Row>
                        {/* <Form.Item
                            name="banner"
                            label="Company banner"
                        >
                            <Input placeholder="" maxLength={200} />
                        </Form.Item>
                        <Form.Item
                            name="companyIntroduction"
                            label="Company introduction "
                        >
                            <TextArea placeholder="" maxLength={200} />
                        </Form.Item> */}
                        <Button type="primary" id='validateForm' htmlType='submit' style={{ opacity: 0 }}>
                            Submit
                        </Button>
                    </Form>

                </div>
            </Spin>
            <CropperModal
                visible={cropperVisible}
                imageSrc={imageSrc}
                onClose={() => setCropperVisible(false)}
                onSave={handleSave}
            />
        </div>
    )
})
export default PopUpCompany