


const initState = {
    open: false,
    step: 0,
    customerLenders: 0,
    showAchievement: false,
    showLenders: false,
    showPricing: false,
    first: false,
    selectLenders:true 

}
export default function Manager(state = initState, action) {
    const { type, data } = action


    switch (type) {
        case 'SELECT_LENDERS' :{

            const newState = { ...state, selectLenders: data }
            return newState
            break
        }
        case 'FIRST': {

            const newState = { ...state, first: data }

            return newState
            break
        }
        case 'OPEN': {
            const newState = { ...state, open: data }

            return newState
            break
        }
        case 'STEP': {
            const newState = { ...state, step: data }

            return newState
            break
        }
        case 'LENDERS_LENGTH': {
            const newState = { ...state, customerLenders: data }

            return newState
            break
        }
        case 'SHOW_ACHIEVEMENT': {
            const newState = { ...state, showAchievement: data }

            return newState
            break
        }
        case 'SHOW_LENDERS': {
            const newState = { ...state, showLenders: data }

            return newState
            break
        }
        case 'SHOW_PRICiNG': {
            const newState = { ...state, showPricing: data }

            return newState
            break
        }


        default:
            break
    }
    return state
}