import React, { useEffect, useState } from "react";

import { commaizeFloat } from "../../Common";

import { Table,Tabs, Switch, Popover, Button, Modal, Tooltip, Input, Select,Row, Divider} from 'antd';

import Lenders from "../../Management/Lenders";
import { checkAccess } from "../../Auth";
import { UR_Owner, UR_LoanOfficer, UR_PriceEngineUser } from "../../State";
import * as com from "../../Common";



const typemap = {
  fixed30: "30 Year",
  fixed25: "25 Year",
  fixed20: "20 Year",
  fixed15: "15 Year",
  fixed10: "10 Year",
  arm56: "ARM 5/6",
  arm76: "ARM 7/6",
  arm106: "ARM 10/6",
}

const loanTermLabelMap = {
  fixed30: "30 Year Fixed",
  fixed25: "25 Year Fixed",
  fixed20: "20 Year Fixed",
  fixed15: "15 Year Fixed",
  fixed10: "10 Year Fixed",
  arm56: "5/6 ARM",
  arm76: "7/6 ARM",
  arm106: "10/6 ARM",
}

const skipKeywordsMap={
  "Agency": ["conforming","highbalance", "high-balance","high_balance","super_conforming","superconforming","super-conforming","agency"],
  "Jumbo": ["jumbo","fha_jumbo","va_jumbo"],
  "FHA": ["fha","fha_high_balance","fha_jumbo"],
  "VA": ["va","va_high_balance","va_jumbo"],
  "USDA": ["usda"],
  "Non-QM": ["nonqm", "non-qm","non_qm"],
}

export default function PricingResults(props) {

  const [allresults,setAllResults] = useState(0);
  const [connectedresults,setConnectedResults] = useState(0);
  const [activeTabKey, setActiveTabKey] = useState("fixed30");
  const [currentTarget,setCurrentTarget] = useState('price');
  // const [targetprice,setTargetPrice] = useState(3000);
  const [targetrate,setTargetRate] = useState(6.5);
  const [showAddLenderModal, setShowAddLendersModal] = useState(false);
  const [useDiscountFormat, setUseDiscountFormat] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showShareRateModal, setShowShareRatesModal] = useState(false);

  const { TextArea } = Input;
  
  let AllresultsCount={}
  let ConnectedresultsCount={}
  useEffect(() => {
    setAllResults(AllresultsCount[activeTabKey]);
    setConnectedResults(ConnectedresultsCount[activeTabKey])
  }, [activeTabKey,props.productTypes])


  const getCurrentDate = () =>{
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    var today = new Date();

    const dayOfWeek = today.getDay();
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      // If today is Sunday (0), subtract 2 days to get Friday
      // If today is Saturday (6), subtract 1 day to get Friday
      today = new Date(today.getFullYear(), today.getMonth(), today.getDate() - (dayOfWeek === 0 ? 2 : 1));
    }

    return today.toLocaleDateString("en-US", options);
  }

 const maincolumns = [
    {
      title: 'Lender programs',
      dataIndex: 'program',
      key: 'program',
      width:"150px",
      className: "outerRateColumn",
      render:(_,record)=>(
        <Tooltip
        placement="topLeft"
          title={
            <div>
              <div><strong>{record.Lender}</strong></div>
              <div><strong style={{fontSize:"12px"}}>{record.Name}</strong></div>
              <div style={{fontSize:"10px", color:"#white",marginTop:"5px"}}>{`Last Updated on ${getCurrentDate()} 09:00`}</div>
            </div>
          }
        >
          <div>
            <Row><strong style={{fontSize:"16px"}}>{com.divideStringByCapitalLetter(record.Lender)}</strong></Row>
            <Row>{record.Name}</Row>
          </div>
        </Tooltip>
      ),
      sorter:(a, b) => a.program.localeCompare(b.program),
      sortDirections: ['descend','ascend','descend'],
    },

    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      width:"70px",
      className: "outerRateColumn",
      render:(_,record)=>(
        <>
          <strong style={{color:currentTarget==='rate' && record.isConnected ?"#1677FF":''}}>{record.rate.toFixed(3)}%</strong>
        </>
      ),
      sorter:(a, b) => {
          return a.rate - b.rate;
      },
      sortDirections: ['ascend','descend',"ascend"],
    },
    {
      title: 'Monthly payment',
      dataIndex: 'monthlypay',
      key: 'monthlypay',
      width:"90px",
      className: "outerRateColumn",
      render:(_,record)=>(
        <div>
          <strong>${commaizeFloat(record.monthlypay)}</strong>
          {record.isConnected && <div style={{fontSize:"12px",color: com.divideStringByCapitalLetter(record.Lender) === "GMCC Celebrities" && record.dti.toFixed(0) > 45 ?"#C21818":"#6E6E70",fontWeight:"500"}}>Final DTI : {record.dti.toFixed(0)} %</div>}
        </div>
      ),
      sorter:(a, b) => a.monthlypay - b.monthlypay,
      sortDirections: ['descend','ascend','descend'],
    },
    {
      title: <Tooltip title="The adjusted price is calculated by adding the price adjustment points to the lock day price.">Total pricing</Tooltip>,
      dataIndex: 'originalprice',
      key: 'originalprice',
      width:"130px",
      className: "outerRateColumn",
      render:(_,record)=>{
        let adjustedPrice = useDiscountFormat ? parseFloat(record.originalprice).toFixed(3) +"%" : (100 - parseFloat(record.originalprice)).toFixed(3)
        return <Popover content={record.popoverContent} title=''>
          <div style={{display:"flex",alignItems:"center"}}>
            <strong style={{textDecoration:"underline",color: !record.isConnected?"":record.originalprice<=0 ? "#208029": "#C21818"}} >
              {adjustedPrice} / ${commaizeFloat(parseFloat(record.originalprice * record.loanAmount*0.01).toFixed(0))}
            </strong> 
          </div>
        </Popover>
      },
      sorter:(a, b) => a.originalprice - b.originalprice,
      sortDirections: ['descend','ascend','descend'],
    },
    {
      title: <Tooltip title='The lender price compensation or the broker price compensation'>LPC/BPC</Tooltip>,
      dataIndex: 'compensationprice',
      key: 'compensationprice',
      width:"110px",
      className: "outerRateColumn",
      render:(_,record)=>(
        <strong>{record.isConnected ? record.prices.CompensationPoint.toFixed(3) + "% / $"+commaizeFloat(record.compensationprice) : "---"}</strong>
      ),
      sorter:(a, b) => a.compensationprice - b.compensationprice,
      sortDirections: ['descend','ascend','descend'],
    },

    {
      title: <Tooltip title='The total price is the sum of the lock day price, adjustment points, and compensation points.'>Net pricing</Tooltip>,
      dataIndex: 'points',
      key: 'points',
      width:"120px",
      className: "outerRateColumn",
      render:(_,record)=>{
        let p = useDiscountFormat ? parseFloat(record.points).toFixed(3) + "%" : parseFloat(100 - record.points).toFixed(3)

        return <>
          {record.isConnected? <div>
            <Popover content={record.netpricingPopContent} title=''>
              <div style={{display:"flex",alignItems:"center"}}>
                <strong style={{textDecoration:"underline",color: record.lender_credit<=0 ? "#208029":"#C21818"}}>
                  {p+" / $"+com.commaizeFloat(record.lender_credit.toFixed(0))}
                </strong>
              </div>
            </Popover>
            <div style={{fontSize:"12px",color:"#6E6E70",fontWeight:"500"}}>Est. Closing cost: ${record.closing_cost }</div>
          </div>
          :
          <div>---</div>
          }</>
      },
      sorter:(a, b) => {
        if (a.points !== b.points){
          return a.points - b.points;
        } else {
          return a.rate - b.rate;
        }
      },
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend','descend','ascend'],
    },
    // {
    //   title: '',
    //   key: 'share',
    //   align: "center",
    //   width:"30px",
    //   render: (_, record) => (
    //     checkAccess([UR_Owner]) && !record.isConnected ? <div><img src="/images/priceengine/addLendersIcon.png" onClick={()=>setShowAddLendersModal(true)} style={{cursor: "pointer", width:32}} /></div> : ""
    //   ),
    // },
    Table.EXPAND_COLUMN,
  ];
  
  const innerColumns=[
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      width:"60px",
      className: "innerRateColumn",
      sorter:(a, b) => a.rate - b.rate,
      sortDirections: ['descend','ascend','descend'],
      defaultSortOrder: 'ascend',
      render:(_,record)=>(
        <>
         <strong >{record.rate}%</strong>
        </>
      ),
    },
    {
      title: 'PMT',
      dataIndex: 'monthlypay',
      key: 'monthlypay',
      width:"90px",
      className: "innerRateColumn",
      render:(_,record)=>(
        <div>
          <strong >${commaizeFloat(record.monthlypay)}</strong>
          <div style={{fontSize:"12px",color: com.divideStringByCapitalLetter(record.Lender) === "GMCC Celebrities" && record.dti.toFixed(0) > 45 ?"#C21818":"#6E6E70",fontWeight:"500"}}>Final DTI : {record.dti.toFixed(0)} %</div>
        </div>
      ),
    },
    {
      title: 'Total pricing',
      dataIndex: 'originalprice',
      key: 'originalprice',
      width:"150px",
      sorter:(a, b) => a.originalprice - b.originalprice,
      sortDirections: ['descend','ascend','descend'],
      className: "innerRateColumn",
      render:(_,record)=>{
        let adjustedPrice = useDiscountFormat ? parseFloat(record.originalprice).toFixed(3) +"%" : (100 - parseFloat(record.originalprice)).toFixed(3)
        return <Popover content={record.popoverContent} title=''>
            <div style={{display:"flex",alignItems:"center"}}>
              <strong style={{textDecoration:"underline",color: !record.isConnected?"": record.originalprice<=0 ? "#208029": "#C21818"}} >
                {adjustedPrice} / ${commaizeFloat(parseFloat(record.originalprice * record.loanAmount*0.01).toFixed(0))}
              </strong>
            </div>
        </Popover>
      },
    },
    {
      title: 'LO Comp.',
      dataIndex: 'compensationprice',
      key: 'compensationprice',
      width:"130px",
      className: "innerRateColumn",
      render:(_,record)=>(
        <strong>{record.isConnected ? record.prices.CompensationPoint.toFixed(3) + "% / $"+commaizeFloat(record.compensationprice) : "---"}</strong>
      ),
    },
    {
      title: <Tooltip title='The total price is the sum of the lock day price, adjustment points, and compensation points.'>Net pricing</Tooltip>,
      dataIndex: 'points',
      key: 'points',
      width:"130px",
      className: "innerRateColumn",
      render:(_,record)=>{
        let p = useDiscountFormat ? parseFloat(record.points).toFixed(3) + "%" : parseFloat(100 - record.points).toFixed(3)
        return <>
        {record.isConnected?
          <Popover content={record.netpricingPopContent} title=''>
            <div>
              <div style={{display:"flex",alignItems:"center"}}>
                <strong style={{textDecoration:"underline",color: record.lender_credit<=0 ? "#208029":"#C21818"}}>
                  {!record.isConnected ? "---":  p+" / $"+com.commaizeFloat(record.lender_credit.toFixed(0))}
                </strong>
              </div>
              <div style={{fontSize:"12px",color:"#6E6E70",fontWeight:"500"}}>Est. Closing cost: ${record.closing_cost}</div>
            </div>
          </Popover>
          :
          <div>---</div>
        }</>
      },
      sorter:(a, b) => a.points - b.points,
      sortDirections: ['descend','ascend','descend'],
    },
  ]

  const ineligiblecolumns = [
    {
      title: 'Lender',
      dataIndex: 'lender',
      key: 'lender',
      width:"90px",
      className: "ineligibleColumn",
      render:(_,record)=>(
        <div>
          <strong>{record.Lender}</strong>
        </div>
      ),
    },
    {
      title: 'Ineligible reason',
      dataIndex: 'reason',
      key: 'reason',
      width:"90px",
      className: "ineligibleColumn",
      render:(_,record)=>(
        <div>
          <div className="d-flex"><strong className="mr-2">Lender source problem:</strong> {record.LenderLevelReason?record.LenderLevelReason:"N/A"}</div>
          {record.ProductLevelReasons && record.ProductLevelReasons.map((reason)=>{
            return <div className="d-flex"><strong className="mr-2">Product problem:</strong> {reason.ProductName}-{reason.IneligibleReason}</div>
          })}
        </div>
      ),
    },
  ]

  const calcTargetPricing = (data) => {
    let target = 0
    if (props.targetOptions.includes("lockpoint")) {
      target += data.LockDayPoint
    }
    if (props.targetOptions.includes("adjustment")) {
      target +=  data.AdjustmentPoint
    }
    if (props.targetOptions.includes("compensation")) {
      target +=  data.CompensationPoint
    }
    return parseFloat(target.toFixed(3))
  }

  let mtypes = [];
  let lenderSet = {}
  let lenderRates={}
  let lenderPrices={}
  let ineligibles = []
 
  for (let [name, value] of Object.entries(props.ineligibleProducts)) {
    if (props.selectedlenders.includes(name)) {
      ineligibles.push({
        Lender: name,
        ProductLevelReasons: value.ProductLevelReasons,
        LenderLevelReason: value.LenderLevelReason
      })
    }
  }
  
  for (let mtype in props.allRates) {
    for (let d of props.allRates[mtype]){
      let tmpname= d.Lender+' - '+d.Name
      if (typeof(lenderRates[tmpname]) === "undefined"){
        lenderRates[tmpname]=[]
        lenderPrices[tmpname]=[]
     
      }
      // for finding targeting price
      lenderRates[tmpname].push(d.base_rate);
      lenderPrices[tmpname].push(calcTargetPricing(d.prices));
    }
    mtypes.push(mtype)
    if (typeof lenderSet[mtype] === "undefined") {
      lenderSet[mtype] = new Set()
    }
    
    for (let d of props.allRates[mtype]) {
      lenderSet[mtype].add(d.Lender + "-" + d.Name);
    }
  }

  for (let m in lenderSet) {
    lenderSet[m] = Array.from(lenderSet[m]).sort((a, b) => {
      return a.toLowerCase().localeCompare(b.toLowerCase());
  })
  }

  const compareTarget=(target,v,targettype,pname)=>{
    if(targettype === "rate"){
      let differences=lenderRates[pname].map(element=>Math.abs(element-target));
      let minval=Math.min(...differences);
      let minidx = differences.indexOf(minval);
      return v === lenderRates[pname][minidx]
    }
    if(targettype === "price"){
      let differences=lenderPrices[pname].map(element=>Math.abs(element-target));
      let minval=Math.min(...differences);
      let minidx = differences.indexOf(minval);
      return v === lenderPrices[pname][minidx];
    }
  }

  const generateTableData = (ratesData, mtype,targettype) => {
    let tmpOuttableonRate = [];
    let tmpOuttableonPrice = [];
    let tmpIntable={}
    let idx=1;
    for (let d of ratesData){
      let mindiff=false
      let tmpname= d.Lender+' - '+d.Name
      let isConnected = (props.connectedLendersMap[d.Lender] && props.connectedLendersMap[d.Lender] === true )? true : false

      if (typeof(tmpIntable[tmpname]) === "undefined"){
        tmpIntable[tmpname]=[]
      }
      let adjs=[]
      for (let adj in d.Adjustments){
        let i = adjs.length
        adjs.push(
          <div className="d-flex justify-content-between" style={{fontSize: 12, color:"rgb(148 148 148)"}}>
            <div className="d-flex">
              <div style={{width:20}}>{i!=0?"+":""}</div>
              <div>{adj}</div>
            </div>
            <div>{parseFloat(d.Adjustments[adj]).toFixed(3)}%</div>
          </div>
        )
      }
      let originalprice = parseFloat(d.prices.LockDayPoint + d.prices.AdjustmentPoint).toFixed(3)
      let Content=(
        <div className="pointsBreakdown">
          <div className="bold mb-2">Adjusted price calculation</div>
          {adjs}
          <hr />
          <div className="d-flex justify-content-between">
            <div className="ml-3">Total adjustment points</div>
            <div>{parseFloat(d.prices.AdjustmentPoint).toFixed(3)}%</div>
          </div>
          <div className="d-flex justify-content-between">
            <div>{"+   Lock day points"}</div>
            <div>{parseFloat(d.prices.LockDayPoint).toFixed(3)}%</div>
          </div>
          <hr/>
          <div className="d-flex justify-content-between">
            <div>Total</div>
            <div>{originalprice}%</div>
          </div>          
        </div>
      )

      let netpricingContent = (
        <div style={{padding:"15px 10px"}}>
          <div className="mb-4" style={{fontWeight:"500"}}>Net pricing Calculation:</div>
          <div>Total pricing:  {originalprice}% / ${commaizeFloat(parseFloat(originalprice * d.loanAmount*0.01).toFixed(0))}</div>
          <div>Compensation:  {parseFloat(d.prices.CompensationPoint.toFixed(3))}% / ${commaizeFloat(parseFloat(d.prices.CompensationPoint * d.loanAmount*0.01).toFixed(0))}</div>
          {d.FHAMIP !== undefined && <div>Upfront MIP: {100*d.FHAMIP.upfrontMIP/d.loanAmount}% / ${d.FHAMIP.upfrontMIP}</div>}
          <hr/>
          <div>Net pricing:  {d.FHAMIP !== undefined ? d.points + (100*d.FHAMIP.upfrontMIP/d.loanAmount) : d.points}% / ${d.FHAMIP !== undefined? commaizeFloat(parseFloat(d.points * d.loanAmount*0.01 + d.FHAMIP.upfrontMIP).toFixed(0)) : commaizeFloat(parseFloat(d.points * d.loanAmount*0.01).toFixed(0))}</div>
        </div>
      )
      
      if(targettype==="rate" && compareTarget(targetrate,d.base_rate,targettype,tmpname)){
        tmpOuttableonRate.push({
          key:idx, 
          isConnected: isConnected,
          program:tmpname,
          rate: d.base_rate,
          price: parseFloat(d.lender_credit).toFixed(0),
          originalprice: originalprice,
          compensationprice: parseFloat(d.prices.CompensationPoint).toFixed(0),
          monthlypay: d.monthly_payment,
          dti: d.DTI,
          popoverContent:Content,
          netpricingPopContent:netpricingContent,
          fhamip: d.FHAMIP,
          pricedetails: d.prices,
          adjustments:d.Adjustments,
          ...d,
        })
        idx+=1;
        mindiff=true;
      }

  
      if(targettype==="price" && compareTarget(100 - parseFloat(props.targetPrice), calcTargetPricing(d.prices),targettype,tmpname)){
        tmpOuttableonPrice.push({
          key:idx, 
          isConnected: isConnected,
          program:tmpname,
          rate: d.base_rate,
          price: parseFloat(d.lender_credit).toFixed(0),
          originalprice: originalprice,
          compensationprice: parseFloat(d.prices.CompensationPoint * d.loanAmount/100).toFixed(0),
          monthlypay: d.monthly_payment,
          dti: d.DTI,
          popoverContent:Content,
          netpricingPopContent:netpricingContent,
          fhamip: d.FHAMIP,
          pricedetails: d.prices,
          adjustments:d.Adjustments,
          ...d,
        })
        idx+=1;
        mindiff=true;
      }
      tmpIntable[tmpname].push({
        program:tmpname,  
        pricedetails: d.prices,
        isConnected: isConnected,
        rate: d.base_rate,
        price: parseFloat(d.lender_credit).toFixed(0),
        originalprice: originalprice,
        compensationprice: parseFloat(d.prices.CompensationPoint * d.loanAmount/100).toFixed(0),
        monthlypay:parseFloat(d.monthly_payment).toFixed(0),
        dti: d.DTI,
        isMin:mindiff,
        popoverContent:Content,
        netpricingPopContent:netpricingContent,
        fhamip: d.FHAMIP,
        ...d,
      })
      
    }
    tmpOuttableonPrice = tmpOuttableonPrice.filter( d => props.selectedlenders.includes(d.program.split(" - ")[0]) )
    tmpOuttableonPrice.sort((a,b)=>a.base_rate-b.base_rate);
    if (targettype==="rate"){
      return [tmpOuttableonRate,tmpIntable];
    }
    if (targettype==="price"){
      return [tmpOuttableonPrice,tmpIntable];
    }
  }
  
  let all={}
  let outdata={}
  let minRate=0;
  const tabdata=[];
  let idx=1;  
  

  for(let mtype in props.allRates){
    [outdata[mtype],all[mtype]]=generateTableData(props.allRates[mtype],mtype,currentTarget);
    AllresultsCount[mtype]=outdata[mtype].length
    ConnectedresultsCount[mtype]=outdata[mtype].filter(d => d.isConnected).length
    if (outdata[mtype].length > 0) {
      minRate = outdata[mtype][0].base_rate
      outdata[mtype]=[]
      all[mtype] = Object.fromEntries(
        Object.entries(all[mtype]).filter(([key, value]) => 
            props.selectedlenders.includes(key.split(" - ")[0])
        )
      );
      let indx = 1;
      for (let key of Object.keys(all[mtype])){
        let d = all[mtype][key]
        let sample = d.find( ele => ele.rate === minRate)
        if (sample !== undefined){
          outdata[mtype].push({
            key:indx,
            ...sample
          })
        }
        else{
          let results = d.filter(ele => ele.rate > minRate)
          let minObj = results.reduce((min, obj)=> obj.rate < min.rate ? obj : min, results[0])
          outdata[mtype].push({
            key:indx,
            ...minObj
          })
        }
        indx+=1
      }
      AllresultsCount[mtype]=outdata[mtype].length
      ConnectedresultsCount[mtype]=outdata[mtype].filter(d => d.isConnected).length
    }
    tabdata.push({
      tabTitle:typemap[mtype],
      intabledata: all[mtype],
      outtabledata:outdata[mtype],
      tabkey:mtype
    })
    idx+=1
  }
  
  let targetTab = tabdata.find(tab=>tab.tabkey===props.loanterm)
  console.log(targetTab)

  return (
    <div className="text-left w-100" style={{height:"90%"}}>
      <div className="d-flex justify-content-end  align-items-center my-2" >
        {selectedRows.length >0 && <Button type="primary" className="mr-2" onClick={()=>setShowShareRatesModal(true)}>Share</Button>}
      </div>
      {props.eligibilityView!=="ineligible" && <div style={{marginRight:"5px",textAlign:"right",color:"#6E6E70", fontSize:"12px", fontWeight:"500"}}>*Search will return lowest rate at which target price first occurs</div>}
      {props.eligibilityView === "ineligible"?
        <Table
          style={{width:"100%"}}
          className="ineligibletable"
          pagination={false}
          columns={ineligiblecolumns}
          rowClassName="ineligibleRow"
          dataSource={ineligibles}
          locale={{emptyText: <div style={{color:"#6E6E70"}}>{'No matched reason'}</div>}}
          scroll={{
            y: checkAccess((UR_PriceEngineUser)) ? props.viewportH- 280 : props.viewportH*0.75
          }}
        />
      :
        <Table
          style={{width:"100%"}}
          className="outertable"
          dataSource={props.showConnectedLendersOnly ? targetTab.outtabledata.filter(d => d.isConnected && props.selectedlenders.includes(d.program.split(" - ")[0])) : targetTab.outtabledata.filter(d => props.selectedlenders.includes(d.program.split(" - ")[0]))}
          pagination={false}
          columns={maincolumns}
          scroll={{
            y: checkAccess((UR_PriceEngineUser)) ? props.viewportH- 280 : props.viewportH*0.75
          }}
          rowClassName={(record,_)=> record.isConnected ? "connectedLenderRow":"unconnectedLenderRow"}
          showSorterTooltip={false}
          expandable={{
            expandedRowRender: (record) => (
              <div>
                {/* {!record.isConnected && <div className="notAddedLenderLabel"><Tooltip className="d-flex align-items-center" title="This wholesale lender is not currently added to your list of lenders. To connect with this lender, click the + button."> Not added yet <QuestionCircleOutlined style={{color:"#fffff", marginLeft:5}} />  </Tooltip></div>} */}
                <div >
                  <div className="px-3 d-flex justify-content-between align-items-center" style={{height:"240px"}}>
                    <div className="productDetails" style={{width:"30%",height:"100%"}}>
                      <div className="bold" style={{fontSize:"14px",color:"#586570", borderBottom:"1px solid #DBDCDE"}}>Loan summary</div>
                      <div className="d-flex justify-content-between mt-2">
                            <div>Loan amount:</div>
                            <div>${commaizeFloat(record.loanAmount)}</div>
                      </div>
                      <div className="d-flex justify-content-between">
                            <div>Loan term:</div>
                            <div>{(props.loanterm).toUpperCase()}</div>
                      </div>
                      <div className="d-flex justify-content-between">
                            <div>LTV:</div>
                            <div>{props.loanInfo.ltv[0]}%</div>
                      </div>
                      <div className="d-flex justify-content-between">
                            <div>FICO:</div>
                            <div>{props.loanInfo.fico}</div>
                      </div>
                      <div className="d-flex justify-content-between">
                            <div>Escrow:</div>
                            <div>{props.loanInfo.escrow===true?"NO":"YES"}</div>
                      </div>
                      <div className="d-flex justify-content-between">
                            <div>Lender origination fee:</div>
                            <div>${record.lender_fee}</div>
                      </div>

                    </div>
                    <div className="productDetails" style={{width:"68%",height:"100%"}}>
                      <div className="d-flex justify-content-between bold" style={{fontSize:"14px",color:"#586570", borderBottom:"1px solid #DBDCDE"}}>
                        <div>About this product</div>
                        <div style={{fontSize:"12px"}}>*Last Updated on {getCurrentDate()} 09:00</div>
                      </div>
                      <div className="d-flex" style={{height:"180px"}}>
                        <div style={{width:"100%",paddingRight:"15px"}}>
                          <div className="d-flex justify-content-between mt-2">
                                <div>Lender</div>
                                <div>{com.divideStringByCapitalLetter(record.Lender)}</div>
                          </div>
                          <div className="d-flex justify-content-between">
                                <div>Product name</div>
                                <div>{record.Name}</div>
                          </div>
                          <div className="d-flex justify-content-between ">
                                <div>Underwriting</div>
                                <div>{record.Aus.join("/")}</div>
                          </div>
                          {record.pricedetails.AdjustmentPoint && <div className="d-flex justify-content-between">
                                <div>Applied adjustments</div>
                                <div>{record.pricedetails.AdjustmentPoint.toFixed(3)}%</div>
                          </div>}
                          {
                            record.Adjustments && Object.entries(record.Adjustments).map(([key, value]) => (
                              <div className="d-flex justify-content-between" style={{color:"#6E6E70"}}>
                                <div>- {key}</div>
                                <div>{value}%</div>
                              </div>
                            ))
                          }
                        </div>

                        {/* <div style={{width:"45%",paddingLeft:"15px"}}>
                            <div className="mt-2 d-flex justify-content-between">
                                <div>Lock day:</div>
                                <div>{record.lockDay}</div>
                            </div>
                            {targetTab.intabledata[record.program][0].fhamip && <>
                              <div className="d-flex justify-content-between">
                                  <div>Upfront MIP</div>
                                  <div>${targetTab.intabledata[record.program][0].fhamip.upfrontMIP.toFixed(0)}</div>
                              </div>
                              <div className="d-flex justify-content-between">
                                    <div>Monthly MIP</div>
                                    <div>${targetTab.intabledata[record.program][0].fhamip.monthlyMIP.toFixed(0)}</div>
                              </div>
                            </>}
                        </div> */}
                      </div>
                    </div>

                  </div>
                  <div className="px-3 mt-3 justify-content-center">
                    <Table 
                      className="innertable"
                      pagination={false}
                      showHeader={true}
                      columns={innerColumns}
                      dataSource={targetTab.intabledata[record.program]}
                      rowClassName="innerTableRow"
                      />
                    </div>
                </div>
              </div>
            ),
            rowExpandable: record => true,
            expandIcon:({expanded,onExpand, record})=>{
              return(
                  expanded? (
                      <img
                        src="/images/priceengine/lendersUpIcon.png"
                        style={{cursor: "pointer", width:32}} 
                        onClick={(e)=>{
                          onExpand(record,e)
                        }}
                      />
                  ):(
                    <img
                    src="/images/priceengine/lendersDownIcon.png"
                      style={{cursor: "pointer", width:32}} 
                        onClick={(e)=>{
                          onExpand(record,e)
                        }}
                      />
                  )
                  
              )
            }
          }}
        />
      }

    <Modal
      open={showAddLenderModal}
      onCancel={()=>setShowAddLendersModal(false)}
      centered
      footer={[
        <Button key="back" onClick={()=>setShowAddLendersModal(false)}>
          Cancel
        </Button>,
      ]}
      width={1000}
    >
      <Lenders />
    </Modal>
    <Modal
      title="Share Rates"
      open={showShareRateModal}
      onCancel={()=>setShowShareRatesModal(false)}
      centered
      footer={[
        <Button type="primary" onClick={()=>setShowShareRatesModal(false)}>
          Share
        </Button>,
      ]}
    >
      <div className="p-3">
        <div className="title-5">Borrower Email</div>
        <Input placeholder="email@example.com"/>

        <div className="title-5">Selected rates</div>
        {/* {displaySelectedRates(selectedRows)} */}
        
        <div className="title-5">Additonal information (optional)</div>
        <TextArea rows={4} placeholder="Enter your infomation..." maxLength={6} />


      </div>
    
    </Modal>

    </div>
  );
}



