import React, { useState, useEffect } from 'react'
import { Document, Page } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import './PDFViewer.css'
import { LeftOutlined, RightOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons'
import { Skeleton } from 'antd'


function PDFViewer(props) {
    const [numPages, setNumPages] = useState(null)
    const [pageNumber, setPageNumber] = useState(props.pageNumber)
    const [inputValue, setInputValue] = useState(String(props.pageNumber))
    const [scale, setScale] = useState(1)
    const [pdfHeight, setPdfHeight] = useState(window.innerHeight) // Initial height based on screen size

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages)
        setPageNumber(1)
        setInputValue("1")
    }

    // Update PDF height when the window is resized
    useEffect(() => {
        function handleResize() {
            setPdfHeight(window.innerHeight)
        }
        window.addEventListener('resize', handleResize)
        return () => {

            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        setInputValue(String(props.pageNumber))
    }, [props.pageNumber])

    function handleInputChange(e) {
        const value = parseInt(e.target.value, 10)
        setInputValue(e.target.value)
        if (!isNaN(value) && value >= 1 && value <= numPages) {
            setPageNumber(value)
            // Use the setPageNumber prop to update the parent component's state
            props.setPageNumber(value)
        }
    }

    function handlePreviousPage() {
        const newPageNumber = Math.max(props.pageNumber - 1, 1)
        setPageNumber(newPageNumber)
        props.setPageNumber(newPageNumber)
        setInputValue(String(newPageNumber))
    }

    function handleNextPage() {
        const newPageNumber = Math.min(props.pageNumber + 1, numPages)
        setPageNumber(newPageNumber)
        props.setPageNumber(newPageNumber)
        setInputValue(String(newPageNumber))
    }

    function handleZoomIn() {
        setScale(prevScale => Math.min(prevScale + 0.1, 3))
        if (scale * 100 > 120) {
            const paf = document.querySelector('.react-pdf__Page')
            paf.style.height = '100%'
        } // limit max zoom to 300%
    }

    function handleZoomOut() {
        setScale(prevScale => Math.max(prevScale - 0.1, 0.5)) // limit min zoom to 50%
    }

    return (
        <div className="pdf-container">
            <div className='pdf-adjustifier p-2'>
                <div className='d-flex border border4'>
                    <MinusOutlined onClick={handleZoomOut} className='border-right p-2' />
                    <span className='p-2'>{Math.round(scale * 100)}%</span>
                    <PlusOutlined onClick={handleZoomIn} className='border-left p-2' />
                </div>

                <div className='d-flex align-items-center'>
                    <div type="button" disabled={pageNumber <= 1} onClick={handlePreviousPage}>
                        <LeftOutlined style={{ marginRight: 5, fontSize: 18, fontWeight: 500 }} />
                    </div>
                    <div className='d-flex align-items-center'>
                        <input
                            type="number"
                            value={inputValue}
                            onChange={handleInputChange}
                            className='border'
                            min="1"
                            max={numPages || 1}
                            style={{ width: 70, textAlign: 'center', marginRight: 5, marginLeft: 5 }}
                        />
                        / {numPages || '--'}
                    </div>
                    <div type="button" disabled={pageNumber >= numPages} onClick={handleNextPage}>
                        <RightOutlined style={{ marginLeft: 5, fontSize: 18, fontWeight: 500 }} />
                    </div>
                </div>
            </div>
            <div className='pdf-preview-container gptpdf'>
                <Document style={{

                }} file={props.file} onLoadSuccess={onDocumentLoadSuccess} loading={<div className='p-2'><Skeleton active /><Skeleton active /><Skeleton active /></div>} >
                    <Page pageNumber={props.pageNumber} scale={scale} width={pdfHeight * 0.53} />
                </Document>
            </div>
        </div>
    )
}

export default PDFViewer
