import React, { useState, useEffect, useRef } from "react"
import { Row, Col } from "react-bootstrap"
import { withRouter, useParams } from 'react-router-dom'
import { RightOutlined, LinkOutlined, LikeOutlined, DislikeOutlined, CopyOutlined, CloseOutlined, ShrinkOutlined, ArrowsAltOutlined, MinusOutlined } from "@ant-design/icons"
import { Drawer, theme, Space, Input, Button, message, Modal, Spin, Skeleton } from 'antd'
import "./Chat.css"
import PDFViewer from "./PDFViewer"
import { ChatPromptExamples, DocPromptExamples, NewfiDocPromptExamples, FindPromptAnswer, ProcessAnswerText, FHADocPromptExamples, VADocPromptExamples } from "./utils"
import ChatFeedback from "./ChatFeedback"
import { useSelector, useDispatch } from 'react-redux'
import ChatHistory from "./ChatHistory"
import smartlookClient from 'smartlook-client'
import { Helmet } from 'react-helmet';

const fanieMaeDocName = "/fannie_mae_guideline.pdf"
const va = "/VA_guideline.pdf"
const FHA = '/FHA.pdf'
const newfiDocName = "/newfi_jumbo_guideline.pdf"

function Chat() {
    const { threadID } = useParams()
    const [messages, setMessages] = useState([])
    const [pdCFontent, setpdCFontent] = useState('Fannie Mae')
    const [showHistory, setShowHistory] = useState(false)
    const isMobile = window.innerWidth < 576
    const showPdfcontent = useSelector(state => {
        return state.common.content
    })
    const pdFontentdata = {
        fanieMaeDocName: "Fannie Mae",
        FHA: "FHA",
        va: "VA",

    }
    // const sc = `window.smartlook||(function(d) {
    //     var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
    //     var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
    //     c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
    //     })(document);
    //     smartlook('init', '702c7d241073da6ad788698d48d75342c29fa91b', { region: 'eu' });`
    

    useEffect(() => {
        const greetingMessage = {
            text: <div>
                <div>Hello! I'm an AI assistant specialized in the {pdFontentdata[showPdfcontent]} lending guidelines. How can I help you today?</div>
                <div style={{ marginTop: 5, color: "#586570" }}>You can ask me about: </div>

                {
                    showPdfcontent == 'fanieMaeDocName' ? DocPromptExamples.map(prompt => <div className="greetingPrompt" onClick={() => handleSendDocMessage(prompt.question, fanieMaeDocName)}>{prompt.question}</div>) : showPdfcontent == 'FHA' ? FHADocPromptExamples.map(prompt => <div className="greetingPrompt" onClick={() => handleSendDocMessage(prompt.question, fanieMaeDocName)}>{prompt.question}</div>) : showPdfcontent == 'va' ? VADocPromptExamples.map(prompt => <div className="greetingPrompt" onClick={() => handleSendDocMessage(prompt.question, fanieMaeDocName)}>{prompt.question}</div>) : null



                }

            </div>,
            sender: "bot",
        }
        setDocMessages([greetingMessage])
        setPageNumber(parseInt(1))
    }, [showPdfcontent])
    const greetingMessage = {
        text: <div>
            <div>Hello! I'm an AI assistant specialized in the {pdCFontent} lending guidelines. How can I help you today?</div>
            <div style={{ marginTop: 5, color: "#586570" }}>You can ask me about: </div>

            {DocPromptExamples.map(prompt => <div className="greetingPrompt" onClick={() => handleSendDocMessage(prompt.question, fanieMaeDocName)}>{prompt.question}</div>)}

        </div>,
        sender: "bot",
    }
    const [docMessages, setDocMessages] = useState([greetingMessage])
    const [newfiMessages, setNewfiMessages] = useState([])

    const [chatThreadID, setChatThreadID] = useState("")
    const [docThreadID, setDocThreadID] = useState("")
    const [newfiThreadID, setNewfiThreadID] = useState("")

    const [chatTitle, setChatTitle] = useState("")

    const [showWelcome, setShowWelcome] = useState(false)
    const [showDoc, setShowDoc] = useState(true)
    const [isBotThinking, setIsBotThinking] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [messageToSend, setMessageToSend] = useState("")
    const [currentDocName, setCurrentDocName] = useState(fanieMaeDocName)
    const [currentMessageID, setCurrentMessageID] = useState("")
    const [currentMessageText, setCurrentMessageText] = useState("")

    const [openChatHistory, setOpenChatHistory] = useState(false)

    const [loadingChat, setLoadingChat] = useState(false)
    const [showChat, setShowChat] = useState(true)
    const [showPdfContent, setPdfContent] = useState(true)
    const handleBackClick = () => {
        setShowWelcome(true)
        setShowDoc(false)
        setCurrentDocName("")
        setPageNumber(1)
    }

    const changePage = (newPageNumber) => {
        setPageNumber(parseInt(newPageNumber))
    }



    const formulateAnswer = (data) => {
        let answer = ProcessAnswerText(data.Answer, changePage, data.QuoteLocations)
        const uniqueSortedQuoteLocations = Array.from(new Set(Object.values(data.QuoteLocations))).sort()

        if (Object.keys(data.QuoteLocations).length > 0) {
            answer = <div>
                <div>{answer}</div>
                <div className="mt-3">Check reference pages:</div>
                <div className="d-flex mt-2" style={{ flexWrap: "wrap" }}>
                    {uniqueSortedQuoteLocations.map((val, index) => (
                        <div key={index} className="pageReference" onClick={() => changePage(val)}>
                            {val}
                        </div>
                    ))}
                </div>
            </div>
        }
        return answer
    }

    const handleSendDocMessage = async (newMessage, docName) => {

        const appendMessage = (docName, newMessage, sender) => {
            const newMsg = { text: newMessage, sender: sender }
            setDocMessages(prevMessages => [...prevMessages, newMsg])
            return
        }

        smartlookClient.track("guidelineGPT_GeneralInteraction", {
            label: "GPT actions",
            category: "Clicks"
        })

        appendMessage(docName, newMessage, "user")
        setIsBotThinking(true)


        let ans = FindPromptAnswer(newMessage)
        if (ans !== null) {
            setIsBotThinking(true)
            setTimeout(() => {

                let answer = formulateAnswer(ans)
                appendMessage(docName, answer, "bot")

                setIsBotThinking(false)
            }, 100)

            return
        }

        const pdfApi = {
            fanieMaeDocName: "/los/mortgagegpt/askfanniemae",
            FHA: "/los/mortgagegpt/askfha",
            va: "/los/mortgagegpt/askva",

        }
        try {
            let token = sessionStorage.getItem("ZeitroA")
            let url = pdfApi[showPdfcontent]


            const response = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache",
                },
                body: JSON.stringify({
                    Message: newMessage,
                    ThreadID: docThreadID,
                }),
            })

            if (!response.ok)
                throw new Error(`HTTP error! Status: ${response.status}`)

            const data = await response.json()
            setCurrentMessageID(data.MessageID)
            setCurrentMessageText(data.Answer)

            if (data.RunID === "Not Mortgage") {
                appendMessage(docName, data.Answer, "bot")
                setIsBotThinking(false)
                return
            }


            if (data.InitialChatTitle) setChatTitle(data.InitialChatTitle)
            let answer = formulateAnswer(data)
            appendMessage(docName, answer, "bot")
            if (docName === fanieMaeDocName) setDocThreadID(data.ThreadID)
            else setNewfiThreadID(data.ThreadID)

        } catch (error) {

            console.error("Fetching error:", error)
            appendMessage(docName, "Sorry something went wrong, please try later", "bot")
        } finally {
            setIsBotThinking(false) // Bot finishes thinking, regardless of success or failure
        }

    }
    const handleSendMessage = async (newMessage) => {
        setMessages([...messages, { text: newMessage, sender: "user" }])
        setIsBotThinking(true)

        let ans = FindPromptAnswer(newMessage)
        if (ans !== null) {
            setIsBotThinking(true)
            setTimeout(() => {
                setMessages((prevMessages) => [
                    ...prevMessages,
                    { text: ans, sender: "bot" },
                ])
                setIsBotThinking(false)
            }, 3000)
            return
        }

        try {
            let token = sessionStorage.getItem("ZeitroA")
            const response = await fetch("/los/mortgagegpt/askwithoutdoc", {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache",
                },
                body: JSON.stringify({
                    Message: newMessage,
                    ThreadID: chatThreadID,
                }),
            })

            if (!response.ok)
                throw new Error(`HTTP error! Status: ${response.status}`)

            const data = await response.json()
            setMessages((prevMessages) => [
                ...prevMessages,
                { text: data.answer, sender: "bot" },
            ])
            setChatThreadID(data.threadID)
        } catch (error) {
            console.error("Fetching error:", error)
            setMessages((prevMessages) => [
                ...prevMessages,
                { text: "Sorry something went wrong, please try later", sender: "bot" },
            ])
        } finally {
            setIsBotThinking(false)
        }
    }

    const handlePromptClick = (prompt) => {
        handleSendMessage(prompt)
        setShowWelcome(false)
    }
    const handleDocPromptClick = (prompt, docName) => {
        handleSendDocMessage(prompt, docName)
        setShowWelcome(false)

        setCurrentDocName(docName)
        setShowDoc(true)
    }
    const dispatch = useDispatch()
    const showChatHeader = () => {
        const pc = <div className="d-flex " style={{ height: "60px" }}>
            <div className="d-flex align-items-center justify-content-between" style={showHistory ? { width: "16.6%", background: "#F6F6F6", padding: '0  16px' } : showPdf ? {

                width: "60px", background: "#fff", padding: '0  16px'
            } : { width: "200px", background: "#fff", padding: '0  16px' }}>
                <div style={{ cursor: "pointer" }} onClick={() => {
                    setShowHistory(!showHistory)
                }}>
                    <img src="/images/close.svg"></img>


                </div>
                {!showPdf && <Button onClick={() => {

                    dispatch({
                        type: "SHOW_PDF", data: true
                    })
                    dispatch({
                        type: "CHANGE_PDF", data: undefined
                    })
                    setDocMessages([greetingMessage])
                    setChatTitle("")
                    setDocThreadID("")
                    setNewfiThreadID("")
                    setChatThreadID("")
                }} type="outline" style={{ height: 42 }}>New chat</Button>}
            </div>
            <div className="d-flex align-items-center justify-content-between " style={showHistory ? { width: "82%", marginLeft: 10 } : { width: "90%", padding: 10, borderBottom: '1px solid #F6F6F6' }}>
                <div className="title-3">Guideline GPT</div>
                {/* <div className="d-flex">
          {docThreadID !== "" && <Button type="primary" onClick={() => window.location.href = "/app/mortgagegpt"}>New chat</Button>}
          <img style={{ width: 32, marginLeft: 15, cursor: "pointer" }} src="/images/moremenu.png" onClick={() => setOpenChatHistory(!openChatHistory)} />
      </div> */}
            </div>
        </div>
        const Mobile = <div className="d-flex align-items-center justify-content-between" style={{ height: "60px", padding: "0 20px" }}>
            <div className="title-3">Guideline GPT</div>
            <div className="d-flex align-items-center">
                <div style={{ cursor: "pointer", marginRight: 10 }} onClick={() => {
                    setShowHistory(!showHistory)
                }}>
                    <img src="/images/pdfMobileHistory.svg"></img>


                </div>
                {!showPdf && <Button onClick={() => {

                    dispatch({
                        type: "SHOW_PDF", data: true
                    })
                    dispatch({
                        type: "CHANGE_PDF", data: undefined
                    })
                    setDocMessages([greetingMessage])
                    setChatTitle("")
                    setDocThreadID("")
                    setNewfiThreadID("")
                    setChatThreadID("")
                }} type="outline" style={{ height: 42 }}>New chat</Button>}
            </div>

        </div>
        return isMobile ? Mobile : pc
    }

    const onChangeMessageToSend = (val) => {
        setMessageToSend(val)
    }

    const onSendMessageOnWelcomePage = () => {
        if (messageToSend.trim() === "") return
        if (currentDocName !== "") {
            handleSendDocMessage(messageToSend, currentDocName)
        } else {
            handleSendMessage(messageToSend)
        }

        setShowWelcome(false)
    }


    const loadChat = (threadID) => {
        setLoadingChat(true)
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/los/mortgagegpt/loadchat', {
            method: 'POST',
            body: JSON.stringify({ ThreadID: threadID }),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status)
                    setLoadingChat(false)
                    return
                }
                response.json().then(js => {
                    setDocThreadID(js.thread.thread_id)
                    if (js.thread.custom_title !== null) {
                        setChatTitle(js.thread.custom_title)
                    } else if (js.thread.initial_title !== null) {
                        setChatTitle(js.thread.initial_title)
                    }
                    let chatHistory = js.chat_history.map(msg => {
                        if (msg.role === "user") {
                            return { text: ProcessAnswerText(msg.content), sender: "user" }
                        } else {
                            return { text: ProcessAnswerText(msg.content), sender: "bot" }
                        }
                    })
                    setLoadingChat(false)
                    setDocMessages(chatHistory)
                })
            }
        ).catch(function (err) {
            setLoadingChat(false)
            console.log('Fetch Error :-S', err)
        })
    }

    const processMoreDetails = (data) => {
        setCurrentMessageID(data.msg_id)
        setCurrentMessageText(data.answer)
        let answer = formulateAnswer(data)
        const newMsg = { text: answer, sender: "bot" }
        setDocMessages(prevMessages => [...prevMessages, newMsg])
    }

    useEffect(() => {
        if (typeof threadID !== "undefined") {
            loadChat(threadID)
            setChatThreadID(threadID)
        }
    }, [])
    const showPdf = useSelector(state => {
        return state.common.showPdf
    })

    const padContent = {
        va: va,
        fanieMaeDocName: fanieMaeDocName,
        FHA: FHA
    }


    return (
        <div className="chat">
            {/* <Helmet>
                <script type='text/javascript'>
                {sc}
                </script>
            </Helmet> */}
            {showWelcome ? (
                <WelcomePage
                    onPromptClick={handlePromptClick}
                    onDocPromptClick={handleDocPromptClick}
                    onChangeMessageToSend={onChangeMessageToSend}
                    onSendMessage={onSendMessageOnWelcomePage}
                    isBotThinking={isBotThinking}
                    docName={currentDocName}
                    setDocName={setCurrentDocName}
                    showDoc={showDoc}
                    setShowDoc={setShowDoc}
                />
            ) : (
                <div style={{ width: "100%" }}>

                    {showChatHeader()}
                    {showDoc ? (
                        <Row className="px-0">
                            {showHistory && <Col md={2} className="px-0">
                                <div style={{ background: "#F6F6F6", height: "calc(100vh - 60px)", padding: 14, overflowX: 'auto' }}>

                                    <ChatHistory
                                        getHistoryList={
                                            (threadId, type) => {
                                                dispatch({
                                                    type: "SHOW_PDF", data: false
                                                })
                                                dispatch({
                                                    type: "CHANGE_PDF", data: type === '' ? 'fanieMaeDocName' : type === 'FannieMae' ? 'fanieMaeDocName' : type === 'VA' ? 'va' : type === 'FHA' ? 'FHA' : ''
                                                })
                                                loadChat(threadId)
                                            }
                                        }

                                        currentThreadID={chatThreadID} openChatHistory={openChatHistory} setOpenChatHistory={setOpenChatHistory} />

                                </div>
                            </Col>}
                            {showPdfContent && <Col md={showHistory ? 5 : 6} className="px-0">
                                {
                                    showPdf ? <div className="Pdfi">
                                        <img src="/images/pdfi.svg"></img>

                                    </div> :
                                        <PDFViewer file={padContent[showPdfcontent]} pageNumber={pageNumber} setPageNumber={changePage} />
                                }
                            </Col>}
                            <Col md={showHistory ? 5 : 6} className="px-0">
                                {
                                    isMobile && <div className="d-flex justify-content-between align-items-center" style={{ height: 58, padding: "0 20px", color: "#010101", fontSize: 16, fontWeight: 500, borderBottom: "1px solid #DBDCDE" }}>
                                        <div>
                                            {chatTitle == '' ? 'New chat' : chatTitle}
                                        </div>
                                        <div className="d-flex justify-content-between " style={{ color: "#6E6E70" }}>
                                            <div className="mobileC"
                                                onClick={() => {
                                                    setShowChat(!showChat)

                                                }}
                                                style={{

                                                    marginRight: 10
                                                }}>
                                                <MinusOutlined />
                                            </div>
                                            <div className="mobileC"
                                                onClick={() => {
                                                    setPdfContent(!showPdfContent)

                                                }}
                                            >

                                                {showPdfContent ? <ShrinkOutlined /> : <ArrowsAltOutlined />}

                                            </div>
                                            {/*  */}
                                        </div>
                                    </div>
                                }
                                {showChat && <ChatApp >

                                    {!showPdf && <MessageHeader chatTitle={chatTitle} setChatTitle={setChatTitle} threadID={docThreadID} />}
                                    <MessageList
                                        loadingChat={loadingChat}
                                        messages={docMessages}
                                        processMoreDetails={processMoreDetails}
                                        threadID={docThreadID}
                                        isBotThinking={isBotThinking}
                                        currentMessageID={currentMessageID}
                                        currentMessageText={currentMessageText}
                                    />
                                    {!showPdf && <ChatInput onSendMessage={showDoc ? handleSendDocMessage : handleSendMessage} isBotThinking={isBotThinking} docName={currentDocName} />}
                                </ChatApp>}
                            </Col>
                        </Row>
                    ) : (
                        <div className="d-flex justify-content-center">
                            <ChatApp className="width70">
                                <MessageList
                                    messages={messages}
                                    processMoreDetails={processMoreDetails}
                                    threadID={threadID}
                                    isBotThinking={isBotThinking}
                                    currentMessageID={currentMessageID}
                                    currentMessageText={currentMessageText}
                                />
                                <ChatInput onSendMessage={handleSendMessage} style={{ marginBottom: 10 }} isBotThinking={isBotThinking} docName={currentDocName} />
                            </ChatApp>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

function WelcomePage({ onPromptClick, onDocPromptClick, onChangeMessageToSend, onSendMessage, isBotThinking, docName, setDocName, showDoc, setShowDoc }) {
    const inputRef = useRef(null)
    useEffect(() => {
        // Auto-focus the input when the component mounts
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [])
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            onSendMessage()
        }
    }
    const handleDocSelection = (e) => {
        switch (e.target.value) {
            case "1":
                setDocName("")
                setShowDoc(false)
                break
            case "2":
                setDocName(fanieMaeDocName)
                setShowDoc(true)
                break
            case "3":
                setDocName(newfiDocName)
                setShowDoc(true)
                break
        }
    }



    return (
        <div className="welcome-page px-4">
            <div className="title-1 mt-4">GuidelineGPT</div>
            <div className="title-5 mt-2">
                Get quick answers for all your mortgage questions
            </div>
            <div className="d-flex justify-content-center">
                <div className="chatprompts">
                    <img src="/images/chatsign.png" style={{ width: 52, height: 52 }} />
                    <div style={{ fontWeight: 500 }}>You can start the chat by asking:</div>
                    <Row className="px-3 text-left mt-3">
                        {ChatPromptExamples.map((prompt, index) => (
                            <Col
                                md={6}
                                className="p-2 "
                                onClick={() => onPromptClick(prompt.question)}
                            >
                                <div className="prompt d-flex justify-space-between">
                                    <div className="mr-3" key={index}>
                                        {`"` + prompt.question + `"`}
                                    </div>
                                    <RightOutlined />
                                </div>
                            </Col>
                        ))}
                    </Row>
                    <Row className="px-3 text-left mt-3">
                        {DocPromptExamples.map((prompt, index) => (
                            <Col
                                md={6}
                                className="p-2 "
                                onClick={() => onDocPromptClick(prompt.question, fanieMaeDocName)}
                            >
                                <div className="prompt d-flex justify-space-between">
                                    <div>
                                        <div className="mr-3" key={index}>
                                            {`"` + prompt.question + `"`}
                                        </div>
                                        <div className="promptdoc">
                                            <img
                                                src="/images/pdf2.png"
                                                style={{ height: 22, marginRight: 6 }}
                                            />
                                            Fannie Mae Conventional Guideline.pdf
                                        </div>
                                    </div>
                                    <RightOutlined />
                                </div>
                            </Col>
                        ))}
                    </Row>
                    <Row className="px-3 text-left mt-3">
                        {NewfiDocPromptExamples.map((prompt, index) => (
                            <Col
                                md={6}
                                className="p-2 "
                                onClick={() => onDocPromptClick(prompt.question, newfiDocName)}
                            >
                                <div className="prompt d-flex justify-space-between">
                                    <div>
                                        <div className="mr-3" key={index}>
                                            {`"` + prompt.question + `"`}
                                        </div>
                                        <div className="promptdoc">
                                            <img
                                                src="/images/pdf2.png"
                                                style={{ height: 22, marginRight: 6 }}
                                            />
                                            NewFi Jumbo Guideline.pdf
                                        </div>
                                    </div>
                                    <RightOutlined />
                                </div>
                            </Col>
                        ))}
                    </Row>
                    <Row className="px-3 mt-3">
                        <Col className="p-2">
                            <div className="chat-input" >
                                <input
                                    type="text"
                                    ref={inputRef}
                                    // value={inputValue}
                                    disabled={isBotThinking}
                                    onChange={(e) => onChangeMessageToSend(e.target.value)}
                                    onKeyPress={handleKeyPress}
                                    placeholder="Ask any mortgage-related questions"
                                />
                                <select onChange={handleDocSelection} disabled={isBotThinking} className="welcomePageDropdown">
                                    <option value="1">General Mortgage Queries</option>
                                    <option value="2">Inquire Fannie Mae Guidelines</option>
                                    <option value="3">Consult Newfi Jumbo Guidelines</option>
                                </select>
                                <Button variant="sendmessage" onClick={() => onSendMessage()} disabled={isBotThinking}>{isBotThinking ? "Pending" : "Send"}</Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

function ChatApp({ children, className }) {
    return <div className={"chat-app " + className}>{children}</div>
}

function MessageList({ messages, processMoreDetails, threadID, isBotThinking, currentMessageID, currentMessageText, loadingChat }) {
    const messageContainerRef = useRef(null)
    const [pdfList, setPdfList] = useState([
        {
            id: 'fanieMaeDocName',
            value: 'Fannie Mae selling guide',
        },
        {
            id: 'FHA',
            value: 'FHA loan guidelines',
        },
        {
            id: 'va',
            value: 'VA loan guidelines'
        },



    ])
    const showPdf = useSelector(state => {
        return state.common.showPdf
    })
    const dispatch = useDispatch()
    useEffect(() => {
        if (messageContainerRef.current) {
            messageContainerRef.current.scrollTop =
                messageContainerRef.current.scrollHeight
        }
    }, [messages])

    if (loadingChat) return <div className="message-list pt-3">
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
    </div>

    return (
        <>
            {
                !showPdf ? <div className="message-list" ref={messageContainerRef}>

                    {messages.map((message, index) => (
                        <Message key={index} text={message.text} sender={message.sender} />
                    ))}
                    {isBotThinking && <Message text="..." sender="bot" />}
                    <ChatFeedback threadID={threadID} processMoreDetails={processMoreDetails} chatAnswer={currentMessageText} messageID={currentMessageID} isBotThinking={isBotThinking} />
                    {/* <div className="feedbackContent">
                        <div className="d-flex justify-content-between align-items-center">
                            <div><img src="/images/face.svg"></img> Thank you for your feedback.

                            </div>
                            <div style={{ cursor: 'pointer' }} ><CloseOutlined /></div>
                        </div>
                        <div style={{ paddingLeft: 24, fontWeight: 400, marginTop: 5 }}>We value your input and will use it to continually improve Mortgage GPT.</div>
                    </div> */}
                </div> : <div className="bot-message message" style={{ display: "block", marginTop: 28, marginLeft: 17 }}>
                    <div className="d-flex">
                        <img src="/images/chatbot.png" alt="Chatbot" className="avatar" />
                        <div style={{ color: "#222", fontSize: 16, fontWeight: 600, marginLeft: 10 }}>
                            Welcome to Guideline GPT.
                        </div>

                    </div>
                    <div style={{
                        marginTop: 12,
                        marginLeft: 50,
                        color: '#62707C',
                        fontSize: 16,
                    }}>
                        Select a guideline to get started:

                    </div>
                    <div className=" pl-2" style={{
                        marginLeft: 40,
                    }}>
                        {
                            pdfList.map((item) => {

                                return <div key={item.id} className="padList"
                                    onClick={() => {
                                        dispatch({
                                            type: "SHOW_PDF", data: false
                                        })
                                        dispatch({
                                            type: "CHANGE_PDF", data: item.id
                                        })
                                    }}
                                >
                                    <img src="/images/pdfIcon.svg"></img>
                                    <span>
                                        {item.value}
                                    </span>
                                </div>
                            })

                        }
                    </div>

                </div>
            }
        </>

    )
}

function MessageHeader({ chatTitle, setChatTitle, threadID }) {
    const [isEditing, setIsEditing] = useState(false)
    const [editableTitle, setEditableTitle] = useState(chatTitle)
    const [showShareModal, setShowShareModal] = useState(false)
    const [loadingShare, setLoadingShare] = useState(false)
    const [loadingSent, setLoadingSent] = useState(false)
    const [chatHistory, setChatHistory] = useState([])
    const [emailToShare, setEmailToShare] = useState("")

    const handleDoubleClick = () => {
        setIsEditing(true)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setIsEditing(false)
            setChatTitle(editableTitle)
            customizeChatTitle(threadID, editableTitle)
        }
    }

    const handleChange = (event) => {
        setEditableTitle(event.target.value)
    }

    const customizeChatTitle = async (threadID, title) => {
        let token = sessionStorage.getItem("ZeitroA")

        try {
            const response = await fetch("/los/mortgagegpt/customchattitle", {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache",
                },
                body: JSON.stringify({
                    ThreadID: threadID,
                    ChatTitle: title,
                })
            })

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`)
            }
            setChatTitle(title)
        } catch (error) {
            console.error("Fetch Error:", error)
        }
    }

    const handleShare = () => {
        setLoadingShare(true)

        let token = sessionStorage.getItem("ZeitroA")
        let body = { ThreadID: threadID }
        fetch('/los/mortgagegpt/sharechat', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status)
                    setLoadingShare(false)
                    alert("Something went wrong, please try again later")
                    return
                }
                response.json().then(js => {
                    setChatHistory(js.chat_history)
                    setLoadingShare(false)
                    setShowShareModal(true)
                })
            }
        ).catch(function (err) {
            setLoadingShare(false)
            console.log('Fetch Error :-S', err)
        })

    }
    if (threadID === "" || threadID === null) {
        return ""
    }

    const displayChatHistory = (chatHistory) => {

        return <div className="chatHistoryWrap">
            <div className='title-5 mb-2'>Email address</div>
            <Input placeholder="Enter email address" onChange={e => setEmailToShare(e.target.value)} />
            <div className="title-5 mb-2">Preiew</div>
            <div className="chatHistoryPreviewWrap">{chatHistory.map(msg => {
                if (msg.role === "user") {
                    return <div className="chatHistoryUser">
                        <img src="/images/chatbotuseravatar.png" style={{ width: 30, height: 30, marginRight: 10 }} />
                        <div>
                            <div className='bold'>You</div>
                            <div>{msg.content}</div>
                        </div>
                    </div>
                } else {
                    return <div className="chatHistoryBot">
                        <img src="/images/chatbot.png" style={{ width: 30, height: 30, marginRight: 10 }} />
                        <div>
                            <div className='bold'>GuidelineGPT</div>
                            <div>{ProcessAnswerText(msg.content)}</div>
                        </div>
                    </div>
                }
            })}</div>
        </div>

    }

    const copyLinkToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(window.location.origin + "/mortgagegpt/sharechat/" + threadID)
            alert('Text copied to clipboard')
        } catch (err) {
            console.error('Failed to copy: ', err)
        }
    }
    const hideChat = () => {
        let token = sessionStorage.getItem("ZeitroA")
        let body = { ThreadID: threadID }
        fetch('/los/mortgagegpt/hidechat', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status)
                    return
                }
            }
        ).catch(function (err) {
            console.log('Fetch Error :-S', err)
        })

    }
    const handleHide = () => {
        hideChat()
        window.location.href = "/app/mortgagegpt"
    }
    const sendShare = () => {
        setLoadingSent(true)
        let token = sessionStorage.getItem("ZeitroA")
        let body = { Email: emailToShare, ThreadID: threadID }
        fetch('/los/mortgagegpt/sendshareemail', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status)
                    setLoadingSent(false)
                    return
                }

                setLoadingSent(false)
                setShowShareModal(false)
                alert("Email sent successfully")

            }
        ).catch(function (err) {
            setLoadingSent(false)
            console.log('Fetch Error :-S', err)
        })
    }
    return (
        <div className="message-header bottomBorder d-flex justify-content-between">
            <div className="message-title-container d-flex">
                {isEditing ? (
                    <input
                        className="message-title-input flex-grow-1"
                        type="text"
                        value={editableTitle}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        autoFocus
                    />
                ) : (
                    <div className="d-flex flex-grow-1 align-items-center justify-content-between" >
                        <div className="message-title ">{chatTitle}</div>
                        <img src="/images/edit.png" style={{ width: 32, cursor: "pointer" }} onClick={handleDoubleClick} />
                    </div>
                )}
            </div>

            <div className="icon-container d-flex">
                {loadingShare ? <Spin className="mr-2" /> : <img src="/images/share.png" style={{ width: 32, marginRight: 10, cursor: "pointer" }} onClick={() => handleShare()} />}
                <img src="/images/delete.png" style={{ width: 32, cursor: "pointer" }} onClick={() => handleHide()} />
            </div>

            <Modal title="Share link of chat" open={showShareModal} onCancel={() => setShowShareModal(false)}
                className="share-chat"
                footer={[
                    <a className="aAsLink mr-2" onClick={() => copyLinkToClipboard()}><LinkOutlined /> Copy link</a>,
                    <Button type="primary" loading={loadingSent} onClick={() => sendShare()}>Send</Button>,
                ]}
                width={800}
                style={{ top: 0 }}
            >
                {displayChatHistory(chatHistory)}
            </Modal>
        </div>
    )
}


function Message({ text, sender }) {


    const avatar =
        sender === "bot" ? (
            <img src="/images/chatbot.png" alt="Chatbot" className="avatar" />
        ) : <img src="/images/chatbotuseravatar.png" alt="Chatbot" className="avatar" />
    const name = sender === "bot" ? "GuidelineGPT" : "You"

    return (
        <div className={`${sender}-message message`} style={sender === "bot" ? {} : {
            borderRadius: '10px',
            background: '#F6F6F6',

            paddingTop: "20px",
            maxWidth: "70%",
            float: "right",
            clear: "both"
        }}>
            {sender === "bot" ? avatar : <></>}
            <div
                className={`text-bubble ${text === "..." ? "typing-indicator" : ""}`}
            >
                {/* <div className="message-user">{name}</div> */}
                {text === "..." ? (
                    <>
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                    </>
                ) : (
                    <div>{text}</div>
                )}
            </div>
            {/* {sender !== "bot" ? avatar : <></>} */}
        </div>
    )
}

function ChatInput({ onSendMessage, style, isBotThinking, docName }) {
    const [inputValue, setInputValue] = useState("")
    const inputRef = useRef(null)

    useEffect(() => {
        inputRef.current.focus()
    }, [])

    const handleSend = () => {
        if (inputValue.trim()) {
            smartlookClient.track("guidelineGPT_GeneralInteraction", {
                label: "GPT actions",
                category: "Clicks"
            })
            onSendMessage(inputValue.trim(), docName)
            setInputValue("")
        }
    }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            // Call the button click handler when Enter key is pressed
            handleSend()
        }
    }
    return (
        <div className="chat-input" style={style}>
            <div>
                <img src="/images/light.svg"></img>
            </div>
            <input
                type="text"
                ref={inputRef}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onFocus={(e) => {
                    smartlookClient.track("guidelineGPT_Typing", {
                        label: "Chat typing",
                        category: "Focus"
                    })
                }}
                onKeyPress={handleKeyPress}
                placeholder="Ask AI..."
                disabled={isBotThinking}
            />
            {

                inputValue == '' ? <div style={{ cursor: "pointer", color: "red" }}>
                    <img src="/images/send.svg"></img>
                </div> : <div onClick={handleSend} disabled={isBotThinking} style={{ cursor: "pointer", color: "red" }}>
                    <img src="/images/sendBlue.svg"></img>
                </div>
            }

            {/* <Button variant="zeitro-primary" onClick={handleSend} disabled={isBotThinking}>{isBotThinking ? "Pending" : "Send"}</Button> */}
        </div>
    )
}

export default withRouter(Chat)
