import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { Modal, Pagination, Button, Image, Dropdown, Form, Input, Select, Row, Col, Table, InputNumber, Tooltip } from 'antd';
import { CloseOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import { Document, Page, pdfjs } from 'react-pdf';
import './PreviewModal.css';
import { capitalize, divideStringByCapitalLetter, splitByCapitalLetter } from '../Common';
import { MinusCircleOutlined } from '@ant-design/icons';
const { TextArea } = Input;
export default function PreviewModal(props) {
    pdfjs.GlobalWorkerOptions.workerSrc =
        `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const {
        mode, open, data, docmentList, file, pageNumber, numPages, width, scale, extractedData, generalValidity, mappingData, resubmissionRecord, moveFile, verify, manualVerify, complete, change,
        handleDocument, onCancel, handleZoom, handlePage,
        loadSuccess
    } = props
    const { Option } = Select
    const [form] = Form.useForm()
    const [pageWidth, setPageWidth] = useState(0)
    const [expand, setExpand] = useState(false)
    const containerRef = useRef(null)
    const [formData, setFormData] = useState(extractedData)
    const [selectCategory, setSelectCategory] = useState(undefined)
    const [selectType, setSelectType] = useState(undefined)
    const rightContainerRef = useRef(null)
    const manualChangeTimeout = useRef(null)
    const [currentPage, setCurrentPage] = useState(1)
    const isManualChange = useRef(false)
    const isLeftSyncing = useRef(false);
    const isRightSyncing = useRef(false);
    const justSwitched = useRef(false);
    const modeRef = useRef(mode)

    useEffect(() => {
        modeRef.current = mode
    }, [mode])

    useEffect(() => {
        if (extractedData && extractedData.length > 0) {
            if (extractedData.some(item => item.docType === 'BankStatement')) {
                const newData = addKeysToTransactions(extractedData)
                const mergedExtractedData = mergeExtractedData(newData)
                setFormData(mergedExtractedData)
            } else {
                setFormData(extractedData)
            }
            const initialValues = convertToFormValues(extractedData)
            form.setFieldsValue(initialValues)
        }
    }, [extractedData, form, pageNumber])

    const addKeysToTransactions = (data) => {
        return data.map((item) => {
          if (item.extractedData.Transactions) {
            item.extractedData.Transactions = item.extractedData.Transactions.map((transaction, index) => ({
              ...transaction,
              key: `${item.page}-${index}`,
            }))
          }
          return item
        })
    }
    const mergeExtractedData = (data) => {
        const mergedData = {
          ...data[0],
          extractedData: {
            ...data[0].extractedData,
            Transactions: [],
          },
        }
      
        for (let i = 0; i < data.length; i++) {
          const pageData = data[i].extractedData
          if (!pageData.Transactions) {
            continue
          }
          mergedData.extractedData.Transactions.push(...pageData.Transactions)
      
          for (const key in pageData) {
            if (pageData[key] && !mergedData.extractedData[key]) {
              mergedData.extractedData[key] = pageData[key]
            }
          }
        }
      
        return [mergedData]
    }
    const splitMergedData = (mergedData, originalData) => {
        let splitData = originalData.map(page => ({
          ...page,
          extractedData: {
            ...page.extractedData,
            Transactions: [],
          },
        }))
        mergedData[0].extractedData.Transactions.forEach(transaction => {
            const [page, index] = transaction.key.split('-')
            const pageIndex = parseInt(page) - 1
            const { key, ...rest } = transaction
    
            splitData[pageIndex].extractedData.Transactions.push(rest)
        })
        const commonFields = { ...mergedData[0].extractedData }
        delete commonFields.Transactions
    
        splitData = splitData.map(page => {
            return { 
                ...page, 
                extractedData: { 
                    ...page.extractedData, 
                    ...commonFields 
                } 
            }
        })
    
        return splitData
    }
      
    const convertToFormValues = (data) => {
        const formValues = {}
        data.forEach((pageData) => {
            const page = pageData.page
            const addToFormValues = (obj, path) => {
                Object.entries(obj).forEach(([key, value]) => {
                    const newPath = path ? `${path}.${key}` : key
                    if (typeof value === 'object' && value !== null) {
                        addToFormValues(value, newPath)
                    } else {
                        formValues[`${page}.${newPath}`] = value
                    }
                })
            }
            addToFormValues(pageData.extractedData, '')
        })
        return formValues
    }
    const handleValuesChange = (changedValues, allValues) => {
        const updatedExtractedData = cloneDeep(formData)
        Object.keys(changedValues).forEach(key => {
            const value = changedValues[key];
            const [pageStr, ...path] = key.split('.')
            const pageIndex = parseInt(pageStr, 10) - 1

            if (updatedExtractedData[pageIndex]) {
                let target = updatedExtractedData[pageIndex].extractedData
                for (let i = 0; i < path.length - 1; i++) {
                    target = target[path[i]]
                }
                target[path[path.length - 1]] = value
            }
        })
        setFormData(updatedExtractedData)
    }
    useEffect(() => {
        return () => {
            if (manualChangeTimeout.current) {
                clearTimeout(manualChangeTimeout.current)
            }
        }
    }, [])
    useLayoutEffect (() => {
        if (open && mode !== 'preview') {
            if (mode === 'move') {
                setSelectCategory(data?.docType)
                setSelectType(data?.taskId)
            } else if (mode === 'sort') {
                setSelectCategory(undefined)
                setSelectType(undefined)
            }
            const handleResize = () => {
                if (containerRef.current) {
                    setPageWidth(containerRef.current.offsetWidth - 8)
                }
            }
            setTimeout(handleResize, 0)
            window.addEventListener('resize', handleResize)
            containerRef.current.addEventListener('scroll', handleScroll)
    
            return () => {
                window.removeEventListener('resize', handleResize)
                containerRef.current.removeEventListener('scroll', handleScroll)
            }
        }
    }, [open, mode])
    useEffect(() => {
        if (open) {
            setCurrentPage(1)
        }
    }, [open, mode])
    const previewCancel = () => {
        setExpand(false)
        onCancel()
    }

    const onDocumentLoadSuccess = (page) => {
        loadSuccess(page)
    }

    const previewDownload = () => {
        const modifiedPdfBlob = new Blob([file], { type: 'application/pdf' })
        const downloadLink = document.createElement('a')
        downloadLink.href = URL.createObjectURL(modifiedPdfBlob)
        downloadLink.download = data?.FileName
        downloadLink.click()
    }    
    const onChangePage = (page) => {
        isManualChange.current = true
    
        setCurrentPage(page)
        const pdfTargetElement = document.querySelector(`#pdfPage-${page}`)
        const formTargetElement = document.querySelector(`#formPage-${page}`)
    
        if (pdfTargetElement) {
            containerRef.current.scrollTo({
                top: pdfTargetElement.offsetTop,
                behavior: 'smooth',
            });
        }
    
        if (formTargetElement) {
            rightContainerRef.current.scrollTo({
                top: formTargetElement.offsetTop,
                behavior: 'smooth',
            })
        }
    
        if (manualChangeTimeout.current) {
            clearTimeout(manualChangeTimeout.current)
        }

        manualChangeTimeout.current = setTimeout(() => {
            isManualChange.current = false
        }, 500)
    }
    const resetScrollHandling = () => {
        containerRef.current.removeEventListener('scroll', resetScrollHandling)
        containerRef.current.addEventListener('scroll', handleScroll)
    }
    const handleScroll = () => {
        const scrollTop = containerRef.current.scrollTop
        const containerHeight = containerRef.current.offsetHeight
        const scrollHeight = containerRef.current.scrollHeight
    
        if (handleScroll.lastScrollTop === undefined) {
            handleScroll.lastScrollTop = 0
        }
        const changeFileMode = modeRef.current !== 'move' && modeRef.current !== 'sort'
        const isScrollingDown = scrollTop > handleScroll.lastScrollTop
        handleScroll.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop
    
        if (justSwitched.current) {
            return
        }
    
        if (isScrollingDown && scrollTop + containerHeight >= scrollHeight && changeFileMode) {
            justSwitched.current = true
            changeFile('next')
    
            containerRef.current.removeEventListener('scroll', handleScroll)
    
            requestAnimationFrame(() => {
                containerRef.current.addEventListener('scroll', resetScrollHandling)
            })
        } else if (!isScrollingDown && scrollTop <= 0 && changeFileMode) {
            justSwitched.current = true
            changeFile('prev')
    
            containerRef.current.removeEventListener('scroll', handleScroll)
    
            requestAnimationFrame(() => {
                containerRef.current.addEventListener('scroll', resetScrollHandling)
            })
        }
    
        if (isManualChange.current || isLeftSyncing.current) return
        isLeftSyncing.current = true
        const anchors = document.querySelectorAll('.pdf-anchor')
        let closestPage = currentPage
        let minDistance = Infinity
    
        anchors.forEach(anchor => {
            const { top } = anchor.getBoundingClientRect()
            const distance = Math.abs(top - containerHeight / 2)
            if (distance < minDistance) {
                minDistance = distance
                closestPage = parseInt(anchor.id.split('-')[1])
            }
        })
    
        if (closestPage !== currentPage) {
            setCurrentPage(closestPage)
            if (!isRightSyncing.current) {
                isManualChange.current = true
                const formTargetElement = document.querySelector(`#formPage-${closestPage}`)
                if (formTargetElement) {
                    rightContainerRef.current.scrollTo({
                        top: formTargetElement.offsetTop,
                        behavior: 'smooth',
                    })
                }
                setTimeout(() => {
                    isManualChange.current = false
                }, 500)
            }
        }
    
        setTimeout(() => {
            isLeftSyncing.current = false
        }, 100)
    }
    
    const handleRightScroll = () => {
        if (isManualChange.current || isRightSyncing.current) return
        isRightSyncing.current = true
        const scrollTop = rightContainerRef.current.scrollTop
        const containerHeight = rightContainerRef.current.offsetHeight
        const formPages = document.querySelectorAll('.extracted-page')
    
        let newPage = currentPage
    
        formPages.forEach((page, index) => {
            const pageTop = page.offsetTop
            const pageHeight = page.offsetHeight
    
            if (scrollTop + containerHeight > pageTop + pageHeight / 4 && scrollTop < pageTop + pageHeight) {
                newPage = parseInt(page.id.split('-')[1])
            }
    
            if (scrollTop + containerHeight < pageTop + pageHeight && scrollTop > pageTop - pageHeight / 4) {
                newPage = parseInt(page.id.split('-')[1])
            }
        });
        if (newPage !== currentPage) {
            setCurrentPage(newPage)
            if (!isLeftSyncing.current) {
                isManualChange.current = true
                const pdfTargetElement = document.querySelector(`#pdfPage-${newPage}`)
                if (pdfTargetElement) {
                    containerRef.current.scrollTo({
                        top: pdfTargetElement.offsetTop,
                        behavior: 'smooth',
                    })
                }
                setTimeout(() => {
                    isManualChange.current = false
                }, 500)
            }
        }
        setTimeout(() => {
            isRightSyncing.current = false
        }, 100)
    }
    const changeZoom = (type) => {
        if (type === 'add') {
            handleZoom(+(Math.min(scale + 0.1, 3).toFixed(1)));
        } else {
            handleZoom(+(Math.max(scale - 0.1, 0.5).toFixed(1)));
        }
    }
    const removeFileExtension = (fileName) => {
        return fileName && fileName.replace(/\.[^/.]+$/, "")
    }
    const arrayBufferToBlob = (buffer, type) => {
        return new Blob([buffer], { type })
    }
    
    const createImageURLFromBase64 = (arrayBuffer, type) => {
        const blob = arrayBufferToBlob(arrayBuffer, type)
        return URL.createObjectURL(blob)
    }
    const changeFile = (type) => {
        let newPage
        if (type === 'prev' && data && data?.num !== 1) {
            change(data?.num - 1)
            newPage = 1
        } else if (type === 'next' && data && data?.num !== data?.total) {
            change(data?.num + 1)
            newPage = 1
        } else {
            newPage = currentPage
        }
        setCurrentPage(newPage)
        setTimeout(() => {
            justSwitched.current = false
        }, 500)
    }
    const showDetails = () => {
        setExpand(!expand)
    }
    const verifyStatus = {
        success: {
            label: 'AI-processed',
            icon: '/images/document/verify-success.svg',
            text: 'Please review the AI-extracted data and confirm its accuracy to proceed.',
            color: '#008334'
        },
        unknownFileType: {
            label: 'Unknown file type',
            icon: <div className='verify-status'>?</div>,
            text: "Our AI does not recognize this file. Here is an AI-generated summary of the file's contents.",
            color: '#CB8600'
        },
        unsuccess: {
            label: 'AI-scan unsuccessful',
            icon: <div className='verify-status unsuccess'>?</div>,
            text: "Our AI couldn't scan this file due to its quality. Please request a resubmission from the borrower or manually review the file.",
            color: '#CB0000'
        },
        timeRangeMismatch: {
            label: 'Time range mismatch',
            icon: <div className='verify-status unsuccess'>!</div>,
            text: "This file is either outside the required time range or has expired. Please request a resubmission from the borrower.",
            color: '#CB0000'
        },
        submittedDocCorrect: {
            label: 'Incorrect document type',
            icon: <div className='verify-status unsuccess'>!</div>,
            text: "This file is not the correct document type. Please request a resubmission from the borrower.",
            color: '#CB0000'
        },
        fileProcessing: {
            label: 'The file is being analyzed by AI',
            icon: '/images/document/process.svg',
            text: "The file is currently being processed by AI. Please check back later to review the results. ",
            color: '#CB8600'
        }
    }
    const handleDoc = (e) => {
        handleDocument(e.key)
    }
    const dropdownItems = [
        {
            label: 'Request resubmission ',
            key: 'resubmission',
            icon: <img src='/images/document/document-resubmission-grey.svg'/>,
        },
        {
            type: 'divider',
        },
        {
            label: <span>Download file</span>,
            key: 'download',
            icon: <img src='/images/document-download-grey.svg' />,
        },
        {
            type: 'divider',
        },
        {
            label: 'Delete file',
            key: 'delete',
            icon: <img src='/images/document/document-delete-grey.svg'/>,
        },
        {
            type: 'divider',
        },
        {
            label: 'Upload a new file',
            key: 'upload',
            icon: <img src='/images/document-upload-grey.svg'/>,
        },
        // {
        //     type: 'divider',
        // },
        // {
        //     label: 'Print file',
        //     key: 'print',
        //     icon: <img src='/images/document/document-print-grey.svg'/>,
        // },
    ]
    const categoryOptions = [
        {
            label: 'Identification',
            value: 'identification'
        },
        {
            label: 'Income',
            value: 'income'
        },
        {
            label: 'Assets',
            value: 'assets'
        },
        {
            label: 'Credit',
            value: 'credit'
        },
        {
            label: 'Property',
            value: 'property'
        },
        {
            label: 'Miscellaneous',
            value: 'miscellaneous'
        },
        {
            label: 'Internal Documents',
            value: 'internalDocuments'
        }
    ]
    const computedWidth = mode === 'preview' ? width : pageWidth
    const getreviewStatus = () => {
        if (!generalValidity) {
            return 'fileProcessing'
        }
        if (['success', 'unknownFileType'].includes(generalValidity?.processResult?.status)) {
            return generalValidity?.processResult?.status
        } 
        if (generalValidity?.submittedDocCorrect === false) {
            return 'submittedDocCorrect'
        } 
        if (generalValidity?.timeRangeCorrect === false) {
            return 'timeRangeMismatch'
        }
        if (generalValidity?.processResult?.status === 'unsuccess') {
            return generalValidity?.processResult?.status
        }
    }
    const inputFormatter = (value) => {
        return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    const inputParser = (value) => {
        return value.replace(/\\s?|(,*)/g, '')
    }
    const displayBankStatement = (pageData) => {
        const updateFormData = (page, newExtractedData) => {
            const newFormData = formData.map((data) => {
              if (data.page === page) {
                return {
                  ...data,
                  extractedData: newExtractedData,
                }
              }
              return data
            })
            setFormData(newFormData)
        }
        const handleInputChange = (e, field, nestedField) => {
            const newValue = e && e.target ? e.target.value : e
            const newExtractedData = { ...pageData.extractedData }
            
            if (nestedField) {
              newExtractedData[nestedField] = newValue
            } else {
              newExtractedData[field] = newValue
            }
        
            updateFormData(pageData.page, newExtractedData)
          }
        
        const handleTableInputChange = (e, key, column) => {
            const newTransactions = pageData.extractedData.Transactions.map((item) => {
              if (item.key === key) {
                let newValue = e.target ? e.target.value : e
                if (column === 'type') {
                  newValue = newValue.charAt(0).toUpperCase() + newValue.slice(1)
                }
                return { ...item, [column]: newValue }
              }
              return item
            })
            updateFormData(pageData.page, { ...pageData.extractedData, Transactions: newTransactions })
        }
        return (
            <div className="bank-statement-form">
                <div className='bank-statement-title'>{data?.taskName}</div>
                <div className='bank-statement-subtitle'>Statement Information</div>
                <Row gutter={24}>
                    <Col span={12}>
                        <div className="form-item">
                            <div className="form-item-label">Bank name</div>
                            <Form.Item name={`${pageData.page}.BankName`} initialValue={pageData.BankName}>
                                <Input className="verify-input" onChange={(e) => handleInputChange(e, 'BankName')} />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <div className="form-item">
                            <div className="form-item-label">Client name</div>
                            <Form.Item name={`${pageData.page}.ClientName`} initialValue={pageData.ClientName}>
                                <Input className="verify-input" onChange={(e) => handleInputChange(e, 'ClientName')} />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="form-item">
                            <div className="form-item-label">Account number</div>
                            <Form.Item name={`${pageData.page}.AccountNumber`} initialValue={pageData.AccountNumber}>
                                <Input className="verify-input" onChange={(e) => handleInputChange(e, 'AccountNumber')} />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <div className="form-item">
                            <div className="form-item-label">Statement start date</div>
                            <Form.Item name={`${pageData.page}.StatementStartDate`} initialValue={pageData.StatementStartDate}>
                                <Input className="verify-input" onChange={(e) => handleInputChange(e, 'StatementStartDate')} />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="form-item">
                            <div className="form-item-label">Statement end date</div>
                            <Form.Item name={`${pageData.page}.StatementEndDate`} initialValue={pageData.StatementEndDate}>
                                <Input className="verify-input" onChange={(e) => handleInputChange(e, 'StatementEndDate')} />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <div className="form-item">
                            <div className="form-item-label">Client address</div>
                            <Form.Item name={`${pageData.page}.ClientAddress`} initialValue={pageData.ClientAddress}>
                            <Input className="verify-input" onChange={(e) => handleInputChange(e, 'ClientAddress')} />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <div className='bank-statement-subtitle'>Transaction Summary</div>
                <Row gutter={24}>
                    <Col span={12}>
                        <div className="form-item">
                            <div className="form-item-label">Starting Balance</div>
                            <Form.Item name={`${pageData.page}.StartingBalance`} initialValue={pageData.StartingBalance}>
                                <InputNumber
                                    className="verify-input w-100"
                                    prefix="$"
                                    min={0}
                                    formatter={inputFormatter}
                                    parser={inputParser}
                                    onChange={(e) => handleInputChange(e, 'StartingBalance')}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="form-item">
                            <div className="form-item-label">Ending Balance</div>
                            <Form.Item name={`${pageData.page}.EndingBalance`} initialValue={pageData.EndingBalance}>
                                <InputNumber
                                    className="verify-input w-100"
                                    prefix="$"
                                    min={0}
                                    formatter={inputFormatter}
                                    parser={inputParser}
                                    onChange={(e) => handleInputChange(e, 'EndingBalance')}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <div className='bank-statement-subtitle'>Transaction Activities</div>
                <div className="form-item">
                    <Table
                        className='bank-statement-table'
                        dataSource={pageData.extractedData.Transactions}
                        pagination={false}
                        columns={[
                            {
                                title: 'Date',
                                dataIndex: 'date',
                                key: 'date',
                                width: 100,
                                render: (text, record) => (
                                    <Input
                                        style={{
                                            fontFamily: 'Inter',
                                            height: 34,
                                            fontSize: 12,
                                            color: '#000',
                                            borderRadius: '6px',
                                            backgroundColor: '#FCFCFC',
                                            border: '1px solid #ECECEC'
                                        }}
                                        value={text}
                                        onChange={(e) => handleTableInputChange(e, record.key, 'date')}
                                    />
                                )
                            },
                            {
                                title: 'Description',
                                dataIndex: 'description',
                                key: 'description',
                                render: (text, record) => (
                                    <Tooltip placement="bottomLeft" arrow={false} title={text}>
                                        <Input
                                            style={{
                                                fontFamily: 'Inter',
                                                height: 34,
                                                fontSize: 12,
                                                color: '#000',
                                                borderRadius: '6px',
                                                backgroundColor: '#FCFCFC',
                                                border: '1px solid #ECECEC'
                                            }}
                                            value={text}
                                            onChange={(e) => handleTableInputChange(e, record.key, 'description')}
                                        />
                                    </Tooltip>
                                )
                            },
                            {
                                title: 'Deposit / Withdraw',
                                dataIndex: 'type',
                                key: 'type',
                                width: 80,
                                render: (text, record) => (
                                    <Input
                                        style={{
                                            fontFamily: 'Inter',
                                            height: 34,
                                            fontSize: 12,
                                            color: '#000',
                                            borderRadius: '6px',
                                            backgroundColor: '#FCFCFC',
                                            border: '1px solid #ECECEC'
                                        }}
                                        value={text}
                                        onChange={(e) => handleTableInputChange(e, record.key, 'type')}
                                    />
                                )
                            },
                            {
                                title: 'Amount',
                                dataIndex: 'amount',
                                key: 'amount',
                                render: (text, record) => (
                                    <InputNumber
                                        style={{
                                            fontFamily: 'Inter',
                                            height: 34,
                                            fontSize: 12,
                                            color: '#000',
                                            borderRadius: '6px',
                                            backgroundColor: '#FCFCFC',
                                            border: '1px solid #ECECEC'
                                        }}
                                        prefix="$"
                                        min={0}
                                        formatter={inputFormatter}
                                        parser={inputParser}
                                        value={text}
                                        onChange={(e) => handleTableInputChange(e, record.key, 'amount')}
                                    />
                                )
                            },
                        ]}
                    />
                </div>
            </div>
        );
    };


    const renderFormItems = (docType, data, prefix = '', level = 0) => {
        return Object.entries(data).map(([key, value]) => {
            const name = prefix ? `${prefix}.${key}` : key;

            if (key === "DocumentTimeRange") {
                return ""
            }
    
            if (typeof value === 'object' && value !== null) {
                return (
                    <div key={name}>
                        <div className='form-item-label' style={{ fontWeight: 600, paddingLeft: level * 20 }}>{capitalize(divideStringByCapitalLetter(key))}</div>
                        {renderFormItems(docType, value, name, level + 1)}
                    </div>
                );
            }
    
            return (
                <Row gutter={10}>
                    <Col span={12} className='form-item-label' style={{ paddingLeft: level * 20 }}>{capitalize(divideStringByCapitalLetter(key))}</Col>
                    <Col span={12}>
                        <Form.Item name={name} key={name}>
                            {typeof value === 'boolean' ? (
                                <Select style={{ height: 34, width: '100%' }}>
                                    <Option label="Yes" value={true}>Yes</Option>
                                    <Option label="No" value={false}>No</Option>
                                </Select>
                            ) : (
                                <TextArea
                                    className='verify-textarea'
                                    autoSize={{
                                        minRows: 1,
                                        maxRows: 4,
                                    }}
                                    style={{ width: '100%' }} 
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            );
        });
    }
    const getCategoryOptions = () => {
        const filtered = categoryOptions.filter(option => {
            const category = docmentList.find(doc => doc.type === option.value)
            return category && category.list.length > 0
        })
        return filtered
    }
    const getTypeOptions = () => {
        const selectedDocument = docmentList.find(item => item.type === selectCategory)
        const options = selectedDocument ? selectedDocument.list.map(doc => ({ label: doc.TaskName, value: doc.ID })) : []
        return options
    }
    const changeCategory = (value) => {
        setSelectType(undefined)
        setSelectCategory(value)
    }
    const changeType = (value) => {
        setSelectType(value)
    }
    const changeMove = () => {
        moveFile(selectType)
    }
    const handleSave = (type) => {
        if (type === 'manual') {
            manualVerify()
            return
        }
        const isBankStatement = extractedData.some(item => item.docType === 'BankStatement')
        const data = isBankStatement ? splitMergedData(formData, extractedData) : formData
        if (type === 'complete') {
            complete(data)
        } else {
            verify(data)
        }
    }
    const modalTopText = (mode) => {
        switch (mode) {
            case 'verify':
              return 'Document Review'
            case 'preview':
              return data?.taskName
            case 'move':
              return capitalize(data?.docType)
            case 'sort':
              return 'Unsorted Files'
            default:
              return ''
        }
    }
    const getFileNameList = (ids) => {
        const fileNames = ids.map(id => {
            const file = data?.documents.find(file => file.ID === id)
            return file ? file.FileName : null
        }).filter(fileName => fileName !== null)
        return fileNames.join(',')
    }
    return (
        <>
            <Modal
                className={`document-preview-modal check-list ${mode === 'preview' ? 'preview-mode' : 'verify-mode'}`}
                maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.85)' }}
                maskClosable={true}
                width='90%'
                open={open}
                title=""
                onCancel={previewCancel}
                footer={null}
            >
                <div className='preview-modal-top'>{modalTopText(mode)}</div>
                <div className='file-action'>
                    <div className='download-directory' style={mode === 'sort' ? {width: '100%'} : {}}>
                        {mode === 'verify' && (
                            <div style={{marginLeft: 46}}><span style={{fontWeight: 600}}>{data?.docType.toUpperCase()}</span> | {data?.taskName}</div>
                        )}
                        {mode === 'move' && <div style={{marginLeft: 46}}>{data?.taskName}</div>}
                        {mode === 'sort' && <div style={{marginLeft: 46}}><span>{data?.FileName}</span><span style={{color: '#545459', fontSize: 14, fontWeight: 400, marginLeft: '6%'}}>Uploaded {data?.Uploaded ? dayjs(data.Uploaded).format('MM/DD/YYYY') :"-"}</span></div>}
                    </div>
                    {
                        (mode !== 'move' && mode !== 'sort') && (
                            <div className='change-file'>
                                <img className={`prev-file ${data?.num === 1 && 'arrow-disabled'}`} src={`/images/document-arrow-left${data?.num === 1 ? '-disabled' : ''}.svg`} onClick={() => changeFile('prev')}/>
                                <div className='flie-total'>File <span>{data?.num} / {data?.total}</span></div>
                                <div className='separate'></div>
                                <div className='file-name'><img src={ data?.MimeType === "application/pdf" ? '/images/pdf-icon.svg' : '/images/img-icon.svg'}/><div>{removeFileExtension(data?.FileName)}</div></div>
                                <img className={`next-file ${data?.num === data?.total && 'arrow-disabled'}`} src={`/images/document-arrow-right${data?.num === data?.total ? '-disabled' : ''}.svg`} onClick={() => changeFile('next')}/>
                            </div>
                        )
                    }
                    <div className='right-action'>
                        {
                            mode === 'verify' ? (
                                <>
                                    <div className={`details-btn ${expand ? 'show' : 'hidden'}`} onClick={showDetails}><span>Details</span><img src='/images/document/arrow-down.svg' /></div>
                                    <Dropdown
                                        overlayClassName="document-checklist-dropdown"
                                        menu={{
                                            items: dropdownItems,
                                            onClick: (e) => handleDoc(e)
                                        }}
                                        placement="bottomRight"
                                        trigger={['click']}
                                    >
                                        <img style={{cursor: 'pointer', marginRight: 24}} src='/images/document/verify-menu.svg' />
                                    </Dropdown>
                                </>
                            ) : mode === 'preview' ? <img className='download-file' style={{ cursor: 'pointer', marginRight: 24 }} onClick={previewDownload} src="/images/document-download-black.svg" /> : null
                        }
                    </div>
                </div>
                <Row style={{flex: 1}}>
                    <Col style={{position: 'relative'}} span={mode === 'preview' ? 24 : 14}>
                        <div className='document-preview-content' ref={containerRef} onScroll={handleScroll}>
                            <div style={{ width: computedWidth * scale }} className='preview-box'>
                                {
                                    data?.MimeType === "application/pdf" ? (
                                        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                                            {Array.from(new Array(numPages), (el, index) => (
                                                <div id={`pdfPage-${index + 1}`} key={`page_${index + 1}`} style={{ width: computedWidth * scale }} className="pdf-anchor">
                                                    <Page pageNumber={index + 1} width={ computedWidth * scale } />
                                                </div>
                                            ))}
                                        </Document>
                                    ) : (
                                        <Image
                                            preview={false}
                                            width={computedWidth * scale}
                                            src={createImageURLFromBase64(file, data?.MimeType )}
                                        />
                                    )

                                }
                            </div>
                        </div>
                        <div className='document-preview-bottom-bar'>
                            <div className="document-preview-pagination">
                                <Pagination size='small' simple current={data?.MimeType === "application/pdf" ? currentPage : 1} pageSize={1} total={data?.MimeType === "application/pdf" ? numPages : 1} onChange={onChangePage} />
                            </div>
                            <div className="document-preview-scale">
                                <div className='zoom-min'><MinusOutlined style={{ fontSize: 16 }} onClick={() => changeZoom('min')} /></div>
                                <div className='zoom-value'>{Math.round(scale * 100)}%</div>
                                <div className='zoom-add'><PlusOutlined style={{ fontSize: 16 }} onClick={() => changeZoom('add')} /></div>
                            </div>
                        </div>
                    </Col>
                    {
                        mode === 'verify' && (
                            <Col span={10}>
                                <div className='document-verify-content'>
                                    <div className='verify-info'>
                                        <div className='verify-title'>{getreviewStatus() === 'success' || getreviewStatus() === 'fileProcessing' ? <img src={verifyStatus[getreviewStatus()]?.icon} /> : verifyStatus[getreviewStatus()]?.icon}<span className='verify-label' style={{color: verifyStatus[getreviewStatus()]?.color}}>{verifyStatus[getreviewStatus()]?.label}</span></div>
                                        <div className='verify-description'>{verifyStatus[getreviewStatus()]?.text}</div>
                                    </div>
                                    <div style={getreviewStatus() !== 'success' ? {padding: 24} : {}} className='verify-middle' ref={rightContainerRef} onScroll={handleRightScroll}>
                                        {getreviewStatus() === 'unknownFileType' && <div className='unknown-card'><div>{generalValidity.processResult.message}</div></div>}
                                        {getreviewStatus() === 'success' ? (
                                            <Form
                                                className='extracted-form'
                                                form={form}
                                                onValuesChange={handleValuesChange}
                                            >
                                                {formData.map((pageData, index) => (
                                                    <div className='extracted-page' style={pageData.docType !== "BankStatement" ? {borderBottom: '1px solid #DBDCDE'} : {}} key={index} id={`formPage-${index + 1}`}>
                                                        {
                                                            pageData.docType === "BankStatement" ? displayBankStatement(pageData) :
                                                        <>
                                                            <div className='extracted-header'><div>Title</div><div>Value</div></div>
                                                            {renderFormItems(pageData.docType, pageData.extractedData, `${pageData.page}`)}
                                                        </>
                                                        }
                                                        {pageData.docType !== "BankStatement" && <div className='page-info'>Page <span>{pageData.page} / {extractedData.length}</span>  in  File <span>{data?.num} / {data?.total}</span></div>}
                                                    </div>
                                                ))}
                                            </Form>
                                        ): <div className='resubmission-btn' onClick={() => handleDoc({key: 'resubmission'})}>Request document resubmission</div>}
                                    </div>
                                    <div style={getreviewStatus() === 'success' ? {paddingTop: 0} : {}} className='verify-action'>
                                        {getreviewStatus() !== 'success' && <div style={{color: '#6E6E70', lineHeight: 'normal', marginBottom: 50}}>At least one file is not AI-readable due to the file format or quality. Please verify <span style={{color: '#222'}}>{data?.taskName}</span> manually or request {getreviewStatus() === 'unknownFileType' ? 'your borrower to resubmit their document' : 'a resubmission'}.</div>}
                                        <div style={{textAlign: 'right'}}>
                                            {getreviewStatus() === 'success' ? 
                                                (mappingData && Object.keys(mappingData).length !== 0 ? <div className='verify-btn smart' onClick={() => handleSave('verify')}><div>Smart Verify</div><img src='/images/document/verify-smart.svg' /></div> : <div className='verify-btn smart' onClick={() => handleSave('complete')}><div>Complete verification</div></div>) : 
                                                <div className='verify-btn manual' onClick={() => handleSave('manual')}><div>Manual Verify</div><img src='/images/document/verify-manual.svg' /></div>
                                            }
                                        </div>
                                        
                                    </div>
                                    <div className={`details-collapse ${expand ? 'show' : 'hidden'}`}>
                                        <div className='details-title'>{data?.docType.toUpperCase()} | {data?.taskName}</div>
                                        <div className='details-content'>
                                            <Row gutter={20} className='details-basic'>
                                                <Col className='details-item' span={12}>
                                                    <div className='item-label'>Time frame</div>
                                                    <div className='item-value'>{data?.timeRange}</div>
                                                </Col>
                                                <Col className='details-item' span={12}>
                                                    <div className='item-label'>Due date</div>
                                                    <div className='item-value'>{dayjs(data?.duedate).format('MM/DD/YYYY')}</div>
                                                </Col>
                                                <Col className='details-item' span={24}>
                                                    <div className='item-label'>Message to borrower</div>
                                                    <div className='item-value'>{data?.description !== '' ? data?.description : '-'}</div>
                                                </Col>
                                            </Row>
                                            <div className='resubmission-list'>
                                                {
                                                    resubmissionRecord.map((item, index) => (
                                                        <div className='resubmission-card' key={index}>
                                                            <div className='card-title'><div className='card-title-left'>Resubmission request</div><div className='sent-time'>Sent on {dayjs(item.RequestTime).format('MM/DD/YYYY')}</div></div>
                                                            <Row gutter={20} className='details-basic'>
                                                                <Col className='details-item' span={12}>
                                                                    <div className='item-label'>Files to be resubmitted</div>
                                                                    <div className='item-value'>{item.Files && item.Files.length > 0 ? getFileNameList(item.Files) : '-' }</div>
                                                                </Col>
                                                                <Col className='details-item' span={12}>
                                                                    <div className='item-label'>Resubmission due date</div>
                                                                    <div className='item-value'>{item.Deadline}</div>
                                                                </Col>
                                                                <Col className='details-item' span={24}>
                                                                    <div className='item-label'>Message to borrower</div>
                                                                    <div className='item-value'>{item.Description === '' ? '-' : item.Description}</div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div className='details-arrow-wrap'><img className='details-arrow' src='/images/document/arrow-details.svg' onClick={() => setExpand(false)}/></div>
                                    </div>
                                </div>
                            </Col>
                        )
                    }
                    {
                        (mode === 'move' || mode === 'sort') && (
                            <Col span={10}>
                                <div className='move-content'>
                                    <div style={{width: '100%'}}>
                                        <div className='file-name'><img src={ data?.MimeType === "application/pdf" ? '/images/pdf-icon.svg' : '/images/img-icon.svg'}/><div>{removeFileExtension(data?.FileName)}</div></div>
                                        <div className='tips'>Select document category and type.</div>
                                        <div className='move-form-item'>
                                            <div className='move-form-label'>Document category</div>
                                            <Select
                                                className='moveFile-select'
                                                style={{
                                                    width: '100%',
                                                }}
                                                value={selectCategory}
                                                allowClear
                                                onChange={changeCategory}
                                                placeholder="Select category"
                                                options={getCategoryOptions()}
                                            />
                                        </div>
                                        <div className='move-form-item'>
                                            <div className='move-form-label'>Document type</div>
                                            <Select
                                                className='moveFile-select'
                                                style={{
                                                    width: '100%',
                                                }}
                                                value={selectType}
                                                allowClear
                                                onChange={changeType}
                                                placeholder="Select type"
                                                options={getTypeOptions()}
                                            />
                                        </div>
                                    </div>
                                    <Button className="move-btn-confirm" type="primary" disabled={selectCategory === undefined || selectType === undefined} onClick={changeMove}>Confirm</Button>
                                </div>
                            </Col>
                        )
                    }
                </Row>
            </Modal>
        </>
    );
}
