import React, { useState, useEffect, useRef } from "react";
import { useBreakPoints, useViewport } from "../Common/Hooks";
import { BrowserRouter as Router } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Input, Result } from "antd";
import { Form, Col, Row, InputGroup, Button, Container } from "react-bootstrap";
import { Spin, Alert, Divider, Select, Tooltip, Tabs,Modal   } from "antd";
import { CloseOutlined, QuestionCircleOutlined, UpCircleFilled,RightOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import * as com from "../Common"
import "./GMCCCalculator.css";
import * as st from "../State"
import BootStrapModal from "react-bootstrap/Modal";

import AddressAutocomplete from "../Common/AddressAutocomplete";
import { CommaizedInput } from "../NumericalInput";
import DividerWithText from "../Common/components/DividerLineWithText";
import CRACalculator from "../CRACalculator/CRACalculator";
import { GMCCDisclaimer } from "./Disclaimer";
import GMCCUniverseCalculator from "../Tools/MortgageCalculators/GMCCUniverseCalculator";
import ProgramInitialGuide from "./PogramInitialGuide";
import { LeftOutlined } from "@ant-design/icons";
import { FloatingBubble } from "antd-mobile";
import { useInView } from "react-intersection-observer";
import { AffluentQualifier } from "./AffluentCalculator";
import GMCCHermesQualifier from "./GMCCHermes";


const Matrices = require("./ProgramEngineMatrices.json");

const CelebrityQualifierComponent = (props) => {
  const [downPayment, setDownPayment] = useState("");
  const [currentpropertyAddress, setCurrentPropertyAddress] = useState("");
  const [currentpropertyState, setCurrentPropertyState] = useState("");
  const [currentpropertyCounty, setCurrentPropertyCounty] = useState("");
  const [currentpropertyCity, setCurrentPropertyCity] = useState("");
  const [currentpropertyZip, setCurrentPropertyZip] = useState("");
  const [displayedCurrentAddress, setDisplayedCurrentAddress] = useState("");

  const [propertyAddress, setPropertyAddress] = useState("");
  const [displayedAddress, setDisplayedAddress] = useState("");
  const [propertyState, setPropertyState] = useState("");
  const [propertyCounty, setPropertyCounty] = useState("");
  const [propertyCity, setPropertyCity] = useState("");
  const [propertyZip, setPropertyZip] = useState("");
  const [propertyValue, setPropertyValue] = useState("");
  const [fico, setFico] = useState("")
  const [firstGeneration, setFirstGeneration] = useState(false);

  const [income, setIncome] = useState("");
  const [qualified, setQualified] = useState(null)
  const [unqualifiedReason, setUnqualifiedReason] = useState("")
    
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [medianIncome, setMedianIncome] = useState(null);
  const [loanLimit, setLoanLimit] = useState(null)

  const [borrowerName, setBorrowerName] = useState("")
  const [borrowerPhone, setBorrowerPhone] = useState("")
  const [borrowerEmail, setBorrowerEmail] = useState("")
  const [borrowerPreferredTimeZone, setBorrowerPreferredTimeZone] = useState("")
  const [borrowerPreferredTime, setBorrowerPreferredTime] = useState("")
  const [loadingContact, setLoadingContact] = useState(false)
  const [language, setLanguage] = useState("english")
  const [grantValue, setGrantValue] = useState(0)
  const [loadingTractData, setLoadingTractData] = useState(false)
  const [mfi, setMfi] = useState(null)
  const [incomeIndicator, setIncomeIndicator] = useState(null)
  const [minority, setMinority] = useState(null)
  const [units, setUnits] = useState("1")
  const [incomeIndicatorCurrentAddr, setIncomeIndicatorCurrentAddr] = useState(null)
  const [minorityCurrentAddr, setMinorityCurrentAddr] = useState(null)

  const loName = props.loName
  const loEmail = props.loEmail
  const loNMLS = props.loNMLS
  const loPhone = props.loPhone
  const program = props.program ? props.program : "celebrity15K"
  let avatarSrc = "/images/avatar.png"
  const email = props?.aboutInfo?.email
  const phone = props?.aboutInfo?.phone
  let qualifiedCounties = {
    "California": ["Contra Costa", "Los Angeles", "Napa", "Orange", "Placer", "Riverside", "Sacramento", "San Bernardino", "San Diego", "San Francisco", "San Mateo", "Santa Clara", "Ventura"],
    "Massachusetts": ["Essex", "Middlesex", "Norfolk", "Suffolk"],
    "North Carolina": [],
    "South Carolina": [],
  }  

  const zeroPercentDownQualifiedCounties = {
    "California": ["San Francisco", "San Mateo", "Santa Clara", "Sacramento", "Contra Costa","Los Angeles", "Orange", "Riverside", "San Bernardino", "San Diego", "Placer", "Napa", "Ventura"],
    // "Georgia": ["Barrow", "Bartow", "Cherokee", "Clayton", "Cobb", "DeKalb", "Douglas", "Fayette", "Forsyth", "Fulton", "Gwinnett", "Henry", "Paulding", "Rockdale", "Spalding"],
    "Massachusetts": ["Essex", "Middlesex", "Norfolk", "Suffolk"],
    "North Carolina": [],
    "South Carolina": [],
    "Virginia": ["Albermarle","Alleghany","Arlington","Augusta","Bedford","Botetourt","Brunswick","Campbell","Carroll","Charlottesville City","Chesterfield","Covington City","Danville City",
                "Emporia City","Fairfax","Fairfax City","Frederick","Fredericksburg City","Galax City","Greensville","Harrisonburg City","Henrico","Loudoun","Lunenburg","Lynchburg City",
                "Mecklenburg","Montgomery","Nottoway","Pittsylvania","Roanoke","Roanoke City","Rockingham","Salem City","Spotsylvania","Staunton City","Warren","Waynesboro City","Winchester City"],
  }

  if (program === "zeroPercentDown") qualifiedCounties = zeroPercentDownQualifiedCounties

  
  useEffect(() => {
    if (propertyState !== "" && propertyCounty !== "") {
      setLoadingTractData(true)
      fetch("/data/loanlimitswithincome", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Address: propertyAddress,
          State: propertyState,
          City: propertyCity,
          Zip: propertyZip,
        })
      })
        .then((res) => {
          if (!res.ok) {
            console.log(res);
            // setShowError(true)
            return
          }
          res.json().then((js) => {
            let conformingLoanLimit = [766550, 981500, 1186350, 1474400]
            let highCost = false
            let ll = ""
            switch (units) {
              case "1":
                ll = js.OneUnit
                // setLoanLimit(js.OneUnit)
                if (js.OneUnit > conformingLoanLimit[0]) {
                  highCost = true
                  // setIsHighCostArea(true)
                  break
                }
              case "2":
                ll = js.TwoUnit
                // setLoanLimit(js.TwoUnit)
                if (js.TwoUnit > conformingLoanLimit[1]) {
                  highCost = true
                  // setIsHighCostArea(true)
                  break
                }
              case "3":
                ll = js.ThreeUnit
                // setLoanLimit(js.ThreeUnit)
                if (js.ThreeUnit > conformingLoanLimit[2]) {
                  highCost = true
                  // setIsHighCostArea(true)
                  break
                }
              case "4":
                ll = js.FourUnit
                // setLoanLimit(js.FourUnit)
                if (js.FourUnit > conformingLoanLimit[3]) {
                  highCost = true
                  // setIsHighCostArea(true)
                  break
                }
              default:
                break
            }
            setMedianIncome(js.MedianIncome)
            setIncomeIndicator(js.IncomeIndicator)
            setMinority(js.MinorityPercent)
            setLoadingTractData(false)
        })
    })
    .catch((err) => {
        console.error(err);
        alert("Cannot get tract info. Please try again later.")
    });
    }

  },[propertyState,propertyCounty,propertyAddress])


  const handleLanguageChange = (value) => {
    setLanguage(value)
  }

  const languageOptions = [
    { value: "english", label: "English" },
    { value: "chinese", label: "Chinese" },
    // { value: "spanish", label: "Spanish" },
  ]

  const applyUrl = props.loid==="gmcc" ? 
  "https://idp.elliemae.com/authorize?client_id=srtrz0d2&site_id=1796774571&response_type=code&redirect_uri=https%3A%2F%2Fwww.gmccloan.com%2Fborrower-app%2Flogin%2F%3FfromLogin%3Dtrue%26siteId%3D1796774571&scope=ccbp%20cc&instance_id=be11078237&logo_url=https%3A%2F%2Fstore.asset.ellieservices.com%2Fc8e9e1d6-64c4-47f2-8e83-3fb89b5c506f&logo_alt_text=Logo&bust=26&apiBaseUrl=https://api.elliemae.com"
  :  
  props.appLink

  const onStreetAddressCompletion = (address) => {
    let num = com.getSubaddress(address, "street_number", "long_name");

    let city = com.getSubaddress(address, "locality", "long_name");
    setPropertyCity(city)
    let state = com.getSubaddress(
      address,
      "administrative_area_level_1",
      "short_name"
    );
    
    let zip = com.getSubaddress(address, "postal_code", "long_name");
    setPropertyZip(zip)

    let county = com.getSubaddress(
      address,
      "administrative_area_level_2",
      "long_name"
    );
    setPropertyCounty(county)
    let longstate = com.getSubaddress(
      address,
      "administrative_area_level_1",
      "long_name"
      );
    setPropertyState(longstate)

    let sfx = "";
    let short = com.getSubaddress(address, "route", "long_name");
    setPropertyAddress(num + " " + short)
    let stradd = num + " " + short + ", " + city + ", " + state + " " + zip;
    setDisplayedAddress(stradd)
  };


  const handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setShowError(false)
    setQualified(null)
    setUnqualifiedReason("")
    setMedianIncome(null)
    setLoanLimit(null)

    setLoading(true)
    fetch("/data/gethudmedianfamilyincome", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        State: propertyState,
        County: propertyCounty,
      })
    })
    .then((res) => {
      if (!res.ok) {
        console.log(res);
        setLoading(false)
        setShowError(true)
        return
      }
      res.json().then((js) => {
        // setMedianIncome(js.MedianIncome)
        setMfi(js.MedianIncome)
        setLoanLimit(js.LoanLimit)
        setLoading(false)

        let mutifier = 1.5
        let minLTV = 80
        let maxLTV_15K = 97
        let maxLTV = 100
        let currentLTV = (com.safeParseInt(propertyValue) - com.safeParseInt(downPayment)) / com.safeParseInt(propertyValue) * 100
        if (program === "zeroPercentDown") minLTV = 0
        if (program === "zeroPercentDown" && com.safeParseInt(propertyValue) - com.safeParseInt(downPayment) > 766550) maxLTV = 95
        if (firstGeneration) mutifier = 2

        if (program === "celebrity15K" && fico !== "" && com.safeParseInt(fico) < 620) {
          setQualified(false)
          setUnqualifiedReason("The program requires a minimum FICO score of 620 . Please contact your loan officer for more information." )
          return
        }

        if (program === "celebrity15K" && (minority <=50 && incomeIndicator !== "1" && incomeIndicator !== "2" && income > 0.8*medianIncome)) {
          setQualified(false)
          setUnqualifiedReason("The program requires the borrower to moving to a Majority Minority Tract area or low-moderate income area. Please contact your loan officer for more information." )
          return
        }

        if (program === "zeroPercentDown" && fico !== "" && com.safeParseInt(fico) < 680) {
          setQualified(false)
          setUnqualifiedReason("The program requires a minimum FICO score of 680 . Please contact your loan officer for more information." )
          return
        }

        if (qualifiedCounties[propertyState] !== undefined && (qualifiedCounties[propertyState].length===0|| qualifiedCounties[propertyState].includes(propertyCounty.replace("County", "").trim()))) {
          if (js.LoanLimit && com.safeParseInt(propertyValue)- com.safeParseInt(downPayment) > com.safeParseInt(js.LoanLimit)) {
            console.log(propertyValue)
            setQualified(false)
            setUnqualifiedReason("The loan limit of this program in the selected area is $" + com.commaize(js.LoanLimit) + ". Please contact your loan officer for more information." )
            return
          }
          
          if (com.safeParseInt(income) <= com.safeParseInt(js.MedianIncome) * mutifier) {
            setQualified(true)
          } else {
            setQualified(false)
            setUnqualifiedReason("The median family income in the selected area is $" + com.commaize(js.MedianIncome) + ", and the income to qualify can not exceed 150% or 200%. Please contact your loan officer for more information." )
            return
          }
          if (program === "celebrity15K" && currentLTV < minLTV) {
            setQualified(false)
            setUnqualifiedReason("Your down payment should not exceed 20% of your property value in order to qualify for this program. However, exceptions for up to 25% down payment is accecptable if in Bay area. Please contact your loan officer for more information." )
            return
          }
          if (program === "celebrity15K" && currentLTV > maxLTV_15K) {
            setQualified(false)
            setUnqualifiedReason("The maximum loan to value ratio(LTV) with this program for the current loan amount is " + maxLTV_15K + "%. "+"Please contact your loan officer for more information." )
            return
          }
          let grantAmount = 0
          if (program === "celebrity15K"){
            if (propertyState === "California") {
              grantAmount = 10000
            }
            if (propertyState === "Massachusetts") {
              grantAmount = 30000
            }
            if (propertyState === "North Carolina" || propertyState === "South Carolina") {
              grantAmount = 5000
            }
          }
          setGrantValue(grantAmount)
          if (program === "zeroPercentDown" && currentLTV > maxLTV) {
            setQualified(false)
            setUnqualifiedReason("The maximum loan to value ratio(LTV) with this program for the loan amount over $766,500 (HB) is " + maxLTV + "%. "+"Please contact your loan officer for more information." )
            return
          }
        } else {
          setQualified(false)
          setUnqualifiedReason("Based on your income and the property address of your purchase, you may not qualify for the free $15k grant. However, there may be additional options that could allow you to qualify for this grant or other programs. Please contact your GMCC loan officer as soon as possible for further details. Note that this does not represent any credit decision.")
        }
      });
    })
    .catch((err) => {
      console.error(err);
      alert("Some internal error happened. Please try again later.")
    });
  }
  const submitContact = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setLoadingContact(true)
    fetch("/borrower/gmcc/contactme", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Address: propertyAddress,
        State: propertyState,
        City: propertyCity,
        Zip: propertyZip,
        Income: String(com.safeParseInt(income)),
        MedianIncome: String(medianIncome===null?"":medianIncome),
        FirstGeneration: firstGeneration,
        From:"Celebrity",

        BorrowerName: borrowerName,
        BorrowerPhone: borrowerPhone,
        BorrowerEmail: borrowerEmail,
        BorrowerPreferredTimeZone: borrowerPreferredTimeZone,
        BorrowerPreferredTime: borrowerPreferredTime,

        LOID: props.loid,
      })
    })
    .then((res) => {
      if (!res.ok) {
        setLoadingContact(false)
        console.log(res);
        return
      }

      setLoadingContact(false)
      alert("Thanks for your submission. We will contact you shortly.")

    })
    .catch((err) => {
      console.error(err);
      setLoadingContact(false)
      alert("Some internal error happened. Please try again later.")
    });

  }

  const contactMe = <Row className="mt-5">
   {typeof props.basicInfo === "undefined" && <Col></Col>}
  <Col md={6} xs={12} className="text-left">
    <div className="callbackTitle-1 mb-2">Request a call back from your loan officer</div>
    {loEmail !== null  && 
      <div className="callback-lo">
        <div className="callbackTitle-3 mb-1"><img src="/images/loanofficer.svg" style={{width:30}}/>Your GMCC Loan Officer</div>
        <div className="callback-loinfo">
          {props.loid !== "gmcc" && <div className="callbackTitle-4">{loName}</div>}
          <div className="callbackTitle-4">{loEmail}</div>
          {props.loid !== "gmcc" && <div className="callbackTitle-4">{loPhone!==null? com.fixPhoneInput(loPhone):""}</div>}
          <div className="callbackTitle-4">NMLS #{loNMLS}</div>
        </div>
      </div>}
    <Form onSubmit={submitContact} className="contactmeForm">
      <Form.Group controlId="name">
        <Form.Label className="callbackTitle-2">Your name</Form.Label>
        <Form.Control type="text" placeholder="Enter your name" onChange={e=>setBorrowerName(e.target.value)} required/>
      </Form.Group>
      <Form.Group controlId="phone">
        <Form.Label className="callbackTitle-2">Your phone number</Form.Label>
        <Form.Control type="text" placeholder="Enter your phone number" onChange={e=>setBorrowerPhone(com.fixPhoneInput(e.target.value))} value={borrowerPhone} required/>
      </Form.Group>
      <Form.Group controlId="email">
        <Form.Label className="callbackTitle-2">Your email</Form.Label>
        <Form.Control type="email" placeholder="Enter your email" onChange={e=>setBorrowerEmail(e.target.value)} required/>
      </Form.Group>
      <div className="d-flex w-100 contactmeTime-mobileAdjuster">
      <Form.Group controlId="email" className="w-100 mr-2">
        <Form.Label className="callbackTitle-2">Preferred contact time</Form.Label>
        <Form.Control
                as="select"
                size="sm"
                value={borrowerPreferredTime}
                onChange={(e) => setBorrowerPreferredTime(e.target.value)}
                type="select"
                required
              >
                <option value="">Select your preferred time</option>
                <option value="8am - 9am">8am - 9am</option>
                <option value="9am - 10am">9am - 10am</option>
                <option value="10am - 11am">10am - 11am</option>
                <option value="11am - 12pm">11am - 12pm</option>
                <option value="12pm - 1pm">12pm - 1pm</option>
                <option value="1pm - 2pm">1pm - 2pm</option>
                <option value="2pm - 3pm">2pm - 3pm</option>
                <option value="3pm - 4pm">3pm - 4pm</option>
                <option value="4pm - 5pm">4pm - 5pm</option>
                <option value="5pm - 6pm">5pm - 6pm</option>
                <option value="6pm - 7pm">6pm - 7pm</option>
                <option value="7pm - 8pm">7pm - 8pm</option>
                <option value="8pm - 9pm">8pm - 9pm</option>

              </Form.Control>      
      </Form.Group>
      <Form.Group controlId="email" className=" w-100">
        <Form.Label className="callbackTitle-2">Time zone</Form.Label>
        <Form.Control
                as="select"
                size="sm"
                value={borrowerPreferredTimeZone}
                onChange={(e) => setBorrowerPreferredTimeZone(e.target.value)}
                type="select"
                required
              >
                <option value="">Select your time zone</option>
                <option value="Eastern">Eastern UTC-5</option>
                <option value="Central">Central UTC-6</option>
                <option value="Mountain">Mountain UTC-7</option>
                <option value="Pacific">Pacific UTC-8</option>
              </Form.Control>      
      </Form.Group>
      </div>
      <Button variant="zeitro-primary" type="submit" className="w-100" >
        {loadingContact ? <div className="gmccSpin"><Spin  /></div> : "Submit"}
      </Button>
      <DividerWithText text={"Or"}/>
      <Button variant="zeitro-primary" className="w-100" onClick={()=>window.open(applyUrl, '_blank', 'noopener,noreferrer')}>
        Apply Now
      </Button>
    </Form>
  </Col>
  {typeof props.basicInfo !== "undefined" ?   <Col>
      <div className='request_right  contactMeRequest'>
        <div style={{ position: 'relative' }} className='green'>
          <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
            <circle cx="28" cy="28" r="28" fill="#E3F5E3" />
          </svg>
          <div className='quote'>
            “
          </div>
        </div>
        <div className='Dream'>
          Make a Dream.

        </div>
        <div className='request_info'>
          <div className='line' />

          <div className='touch'>

            <div className='width_line' />
            <div className='touch_text'>We’ll be in touch.Shortly!</div>
          </div>
        </div>
        <div className='information '>

          <div>
          <div style={{ display: 'flex' }}> 
                              {/* <img className='info_img' src={typeof props.basicInfo.file !== "undefined" ? props.basicInfo.file[0].url : avatarSrc}></img> */}
                            <div className='info_span'>
                                <div>{props.basicInfo.firstname}</div>
                                <div>
                                    {props.basicInfo.lastname}
                                </div>
                            </div>
                        </div>
            <div>{email}</div>

          </div>


          <div >
            <div>NMLS {loNMLS}</div> <div > {phone}</div>
          </div>


        </div>
      </div>
    </Col> : <Col></Col>}   

  </Row>

  const showFlyer = () => {
    let p 
    if (program === "zeroPercentDown") {
      if (language === "english") p = "/images/gmccflyers/GMCC_0downFlyer_zeitro.png"
      if (language === "chinese") p = "/images/gmccflyers/GMCC_0downFlyer_zeitroChinese.png"
      return <img src={p} className="w-100 mt-1"/>
    } else {
      if (language === "english") p = "/images/gmccflyers/GMCC_10To15GrantFlyer_zeitro.png"
      if (language === "chinese") p = "/images/gmccflyers/GMCC_10To15GrantFlyer_zeitroChinese.png" 
      return <img src={p} className="w-100 mt-1"/>
    }
  }

  const displayedLoanAmount = () => {
    if (propertyValue === "" || downPayment === "") return ""
    let dp = com.safeParseInt(downPayment)
    let pp = com.safeParseInt(propertyValue)

    let loanAmount = pp - dp

    return <Row className="blueTips bold mb-2 mt-2">
    <Col>
      <div>Your loan amount is ${com.commaize(loanAmount)}</div>
    </Col>
    </Row>
  }

  const displayHighligths = () => {
    if (program === "zeroPercentDown") {
      return (
        <div>
          <div className="title-2 text-center mt-3 mb-2">GMCC - 0% Downpayment Program</div>
            <div  className="programHighlights">
              <div className="bold"><img src="/images/bulb.svg" style={{width:28}} /> Program highlights</div>
              <ul>
                <li>Primary purchase only</li>
                <li>No mortgage insurance required</li>
                <li>First-time Homebuyer not required</li>
                <li>Currently available for selected counties in CA, MA, NC, SC, VA</li>
                <li>$10-15K grant program also available if qualified for this program </li>
              </ul>
            </div> 
        </div>
      )
    }
    return (
      <div>
        <div className="title-2 text-center mt-3 mb-2">GMCC - Celebrity $5K-30K Grant Program</div>
          <div  className="programHighlights">
            <div className="bold"><img src="/images/bulb.svg" style={{width:28}} /> Program highlights</div>
            <ul>
              <li>Designed to support underserved borrowers and Communities</li>
              <li>Primary purchase only</li>
              <li>Up to $5K qualified for selected counties in NC,SC</li>
              <li>Up to $10K qualified for selected counties in CA</li>
              <li>Up to $30K qualified for selected counties in MA</li>
            </ul>
          </div> 
      </div>
    )
  }

  return (
    <>
      <Row className="qualifierWrap">
        <Col md={5} className="text-center">
          <div className="d-flex align-items-center mt-3" style={{justifyContent:"center",marginBottom:"10px"}}>
            <div className="mr-2">Change language to</div>
            <Select defaultValue="english" onChange={handleLanguageChange} options={languageOptions}/>
          </div>
          {showFlyer()}

        </Col>
        <Col md={7} style={{display:"flex",flexDirection:"column", justifyContent:"flex-start"}}>
          {displayHighligths()}
          <div className="hfaSubTitle text-center mb-4">
          Check your eligibility for special incentive programs with attractive rates and start your homeownership journey!
          </div>


          <div className="qualifierFormWrap">   
            <Form onSubmit={handleSubmit} className="qualifierForm">
            <Row>
              <Col md={12} xs={12}>
                <AddressAutocomplete
                id={props.program + "address"}
                label="Property address"
                required={true}
                name="addessline"
                defaultValue={displayedAddress}
                onChange={(e) => setDisplayedAddress(e.target.value)}
                pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                placeholder="Please type your home address and select from the dropdown addresses"
                goodfeedback="Looks good!"
                badfeedback="Please provide your street address."
                onCompletion={e=>onStreetAddressCompletion(e)}
                size="md"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} xs={12}>
                <Form.Label >Yearly income</Form.Label>
                  <CommaizedInput value={income} onChange={e=>setIncome(e.target.value)} withDollarSign required/>
              </Col>
              <Col md={6} xs={12} className="gmccMarginAdjuster">
                <Form.Group>
                  <Form.Label>FICO </Form.Label>
                  <Form.Control type="number" placeholder="" onChange={e=>setFico(e.target.value)} />
              </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6} xs={12} className="gmccMarginAdjuster">
                <Form.Label >Estimated property value</Form.Label>
                  <CommaizedInput value={propertyValue} onChange={e=>setPropertyValue(e.target.value)} withDollarSign required/>
              </Col>
              <Col md={6} xs={12} className="gmccMarginAdjuster">
                <Form.Label >Down payment</Form.Label>
                  <CommaizedInput value={downPayment} onChange={e=>setDownPayment(e.target.value)} withDollarSign required/>
              </Col>
            </Row>
            {displayedLoanAmount()}
            <Row className="mt-1">
              <Col className="d-flex align-items-center">
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check type="checkbox" label={<div>I am first generation home buyer          <Tooltip title={<div>
                <div>A first generation home buyer is:</div>
                <li>- A borrower who has not owned a home in the last three years and whose Parent(s) never owned a home, or</li>
                <li>- A borrower who was in foster care</li>
                <li>- A borrower who was raised by Guardian(s) meets the definition of a First-Generation homebuyer regardless of Guardian’s property ownership status.</li>
              </div>}>
              <span><QuestionCircleOutlined className="ml-2 "/></span>
              </Tooltip></div>} checked={firstGeneration} onChange={(e)=>setFirstGeneration(e.target.checked)} />
              </Form.Group>

              </Col>
            </Row>
            <Row className="ml-2">
              <Col className="text-center" >
                <Button type="submit" variant="zeitro-primary">{loading || loadingTractData? <div className="gmccSpin"><Spin  /></div>: "Check my eligibility"}</Button>
              </Col>
            </Row>

            </Form>
            
            {!loading && showError && <div className="mx-2 mt-3">
              <Alert message="Sorry, we're unable to process the information provided. Please verify your address and select an option from the dropdown list." type="warning" showIcon/>
              </div>}
            {!loading && qualified ===true && program === "celebrity15K" && <Result
                status="success"
                title={`Congratulations! You could be eligible for up to $${com.commaize(grantValue)} in credits! `}
                subTitle={`Based on your income and the address of the property you're purchasing, you may qualify for a free $${grantValue/1000+"K"} grant. Additional restrictions or requirements may apply. Please contact your GMCC loan officer as soon as possible for further details and verification. This message does not represent any credit decision.`}
            />}
            {!loading && qualified ===true && program === "zeroPercentDown" && <Result
                status="success"
                title="Congratulations! You could be eligible for the 0% down payment program! "
                subTitle="Based on your income and the address of the property you're purchasing, you may qualify for the 0% down payment program and with eligibility to apply for a free $15K grant (to check your qualification, please go to the $15K grant program qualifier tab). Additional restrictions or requirements may apply. Please contact your GMCC loan officer as soon as possible for further details and verification. This message does not represent any credit decision."
            />}
            {!loading && qualified === false &&   <Result
                status="warning"
                title={unqualifiedReason.includes("median family income") ? "The subject property is qualified, but your income might exceed the limit." : "Unfortunately, it appears that you do not meet the eligibility requirements for the incentive programs at this time."}
                subTitle={<div>
                  <div>{unqualifiedReason}</div>
                </div>}
            />}
            <Divider />
            <Row className="qualifierDisclaimerWrap">
              <div className="disclaimerContainer">
                <div className="title-6 mb-2">
                  Disclaimer:
                </div>
                <div className="title-6 mb-2">
                  Subject to income limit and census tract with 20% down only (not exceeding, exception possible, consult with gmcc mlo), Conforming, FHA, community CRA program. For less than 20% down, no MI (0% down up to $766k conforming loan amount limit, 5% down up to high balance $1.149 million loan amount limit). Please consult GMCC MLO for the gmcc celebrity CRA program.
                </div>
                <div className="title-6">
                  All loans are subject to credit approval. Interest rates are subject to change daily and without notice. Current interest rates shown our indicative of market conditions and individual qualifications and will vary upon your lock-in period, loan type, credit score, purpose and loan to value.          
                </div>
              </div>
            </Row>
          </div>
        </Col>
      </Row>
      <Divider />
      <div className="mb-5">
        {contactMe}
      </div>
    </>
  );
  };

const calculateDimensions = (breakPoints, viewport) => {
  let w, h;
  if (breakPoints.xl) {
    w = 1000;
    h = 800;
  } else if (breakPoints.lg) {
    w = 800;
    h = 600;
  } else if (breakPoints.md) {
    w = 600;
    h = 400;
  } else if (breakPoints.sm || breakPoints.xs) {
    w = Math.round(viewport.width * 0.95);
    h = 300;
  }
  return { width: w, height: h };
};

function CelebrityQualifier(props) {

  const breakPoints = useBreakPoints();
  const viewport = useViewport();
  const { width, height } = calculateDimensions(breakPoints, viewport);
  const [loaded, setLoaded] = useState(false);

  const ref = useRef();

  useEffect(() => {
    const myObserver = new ResizeObserver((entries) => {
      if (entries.length > 0) {
        let height = entries[0].contentRect.height;
        let width = entries[0].contentRect.width;
        window.parent.postMessage(
          { source: "tools", payload: [width, height + 39] },
          "*"
        );
        // console.log("width:" + width + ", height: " + height);
      }
    });
    if (ref.current) myObserver.observe(ref.current);

  });

  return (
    <Router>


        <CelebrityQualifierComponent {...props} />

    </Router>
  );
}

export default withRouter(CelebrityQualifier);

export function UniverseQualifier(props) {
  const [propertyAddress, setPropertyAddress] = useState("");
  const [displayedAddress, setDisplayedAddress] = useState("");
  const [propertyState, setPropertyState] = useState("");
  const [propertyCounty, setPropertyCounty] = useState("");
  const [propertyCity, setPropertyCity] = useState("");
  const [propertyZip, setPropertyZip] = useState("");
  const [units, setUnits] = useState("1");
  const [fico, setFico] = useState("");
  const [citizenship, setCitizenship] = useState("");
  const [purpose, setPurpose] = useState("");
  const [occupancy, setOccupancy] = useState("");
  const [visa, setVisa] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [downPayment, setDownPayment] = useState("");
  const [cashOutAmount, setCashOutAmount] = useState("");
  const [hasPastMortgageLate, setHasPastMortgageLate] = useState("");


  const [qualified, setQualified] = useState(null)
  const [unqualifiedReason, setUnqualifiedReason] = useState("")

  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [medianIncome, setMedianIncome] = useState(null);

  const [borrowerName, setBorrowerName] = useState("")
  const [borrowerPhone, setBorrowerPhone] = useState("")
  const [borrowerEmail, setBorrowerEmail] = useState("")
  const [borrowerPreferredTimeZone, setBorrowerPreferredTimeZone] = useState("")
  const [borrowerPreferredTime, setBorrowerPreferredTime] = useState("")
  const [loadingContact, setLoadingContact] = useState(false)
  const [language, setLanguage] = useState("english")
  const [loanLimits, setLoanLimits] = useState({})
  const loName = props.loName
  const loEmail = props.loEmail
  const loNMLS = props.loNMLS
  const loPhone = props.loPhone
  let avatarSrc = "/images/avatar.png"
  const email = props?.aboutInfo?.email
  const phone = props?.aboutInfo?.phone

  const handleLanguageChange = (value) => {
    setLanguage(value)
  }

  const fetchLoanLimits = () =>
    fetch("/data/loanlimits")
      .then((res) => {
        if (!res.ok) {
          console.log(res);
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        setLoanLimits(res)
      })
      .catch((err) => {
        console.error(err);
      });

  useEffect(() => {
    fetchLoanLimits()
  }, [])

  const getStates = () => {
    let options = [];
    let keys = Object.keys(loanLimits);
    options.push(<option value=""></option>);
    keys.forEach((x) => {
      options.push(<option value={com.capitalize(x)}> {com.capitalize(x)}</option>);
    });
    return options;
  };

  const getCounties = () => {
    let options = [];
    options.push(<option value=""></option>);
    try {
      if (loanLimits[propertyState.toUpperCase()]) {
        let keys = Object.keys(loanLimits[propertyState.toUpperCase()]);
        keys.forEach((x) => {
          options.push(<option value={com.capitalize(x)}> {com.capitalize(x)}</option>);
        });
      }
    } catch (error) {
      console.log(error)
    }

    return options;
  };

  const languageOptions = [
    { value: "english", label: "English" },
    { value: "chinese", label: "Chinese" },
  ]

  const qualifiedCounties = {
    "California": ["San Francisco", "Alameda", "San Mateo", "Santa Clara", "Sacramento", "Contra Costa", "Los Angeles", "Orange", "Riverside", "San Bernardino", "San Diego"],
    "Georgia": ["Barrow", "Bartow", "Cherokee", "Clayton", "Cobb", "DeKalb", "Douglas", "Fayette", "Forsyth", "Fulton", "Gwinnett", "Henry", "Paulding", "Rockdale", "Spalding"],
    "Massachusetts": ["Middlesex", "Norfolk", "Suffolk"],
    "Nevada": ["Clark"],
    "Texas": ["Collin", "Dallas", "Fort Bend", "Harris"],
    "Washington": ["King", "Pierce", "Snohomish"],
    // "New York": ["Bronx", "Kings (Brooklyn)", "New York (Manhattan)", "Queens", "Richmond (Staten Island)"]
  }

  const visas = [
    st.VT_E_1,
    st.VT_E_2,
    st.VT_E_3,
    st.VT_F_1,
    st.VT_G_1,
    st.VT_G_2,
    st.VT_G_3,
    st.VT_G_4,
    st.VT_H_1B,
    st.VT_H_4,
    st.VT_L_1,
    st.VT_L_2,
    st.VT_O_1,
    st.VT_O_2,
    st.VT_O_3,
    st.VT_P_1,
    st.VT_P_2,
    st.VT_P_3,
    st.VT_P_4,
    st.VT_TN,
  ];

  const onStreetAddressCompletion = (address) => {
    let num = com.getSubaddress(address, "street_number", "long_name");

    let city = com.getSubaddress(address, "locality", "long_name");
    setPropertyCity(city)
    let state = com.getSubaddress(
      address,
      "administrative_area_level_1",
      "short_name"
    );

    let zip = com.getSubaddress(address, "postal_code", "long_name");
    setPropertyZip(zip)

    let county = com.getSubaddress(
      address,
      "administrative_area_level_2",
      "long_name"
    );
    setPropertyCounty(county)
    let longstate = com.getSubaddress(
      address,
      "administrative_area_level_1",
      "long_name"
    );
    console.log(longstate)
    setPropertyState(longstate)

    let short = com.getSubaddress(address, "route", "long_name");
    setPropertyAddress(num + " " + short)
    let stradd = num + " " + short + ", " + city + ", " + state + " " + zip;
    setDisplayedAddress(stradd)
  };


  const handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setShowError(false)
    setQualified(null)
    setUnqualifiedReason("")
    setMedianIncome(null)

    if (hasPastMortgageLate === "yes") {
      setQualified(false)
      setUnqualifiedReason("The program is not available for borrowers with late mortgage payments in the last 12 months.")
      return
    }

    // if (fico !== "" && com.safeParseInt(fico) < 680) {
    //   setQualified(false)
    //   setUnqualifiedReason("The program requires a minimum FICO score of 680. However, you might be qualified with the exception.")
    //   return
    // }

    if (qualifiedCounties[propertyState] !== undefined && (qualifiedCounties[propertyState].length === 0 || qualifiedCounties[propertyState].includes(propertyCounty.replace("County", "").trim()))) {
      if (downPayment !== "" && purchasePrice !== "") {
        let dp = com.safeParseInt(downPayment)
        let pp = com.safeParseInt(purchasePrice)
        let co = com.safeParseInt(cashOutAmount)

        let maxLtv = 0.6
        let maxLoanAmount = 2000000
        if (purpose === "cashOutRefinance" || occupancy === "investment") {
          maxLtv = 0.5
        }

        let loanAmount = purpose === "purchase" ? pp - dp : co + dp
        let currentLTV = purpose === "purchase" ? (pp - dp) / pp : (co + dp) / pp

        if (loanAmount > maxLoanAmount) {
          setQualified(false)
          setUnqualifiedReason("The program requires a loan amount less than $" + com.commaize(maxLoanAmount) + ".")
          return
        }

        if (currentLTV > maxLtv) {
          setQualified(false)
          setUnqualifiedReason("The maximum loan to value ratio(LTV) of this program is " + maxLtv * 100 + "%")
          return
        }
      }

      setQualified(true)
    } else {
      setQualified(false)
      setUnqualifiedReason("The program is not available in your area.")
    }
  }
  const submitContact = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setLoadingContact(true)
    fetch("/borrower/gmcc/contactme", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Address: propertyAddress,
        State: propertyState,
        City: propertyCity,
        Zip: propertyZip,
        Units: units,
        From: "Universe",
        PropertyValue: purchasePrice,
        Downpayment: downPayment,
        FICO: fico === "" ? "-" : fico,
        Citizenship: citizenship,
        Visa: visa === "" ? "-" : visa,
        HasPastMortgageLate: hasPastMortgageLate,
        BorrowerName: borrowerName,
        BorrowerPhone: borrowerPhone,
        BorrowerEmail: borrowerEmail,
        BorrowerPreferredTimeZone: borrowerPreferredTimeZone,
        BorrowerPreferredTime: borrowerPreferredTime,

        LOID: props.loid,
      })
    })
      .then((res) => {
        if (!res.ok) {
          setLoadingContact(false)
          console.log(res);
          return
        }

        setLoadingContact(false)
        alert("Thanks for your submission. We will contact you shortly.")

      })
      .catch((err) => {
        console.error(err);
        setLoadingContact(false)
        alert("Some internal error happened. Please try again later.")
      });

  }

  const applyUrl = props.loid==="gmcc" ? 
  "https://idp.elliemae.com/authorize?client_id=srtrz0d2&site_id=1796774571&response_type=code&redirect_uri=https%3A%2F%2Fwww.gmccloan.com%2Fborrower-app%2Flogin%2F%3FfromLogin%3Dtrue%26siteId%3D1796774571&scope=ccbp%20cc&instance_id=be11078237&logo_url=https%3A%2F%2Fstore.asset.ellieservices.com%2Fc8e9e1d6-64c4-47f2-8e83-3fb89b5c506f&logo_alt_text=Logo&bust=26&apiBaseUrl=https://api.elliemae.com"
  :  
  props.appLink

  const contactMe = <Row className="mt-5">
 
    {typeof props.basicInfo === "undefined" && <Col></Col>}
    <Col md={6} xs={12} className="text-left">
      <div className="callbackTitle-1 mb-2">Request a call back from your loan officer</div>
      {loEmail !== null &&
        <div className="callback-lo">
          <div className="callbackTitle-3 mb-1"><img src="/images/loanofficer.svg" style={{ width: 30 }} />Your GMCC Loan Officer</div>
          <div className="callback-loinfo">
            {props.loid !== "gmcc" && <div className="callbackTitle-4">{loName}</div>}
            <div className="callbackTitle-4">{loEmail}</div>
            {props.loid !== "gmcc" && <div className="callbackTitle-4">{loPhone !== null ? com.fixPhoneInput(loPhone) : ""}</div>}
            <div className="callbackTitle-4">NMLS #{loNMLS}</div>
          </div>
        </div>}
      <Form onSubmit={submitContact}>
        <Form.Group controlId="name">
          <Form.Label className="callbackTitle-2">Your name</Form.Label>
          <Form.Control type="text" placeholder="Enter your name" onChange={e => setBorrowerName(e.target.value)} required />
        </Form.Group>
        <Form.Group controlId="phone">
          <Form.Label className="callbackTitle-2">Your phone number</Form.Label>
          <Form.Control type="text" placeholder="Enter your phone number" onChange={e => setBorrowerPhone(com.fixPhoneInput(e.target.value))} value={borrowerPhone} required />
        </Form.Group>
        <Form.Group controlId="email">
          <Form.Label className="callbackTitle-2">Your email</Form.Label>
          <Form.Control type="email" placeholder="Enter your email" onChange={e => setBorrowerEmail(e.target.value)} required />
        </Form.Group>
        <div className="d-flex w-100">
          <Form.Group controlId="email" className="w-100 mr-2">
            <Form.Label className="callbackTitle-2">Preferred contact time</Form.Label>
            <Form.Control
              as="select"
              size="sm"
              value={borrowerPreferredTime}
              onChange={(e) => setBorrowerPreferredTime(e.target.value)}
              type="select"
              required
            >
              <option value="">Select your preferred time</option>
              <option value="8am - 9am">8am - 9am</option>
              <option value="9am - 10am">9am - 10am</option>
              <option value="10am - 11am">10am - 11am</option>
              <option value="11am - 12pm">11am - 12pm</option>
              <option value="12pm - 1pm">12pm - 1pm</option>
              <option value="1pm - 2pm">1pm - 2pm</option>
              <option value="2pm - 3pm">2pm - 3pm</option>
              <option value="3pm - 4pm">3pm - 4pm</option>
              <option value="4pm - 5pm">4pm - 5pm</option>
              <option value="5pm - 6pm">5pm - 6pm</option>
              <option value="6pm - 7pm">6pm - 7pm</option>
              <option value="7pm - 8pm">7pm - 8pm</option>
              <option value="8pm - 9pm">8pm - 9pm</option>

            </Form.Control>
          </Form.Group>
          <Form.Group controlId="email" className=" w-100 mr-2">
            <Form.Label className="callbackTitle-2">Time zone</Form.Label>
            <Form.Control
              as="select"
              size="sm"
              value={borrowerPreferredTimeZone}
              onChange={(e) => setBorrowerPreferredTimeZone(e.target.value)}
              type="select"
              required
            >
              <option value="">Select your time zone</option>
              <option value="Eastern">Eastern UTC-5</option>
              <option value="Central">Central UTC-6</option>
              <option value="Mountain">Mountain UTC-7</option>
              <option value="Pacific">Pacific UTC-8</option>
            </Form.Control>
          </Form.Group>
        </div>
        <Button variant="zeitro-primary" type="submit" className="w-100" >
          {loadingContact ? <div className="gmccSpin"><Spin /></div> : "Submit"}
        </Button>
        <DividerWithText text={"Or"} />
        <Button variant="zeitro-primary" className="w-100" onClick={() => window.open(applyUrl, '_blank', 'noopener,noreferrer')}>
          Apply Now
        </Button>
      </Form>
    </Col>
    {typeof props.basicInfo !== "undefined" ?  <Col>
    <div className='request_right  contactMeRequest'>
        <div style={{ position: 'relative' }} className='green'>
          <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
            <circle cx="28" cy="28" r="28" fill="#E3F5E3" />
          </svg>
          <div className='quote'>
            “
          </div>
        </div>
        <div className='Dream'>
          Make a Dream.

        </div>
        <div className='request_info'>
          <div className='line' />

          <div className='touch'>

            <div className='width_line' />
            <div className='touch_text'>We’ll be in touch.Shortly!</div>
          </div>
        </div>
        <div className='information '>

          <div>
          <div style={{ display: 'flex' }}> 
                              <img className='info_img' src={typeof props.basicInfo.file !== "undefined" ? props.basicInfo.file[0].url : avatarSrc}></img>
                            <div className='info_span'>
                                <div>{props.basicInfo.firstname}</div>
                                <div>
                                    {props.basicInfo.lastname}
                                </div>
                            </div>
                        </div>
            <div>{email}</div>

          </div>


          <div >
            <div>NMLS {loNMLS}</div> <div > {phone}</div>
          </div>


        </div>
      </div>
    </Col> : <Col></Col>}

  </Row>

  const showAddressMessage = () => {
    if (unqualifiedReason.includes("not available in your area")) {
      return <div>
        <div>The program is only available in the following areas:</div>
        {
          Object.keys(qualifiedCounties).map((state, i) => {
            return <div key={i}>{state}: {qualifiedCounties[state].join(", ")}</div>
          }
          )
        }
      </div>
    }
  }

  const displayedLoanAmount = () => {
    if (purchasePrice === "" || downPayment === "") return ""
    let dp = com.safeParseInt(downPayment)
    let pp = com.safeParseInt(purchasePrice)

    let loanAmount = purpose === "purchase" ? pp - dp : dp
    if (purpose === "cashOutRefinance") loanAmount += com.safeParseInt(cashOutAmount)

    return <Row className="blueTips bold mb-2">
      <Col>
        <div>Your loan amount is ${com.commaize(loanAmount)}</div>
      </Col>
    </Row>
  }

  const displayFlyer = () => {
    if (props.type === "celebrityNoratio" && language === "english") return <img src="/images/gmccflyers/GMCC_40downFlyer_zeitro.png" className="w-100" />
    if (props.type === "celebrityNoratio" && language === "chinese") return <img src="/images/gmccflyers/GMCC_40downFlyer_zeitroChinese.png" className="w-100" />


    if (language === "english") return <img src="/images/gmccflyers/GMCC_ForeignerFlyer_zeitro.png" className="w-100" />
    if (language === "chinese") return <img src="/images/gmccflyers/GMCC_ForeignerFlyer_zeitroChinese.png" className="w-100" />
  }

  const getQualifiedTitle = () => {
    if (props.type === "celebrityNoratio") return "You may be qualified for this special program!"
    if (props.type === "foreigners") return "You may be qualified for the Foreign nationals program!"
    return "You may be qualified for this special program!"
  }

  return (
    <>
      <Row className="qualifierWrap">
        <Col md={6} className="text-center">
          <div className="d-flex align-items-center mt-3" style={{ justifyContent: "center", marginBottom: "10px" }}>
            <div className="mr-2">Change language to</div>
            <Select defaultValue="english" onChange={handleLanguageChange} options={languageOptions} />
          </div>
          {displayFlyer()}

        </Col>
        <Col md={6} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
          {props.type === "celebrityNoratio" && <>
            <div className="title-3 text-center mt-3 mb-3">GMCC - No Employment Required</div>
            <div className="programHighlights">
              <div className="bold"><img src="/images/bulb.svg" style={{ width: 28 }} /> Program highlights</div>
              <ul>
                <li>No employment verification necessary, credit score optional.</li>
                <li>Loan amount up to $2M</li>
                <li>Currently available for selected counties in WA, CA, TX, GA, NV, MA </li>
              </ul>
            </div>
          </>}
          {props.type === "foreigners" && <>
            <div className="title-3 text-center mt-3 mb-3">GMCC - Foreign Nationals Program</div>
            <div className="programHighlights">
              <div className="bold"><img src="/images/bulb.svg" style={{ width: 28 }} /> Program highlights</div>
              <ul>
                <li>Designed to support non-US citizen borrowers, credit score optional.</li>
                <li>Loan amount up to $2M</li>
                <li>Currently available for selected counties in WA, CA, TX, GA, NV, MA </li>
              </ul>
            </div>
          </>}
          <div className="hfaSubTitle text-center mb-4 ">
            Check your eligibility for special incentive programs with attractive rates and start your homeownership journey!
          </div>


          <div className="qualifierFormWrap">
            <Form onSubmit={handleSubmit} className="qualifierForm">
              <Row>
                <Col md={6} >
                  <AddressAutocomplete
                    id={props.type + "address"}
                    label="Property address"
                    required={true}
                    name="addessline"
                    defaultValue={displayedAddress}
                    onChange={(e) => setDisplayedAddress(e.target.value)}
                    pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                    placeholder="Please type your home address and select from the dropdown addresses"
                    goodfeedback="Looks good!"
                    badfeedback="Please provide your street address."
                    onCompletion={e => onStreetAddressCompletion(e)}
                    size="md"
                  />
                </Col>
                <Col md={6}>
                  <Form.Group controlId="purpose" className="">
                    <Form.Label className="text-left">Number of units</Form.Label>
                    <Form.Control
                      required
                      name="units"
                      as="select"
                      size="md"
                      defaultValue={units}
                      onChange={(e) => setUnits(e.target.value)}
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row className=" ">
                <Col xs={6}>
                  <Form.Label className="text-left">Property state</Form.Label>
                  <Form.Group >
                    <Form.Control
                      required
                      size="sm"
                      as="select"
                      placeholder="State"
                      onChange={(e) => {
                        setPropertyState(e.target.value, 'borrower');
                      }}
                      value={propertyState}
                    >
                      {getStates()}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group >
                    <Form.Label className="text-left">Property county</Form.Label>
                    <Form.Control
                      required
                      size="sm"
                      as="select"
                      onChange={(e) => {
                        setPropertyCounty(e.target.value, 'borrower');
                      }}
                      value={propertyCounty}
                    >
                      {getCounties()}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="purpose" className="">
                    <Form.Label className="text-left">Loan purpose</Form.Label>
                    <Form.Control
                      required
                      name="purpose"
                      as="select"
                      size="md"
                      defaultValue={purpose}
                      onChange={(e) => setPurpose(e.target.value)}
                    >
                      <option value="">...</option>
                      <option value="purchase">Purchase</option>
                      <option value="rateTermRefinance">Rate/Term refinance</option>
                      <option value="cashOutRefinance">Cash out refinance</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="purpose" className="">
                    <Form.Label className="text-left">Occupancy</Form.Label>
                    <Form.Control
                      required
                      name="occupancy"
                      as="select"
                      size="md"
                      defaultValue={occupancy}
                      onChange={(e) => setOccupancy(e.target.value)}
                    >
                      <option value="">...</option>
                      <option value="primary">Primary</option>
                      <option value="secondHome">Second home</option>
                      <option value="investment">Investment(rental)</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={purpose === "cashOutRefinance" ? 4 : 6} xs={12}>
                  <Form.Group>
                    <Form.Label>Property price</Form.Label>
                    <CommaizedInput value={purchasePrice} onChange={e => setPurchasePrice(e.target.value)} withDollarSign />
                  </Form.Group>
                </Col>
                <Col md={purpose === "cashOutRefinance" ? 4 : 6} xs={12}>
                  <Form.Group>
                    <Form.Label>{purpose === "purchase" ? "Down payment" : "Current balance"}</Form.Label>
                    <CommaizedInput className="gmccCalculatorInput" value={downPayment} onChange={e => setDownPayment(e.target.value)} withDollarSign />
                  </Form.Group>
                </Col>
                {purpose === "cashOutRefinance" && <Col md={4} xs={12}>
                  <Form.Group>
                    <Form.Label>Cash out amount</Form.Label>
                    <CommaizedInput className="gmccCalculatorInput" value={cashOutAmount} onChange={e => setCashOutAmount(e.target.value)} withDollarSign />
                  </Form.Group>
                </Col>
                }
              </Row>
              {displayedLoanAmount()}
              <Row>
                <Col md={6}>
                  <Form.Group controlId="citizenship" className="">
                    <Form.Label className="text-left">Mortgage late in the past 12 months?</Form.Label>
                    <Form.Control
                      required
                      name="hasPastMortgageLate"
                      as="select"
                      size="md"
                      defaultValue={hasPastMortgageLate}
                      onChange={(e) => setHasPastMortgageLate(e.target.value)}
                    >
                      <option value="">...</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>FICO (optional)</Form.Label>
                    <Form.Control type="number" placeholder="" onChange={e => setFico(e.target.value)} />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="">
                <Col md={citizenship === "alien" ? 6 : 12} >
                  <Form.Group controlId="citizenship" className="">
                    <Form.Label className="text-left">Citizenship</Form.Label>
                    <Form.Control
                      name="citizenship"
                      as="select"
                      size="md"
                      defaultValue={citizenship}
                      onChange={(e) => setCitizenship(e.target.value)}
                    >
                      <option value="">...</option>
                      {props.type !== "foreigners" && <option value="citizen">US Citizen</option>}
                      <option value="permanent">US Permanent Resident</option>
                      <option value="alien">Non-US Citizen</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={citizenship === "alien" ? 6 : 0} >
                  {citizenship === "alien" && <Form.Group controlId="visa">
                    <Form.Label className="text-left">Visa</Form.Label>
                    <Form.Control
                      name="visa"
                      as="select"
                      size="md"
                      defaultValue={visa}
                      onChange={(e) => setVisa(e.target.value)}
                    >
                      {visas.map((v, i) => {
                        return <option key={i} value={v}>{v}</option>
                      }
                      )}
                    </Form.Control>
                  </Form.Group>}
                </Col>
              </Row>

              <Row className="ml-2">
                <Col className="text-center" >
                  <Button type="submit" variant="zeitro-primary">{loading ? <div className="gmccSpin"><Spin /></div> : "Check my eligibility"}</Button>
                </Col>
              </Row>

            </Form>

            {!loading && showError && <div className="mx-2 mt-3">
              <Alert message="Sorry, we're unable to process the information provided. Please verify your address and select an option from the dropdown list." type="warning" showIcon />
            </div>}
            {!loading && qualified === true && <Result
              status="success"
              title={getQualifiedTitle()}
              subTitle={fico !== "" && com.safeParseInt(fico) < 680 ?
                "Please be aware that if your FICO score is below 680, additional adjustments will apply. Additional restriction required and subject to underwriting approval. Please contact your GMCC agent for more details."
                :
                "Additional restriction required and subject to underwriting approval. Please contact your GMCC agent for more details."
              }
            />}
            {!loading && qualified === false && <Result
              status="warning"
              title="You're not qualified for the program."
              subTitle={<div>
                <div>{unqualifiedReason}</div>
                {showAddressMessage()}
                {props.type !== "foreigners" && <div>You might be qualified for other programs, please contact the loan officer for more information.</div>}
                {props.type === "foreigners" && <div>You might be qualified for other foreign nationals programs, please contact the loan officer for more information.</div>}
              </div>}
            />}
            <Divider />
            <Row className="qualifierDisclaimerWrap">
              <div className="disclaimerContainer">
                <div className="title-6 mb-2">
                  Disclaimer:
                </div>
                <div className="title-6">
                  All loans are subject to credit approval. Interest rates are subject to change daily and without notice. Current interest rates shown our indicative of market conditions and individual qualifications and will vary upon your lock-in period, loan type, credit score, purpose and loan to value.
                </div>
              </div>
            </Row>
          </div>
        </Col>
      </Row>
      <Divider />
      <div className="mb-5">
        {contactMe}
      </div>
    </>
  );
};

export function GMCCBuyWithoutSellQualifier(props) {
  const [propertyAddress, setPropertyAddress] = useState("");
  const [displayedAddress, setDisplayedAddress] = useState("");
  const [propertyState, setPropertyState] = useState("");
  const [propertyCounty, setPropertyCounty] = useState("");
  const [propertyCity, setPropertyCity] = useState("");
  const [propertyZip, setPropertyZip] = useState("");
  const [units, setUnits] = useState("1");
  const [fico, setFico] = useState("");
  const [sufficientEquity, setSufficientEquity] = useState("");
  const [purpose, setPurpose] = useState("purchase");
  const [occupancy, setOccupancy] = useState("");
  const [visa, setVisa] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [downPayment, setDownPayment] = useState("");
  const [cashOutAmount, setCashOutAmount] = useState("");
  const [hasListProperty, setHasListProperty] = useState("");
  const [citizenship, setCitizenship] = useState("");
  const [hasPastMortgageLate, setHasPastMortgageLate] = useState("");


  const [qualified, setQualified] = useState(null)
  const [unqualifiedReason, setUnqualifiedReason] = useState("")

  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [medianIncome, setMedianIncome] = useState(null);

  const [borrowerName, setBorrowerName] = useState("")
  const [borrowerPhone, setBorrowerPhone] = useState("")
  const [borrowerEmail, setBorrowerEmail] = useState("")
  const [borrowerPreferredTimeZone, setBorrowerPreferredTimeZone] = useState("")
  const [borrowerPreferredTime, setBorrowerPreferredTime] = useState("")
  const [loadingContact, setLoadingContact] = useState(false)
  const [language, setLanguage] = useState("english")
  const [loanLimits, setLoanLimits] = useState({})
  const loName = props.loName
  const loEmail = props.loEmail
  const loNMLS = props.loNMLS
  const loPhone = props.loPhone
  let avatarSrc = "/images/avatar.png"
  const email = props?.aboutInfo?.email
  const phone = props?.aboutInfo?.phone

  const handleLanguageChange = (value) => {
    setLanguage(value)
  }

  const fetchLoanLimits = () =>
    fetch("/data/loanlimits")
      .then((res) => {
        if (!res.ok) {
          console.log(res);
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        setLoanLimits(res)
      })
      .catch((err) => {
        console.error(err);
      });

  useEffect(() => {
    fetchLoanLimits()
  }, [])

  const getStates = () => {
    let options = [];
    let keys = Object.keys(loanLimits);
    options.push(<option value=""></option>);
    keys.forEach((x) => {
      options.push(<option value={com.capitalize(x)}> {com.capitalize(x)}</option>);
    });
    return options;
  };

  const getCounties = () => {
    let options = [];
    options.push(<option value=""></option>);
    try {
      if (loanLimits[propertyState.toUpperCase()]) {
        let keys = Object.keys(loanLimits[propertyState.toUpperCase()]);
        keys.forEach((x) => {
          options.push(<option value={com.capitalize(x)}> {com.capitalize(x)}</option>);
        });
      }
    } catch (error) {
      console.log(error)
    }

    return options;
  };

  const languageOptions = [
    { value: "english", label: "English" },
    { value: "chinese", label: "Chinese" },
  ]

  const qualifiedCounties = {
    "California": ["San Francisco", "Alameda", "San Mateo", "Santa Clara", "Sacramento", "Contra Costa", "Los Angeles", "Orange", "Riverside", "San Bernardino", "San Diego"],
    "Georgia": ["Barrow", "Bartow", "Cherokee", "Clayton", "Cobb", "DeKalb", "Douglas", "Fayette", "Forsyth", "Fulton", "Gwinnett", "Henry", "Paulding", "Rockdale", "Spalding"],
    "Massachusetts": ["Middlesex", "Norfolk", "Suffolk"],
    "Nevada": ["Clark"],
    "Texas": ["Collin", "Dallas", "Fort Bend", "Harris"],
    "Washington": ["King", "Pierce", "Snohomish"],
    "New York": ["Bronx", "Kings (Brooklyn)", "New York (Manhattan)", "Queens", "Richmond (Staten Island)"]
  }

  const visas = [
    st.VT_E_1,
    st.VT_E_2,
    st.VT_E_3,
    st.VT_F_1,
    st.VT_G_1,
    st.VT_G_2,
    st.VT_G_3,
    st.VT_G_4,
    st.VT_H_1B,
    st.VT_H_4,
    st.VT_L_1,
    st.VT_L_2,
    st.VT_O_1,
    st.VT_O_2,
    st.VT_O_3,
    st.VT_P_1,
    st.VT_P_2,
    st.VT_P_3,
    st.VT_P_4,
    st.VT_TN,
  ];

  const onStreetAddressCompletion = (address) => {
    let num = com.getSubaddress(address, "street_number", "long_name");

    let city = com.getSubaddress(address, "locality", "long_name");
    setPropertyCity(city)
    let state = com.getSubaddress(
      address,
      "administrative_area_level_1",
      "short_name"
    );

    let zip = com.getSubaddress(address, "postal_code", "long_name");
    setPropertyZip(zip)

    let county = com.getSubaddress(
      address,
      "administrative_area_level_2",
      "long_name"
    );
    setPropertyCounty(county)
    let longstate = com.getSubaddress(
      address,
      "administrative_area_level_1",
      "long_name"
    );
    console.log(longstate)
    setPropertyState(longstate)

    let short = com.getSubaddress(address, "route", "long_name");
    setPropertyAddress(num + " " + short)
    let stradd = num + " " + short + ", " + city + ", " + state + " " + zip;
    setDisplayedAddress(stradd)
  };


  const handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setShowError(false)
    setQualified(null)
    setUnqualifiedReason("")
    setMedianIncome(null)

    if (hasListProperty === "no"){
      setQualified(false)
      setUnqualifiedReason("The program requires you to have a property listed/intended for sale.")
      return
    }
    if (sufficientEquity === "no"){
      setQualified(false)
      setUnqualifiedReason("The program requires you to have at least 20% of equity of your departuring property.")
      return
    }
    if (fico !== "" && com.safeParseInt(fico) < 660) {
      setQualified(false)
      setUnqualifiedReason("The program requires a minimum FICO score of 660.")
      return
    }
    if (downPayment !== "" && purchasePrice !== "") {
      let dp = com.safeParseInt(downPayment)
      let pp = com.safeParseInt(purchasePrice)
      let co = com.safeParseInt(cashOutAmount)

      let maxLtv = 0.8
      let maxLoanAmount = 3500000
      let loanAmount = purpose === "purchase" ? pp - dp : co + dp
      let currentLTV = purpose === "purchase" ? (pp - dp) / pp : (co + dp) / pp

      if (loanAmount > maxLoanAmount || loanAmount < 150000) {
        setQualified(false)
        setUnqualifiedReason("The program requires a loan amount from $150,000 to $3.5M.")
        return
      }
      if (currentLTV > maxLtv) {
        setQualified(false)
        setUnqualifiedReason("The maximum loan to value ratio(LTV) of this program is " + maxLtv * 100 + "%")
        return
      }
      setQualified(true)
    }
  }
  const submitContact = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setLoadingContact(true)
    fetch("/borrower/gmcc/contactme", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Address: propertyAddress,
        State: propertyState,
        City: propertyCity,
        Zip: propertyZip,
        Units: units,
        From: "Universe",
        PropertyValue: purchasePrice,
        Downpayment: downPayment,
        FICO: fico === "" ? "-" : fico,
        Citizenship: citizenship,
        Visa: visa === "" ? "-" : visa,
        HasPastMortgageLate: hasPastMortgageLate,
        BorrowerName: borrowerName,
        BorrowerPhone: borrowerPhone,
        BorrowerEmail: borrowerEmail,
        BorrowerPreferredTimeZone: borrowerPreferredTimeZone,
        BorrowerPreferredTime: borrowerPreferredTime,

        LOID: props.loid,
      })
    })
      .then((res) => {
        if (!res.ok) {
          setLoadingContact(false)
          console.log(res);
          return
        }

        setLoadingContact(false)
        alert("Thanks for your submission. We will contact you shortly.")

      })
      .catch((err) => {
        console.error(err);
        setLoadingContact(false)
        alert("Some internal error happened. Please try again later.")
      });

  }

  const applyUrl = props.loid==="gmcc" ? 
  "https://idp.elliemae.com/authorize?client_id=srtrz0d2&site_id=1796774571&response_type=code&redirect_uri=https%3A%2F%2Fwww.gmccloan.com%2Fborrower-app%2Flogin%2F%3FfromLogin%3Dtrue%26siteId%3D1796774571&scope=ccbp%20cc&instance_id=be11078237&logo_url=https%3A%2F%2Fstore.asset.ellieservices.com%2Fc8e9e1d6-64c4-47f2-8e83-3fb89b5c506f&logo_alt_text=Logo&bust=26&apiBaseUrl=https://api.elliemae.com"
  :  
  props.appLink

  const contactMe = <Row className="mt-5">
 
    {typeof props.basicInfo === "undefined" && <Col></Col>}
    <Col md={6} xs={12} className="text-left">
      <div className="callbackTitle-1 mb-2">Request a call back from your loan officer</div>
      {loEmail !== null &&
        <div className="callback-lo">
          <div className="callbackTitle-3 mb-1"><img src="/images/loanofficer.svg" style={{ width: 30 }} />Your GMCC Loan Officer</div>
          <div className="callback-loinfo">
            {props.loid !== "gmcc" && <div className="callbackTitle-4">{loName}</div>}
            <div className="callbackTitle-4">{loEmail}</div>
            {props.loid !== "gmcc" && <div className="callbackTitle-4">{loPhone !== null ? com.fixPhoneInput(loPhone) : ""}</div>}
            <div className="callbackTitle-4">NMLS #{loNMLS}</div>
          </div>
        </div>}
      <Form onSubmit={submitContact}>
        <Form.Group controlId="name">
          <Form.Label className="callbackTitle-2">Your name</Form.Label>
          <Form.Control type="text" placeholder="Enter your name" onChange={e => setBorrowerName(e.target.value)} required />
        </Form.Group>
        <Form.Group controlId="phone">
          <Form.Label className="callbackTitle-2">Your phone number</Form.Label>
          <Form.Control type="text" placeholder="Enter your phone number" onChange={e => setBorrowerPhone(com.fixPhoneInput(e.target.value))} value={borrowerPhone} required />
        </Form.Group>
        <Form.Group controlId="email">
          <Form.Label className="callbackTitle-2">Your email</Form.Label>
          <Form.Control type="email" placeholder="Enter your email" onChange={e => setBorrowerEmail(e.target.value)} required />
        </Form.Group>
        <div className="d-flex w-100">
          <Form.Group controlId="email" className="w-100 mr-2">
            <Form.Label className="callbackTitle-2">Preferred contact time</Form.Label>
            <Form.Control
              as="select"
              size="sm"
              value={borrowerPreferredTime}
              onChange={(e) => setBorrowerPreferredTime(e.target.value)}
              type="select"
              required
            >
              <option value="">Select your preferred time</option>
              <option value="8am - 9am">8am - 9am</option>
              <option value="9am - 10am">9am - 10am</option>
              <option value="10am - 11am">10am - 11am</option>
              <option value="11am - 12pm">11am - 12pm</option>
              <option value="12pm - 1pm">12pm - 1pm</option>
              <option value="1pm - 2pm">1pm - 2pm</option>
              <option value="2pm - 3pm">2pm - 3pm</option>
              <option value="3pm - 4pm">3pm - 4pm</option>
              <option value="4pm - 5pm">4pm - 5pm</option>
              <option value="5pm - 6pm">5pm - 6pm</option>
              <option value="6pm - 7pm">6pm - 7pm</option>
              <option value="7pm - 8pm">7pm - 8pm</option>
              <option value="8pm - 9pm">8pm - 9pm</option>

            </Form.Control>
          </Form.Group>
          <Form.Group controlId="email" className=" w-100 mr-2">
            <Form.Label className="callbackTitle-2">Time zone</Form.Label>
            <Form.Control
              as="select"
              size="sm"
              value={borrowerPreferredTimeZone}
              onChange={(e) => setBorrowerPreferredTimeZone(e.target.value)}
              type="select"
              required
            >
              <option value="">Select your time zone</option>
              <option value="Eastern">Eastern UTC-5</option>
              <option value="Central">Central UTC-6</option>
              <option value="Mountain">Mountain UTC-7</option>
              <option value="Pacific">Pacific UTC-8</option>
            </Form.Control>
          </Form.Group>
        </div>
        <Button variant="zeitro-primary" type="submit" className="w-100" >
          {loadingContact ? <div className="gmccSpin"><Spin /></div> : "Submit"}
        </Button>
        <DividerWithText text={"Or"} />
        <Button variant="zeitro-primary" className="w-100" onClick={() => window.open(applyUrl, '_blank', 'noopener,noreferrer')}>
          Apply Now
        </Button>
      </Form>
    </Col>
    {typeof props.basicInfo !== "undefined" ?  <Col>
    <div className='request_right  contactMeRequest'>
        <div style={{ position: 'relative' }} className='green'>
          <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
            <circle cx="28" cy="28" r="28" fill="#E3F5E3" />
          </svg>
          <div className='quote'>
            “
          </div>
        </div>
        <div className='Dream'>
          Make a Dream.

        </div>
        <div className='request_info'>
          <div className='line' />

          <div className='touch'>

            <div className='width_line' />
            <div className='touch_text'>We’ll be in touch.Shortly!</div>
          </div>
        </div>
        <div className='information '>

          <div>
          <div style={{ display: 'flex' }}> 
                              <img className='info_img' src={typeof props.basicInfo.file !== "undefined" ? props.basicInfo.file[0].url : avatarSrc}></img>
                            <div className='info_span'>
                                <div>{props.basicInfo.firstname}</div>
                                <div>
                                    {props.basicInfo.lastname}
                                </div>
                            </div>
                        </div>
            <div>{email}</div>

          </div>


          <div >
            <div>NMLS {loNMLS}</div> <div > {phone}</div>
          </div>


        </div>
      </div>
    </Col> : <Col></Col>}

  </Row>

  const showAddressMessage = () => {
    if (unqualifiedReason.includes("not available in your area")) {
      return <div>
        <div>The program is only available in the following areas:</div>
        {
          Object.keys(qualifiedCounties).map((state, i) => {
            return <div key={i}>{state}: {qualifiedCounties[state].join(", ")}</div>
          }
          )
        }
      </div>
    }
  }

  const displayedLoanAmount = () => {
    if (purchasePrice === "" || downPayment === "") return ""
    let dp = com.safeParseInt(downPayment)
    let pp = com.safeParseInt(purchasePrice)

    let loanAmount = purpose === "purchase" ? pp - dp : dp
    if (purpose === "cashOutRefinance") loanAmount += com.safeParseInt(cashOutAmount)

    return <Row className="blueTips bold mb-2">
      <Col>
        <div>Your loan amount is ${com.commaize(loanAmount)}</div>
      </Col>
    </Row>
  }

  const displayFlyer = () => {

    if (language === "english") return <img src="/images/gmccflyers/GMCC_BuyWithoutSellFlyer_zeitro.png" className="w-100" />
    if (language === "chinese") return <img src="/images/gmccflyers/GMCC_BuyWithoutSellFlyer_zeitro.png" className="w-100" />
  }

  const getQualifiedTitle = () => {
    return "You may be qualified for the Buy Without Sell First program!"
  }

  return (
    <>
      <Row className="qualifierWrap">
        <Col md={6} className="text-center">
          <div className="d-flex align-items-center mt-3" style={{ justifyContent: "center", marginBottom: "10px" }}>
            <div className="mr-2">Change language to</div>
            <Select defaultValue="english" onChange={handleLanguageChange} options={languageOptions} />
          </div>
          {displayFlyer()}

        </Col>
        <Col md={6} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
          <>
            <div className="title-3 text-center mt-3 mb-3">GMCC - Buy Without Sell First Program</div>
            <div className="programHighlights">
              <div className="bold"><img src="/images/bulb.svg" style={{ width: 28 }} /> Program highlights</div>
              <ul>
                <li>Need to own at least 20% equity of the departuring property.</li>
                <li>Down payment at least 20%, DTI up to 55%</li>
                <li>Loan amount up from $150K to $3.5M</li>
                <li>Available in all 49 states where GMCC is licensed </li>
              </ul>
            </div>
          </>
          <div className="hfaSubTitle text-center mb-4 ">
            Check your eligibility for special incentive programs with attractive rates and start your homeownership journey!
          </div>


          <div className="qualifierFormWrap">
            <Form onSubmit={handleSubmit} className="qualifierForm">
              <Row>
                <Col md={12} >
                  <AddressAutocomplete
                    id={"buywithoutsell" + "address"}
                    label="Property address"
                    required={true}
                    name="addessline"
                    defaultValue={displayedAddress}
                    onChange={(e) => setDisplayedAddress(e.target.value)}
                    pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                    placeholder="Please type your home address and select from the dropdown addresses"
                    goodfeedback="Looks good!"
                    badfeedback="Please provide your street address."
                    onCompletion={e => onStreetAddressCompletion(e)}
                    size="md"
                  />
                </Col>
              </Row>
              <Row className=" ">
                <Col xs={6}>
                  <Form.Label className="text-left">Property state</Form.Label>
                  <Form.Group >
                    <Form.Control
                      required
                      size="sm"
                      as="select"
                      placeholder="State"
                      onChange={(e) => {
                        setPropertyState(e.target.value, 'borrower');
                      }}
                      value={propertyState}
                    >
                      {getStates()}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group >
                    <Form.Label className="text-left">Property county</Form.Label>
                    <Form.Control
                      required
                      size="sm"
                      as="select"
                      onChange={(e) => {
                        setPropertyCounty(e.target.value, 'borrower');
                      }}
                      value={propertyCounty}
                    >
                      {getCounties()}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              {/* <Row>
                <Col>
                  <Form.Group controlId="purpose" className="">
                    <Form.Label className="text-left">Loan purpose</Form.Label>
                    <Form.Control
                      required
                      name="purpose"
                      as="select"
                      size="md"
                      defaultValue={purpose}
                      onChange={(e) => setPurpose(e.target.value)}
                    >
                      <option value="">...</option>
                      <option value="purchase">Purchase</option>
                      <option value="rateTermRefinance">Rate/Term refinance</option>
                      <option value="cashOutRefinance">Cash out refinance</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="purpose" className="">
                    <Form.Label className="text-left">Occupancy</Form.Label>
                    <Form.Control
                      required
                      name="occupancy"
                      as="select"
                      size="md"
                      defaultValue={occupancy}
                      onChange={(e) => setOccupancy(e.target.value)}
                    >
                      <option value="">...</option>
                      <option value="primary">Primary</option>
                      <option value="secondHome">Second home</option>
                      <option value="investment">Investment(rental)</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row> */}
              <Row>
                <Col md={purpose === "cashOutRefinance" ? 4 : 6} xs={12}>
                  <Form.Group>
                    <Form.Label>Property price</Form.Label>
                    <CommaizedInput value={purchasePrice} onChange={e => setPurchasePrice(e.target.value)} withDollarSign />
                  </Form.Group>
                </Col>
                <Col md={purpose === "cashOutRefinance" ? 4 : 6} xs={12}>
                  <Form.Group>
                    <Form.Label>{purpose === "purchase" ? "Down payment" : "Current balance"}</Form.Label>
                    <CommaizedInput className="gmccCalculatorInput" value={downPayment} onChange={e => setDownPayment(e.target.value)} withDollarSign />
                  </Form.Group>
                </Col>
                {purpose === "cashOutRefinance" && <Col md={4} xs={12}>
                  <Form.Group>
                    <Form.Label>Cash out amount</Form.Label>
                    <CommaizedInput className="gmccCalculatorInput" value={cashOutAmount} onChange={e => setCashOutAmount(e.target.value)} withDollarSign />
                  </Form.Group>
                </Col>
                }
              </Row>
              {displayedLoanAmount()}
              <Row>
                <Col md={6}>
                  <Form.Group controlId="citizenship" className="">
                    <Form.Label className="text-left">Have property currently listing/intend to sell?</Form.Label>
                    <Form.Control
                      required
                      name="hasPastMortgageLate"
                      as="select"
                      size="md"
                      defaultValue={hasListProperty}
                      onChange={(e) => setHasListProperty(e.target.value)}
                    >
                      <option value="">...</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>FICO</Form.Label>
                    <Form.Control required type="number" placeholder="" onChange={e => setFico(e.target.value)} />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="">
                <Col md={12} >
                  <Form.Group controlId="citizenship" className="">
                    <Form.Label className="text-left">Own 20% or more equity of your selling property?</Form.Label>
                    <Form.Control
                      required
                      name="citizenship"
                      as="select"
                      size="md"
                      defaultValue={sufficientEquity}
                      onChange={(e) => setSufficientEquity(e.target.value)}
                    >
                      <option value="">...</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="ml-2">
                <Col className="text-center" >
                  <Button type="submit" variant="zeitro-primary">{loading ? <div className="gmccSpin"><Spin /></div> : "Check my eligibility"}</Button>
                </Col>
              </Row>

            </Form>

            {!loading && showError && <div className="mx-2 mt-3">
              <Alert message="Sorry, we're unable to process the information provided. Please verify your address and select an option from the dropdown list." type="warning" showIcon />
            </div>}
            {!loading && qualified === true && <Result
              status="success"
              title={getQualifiedTitle()}
              subTitle={
                "Additional restriction required and subject to underwriting approval. Please contact your GMCC agent for more details."
              }
            />}
            {!loading && qualified === false && <Result
              status="warning"
              title="You're not qualified for this program."
              subTitle={<div>
                <div>{unqualifiedReason}</div>
                {showAddressMessage()}
                <div>You might still be able to qualify for this program with exception, please contact the GMCC loan officer for more information.</div>
              </div>}
            />}
            <Divider />
            <Row className="qualifierDisclaimerWrap">
              <div className="disclaimerContainer">
                <div className="title-6 mb-2">
                  Disclaimer:
                </div>
                <div className="title-6">
                  All loans are subject to credit approval. Interest rates are subject to change daily and without notice. Current interest rates shown our indicative of market conditions and individual qualifications and will vary upon your lock-in period, loan type, credit score, purpose and loan to value.
                </div>
              </div>
            </Row>
          </div>
        </Col>
      </Row>
      <Divider />
      <div className="mb-5">
        {contactMe}
      </div>
    </>
  );
};

export function GMCCDSCRQualifier(props) {
  const [propertyAddress, setPropertyAddress] = useState("");
  const [displayedAddress, setDisplayedAddress] = useState("");
  const [propertyState, setPropertyState] = useState("");
  const [propertyCounty, setPropertyCounty] = useState("");
  const [propertyCity, setPropertyCity] = useState("");
  const [propertyZip, setPropertyZip] = useState("");
  const [units, setUnits] = useState("1");
  const [fico, setFico] = useState("");
  const [sufficientEquity, setSufficientEquity] = useState("");
  const [purpose, setPurpose] = useState("purchase");
  const [occupancy, setOccupancy] = useState("");
  const [visa, setVisa] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [downPayment, setDownPayment] = useState("");
  const [cashOutAmount, setCashOutAmount] = useState("");
  const [hasListProperty, setHasListProperty] = useState("");
  const [citizenship, setCitizenship] = useState("");
  const [hasPastMortgageLate, setHasPastMortgageLate] = useState("");


  const [qualified, setQualified] = useState(null)
  const [unqualifiedReason, setUnqualifiedReason] = useState("")

  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [medianIncome, setMedianIncome] = useState(null);

  const [borrowerName, setBorrowerName] = useState("")
  const [borrowerPhone, setBorrowerPhone] = useState("")
  const [borrowerEmail, setBorrowerEmail] = useState("")
  const [borrowerPreferredTimeZone, setBorrowerPreferredTimeZone] = useState("")
  const [borrowerPreferredTime, setBorrowerPreferredTime] = useState("")
  const [loadingContact, setLoadingContact] = useState(false)
  const [language, setLanguage] = useState("english")
  const [loanLimits, setLoanLimits] = useState({})
  const [firstTimeHB, setFirstTimeHB] = useState(false)
  const [rentalIncome, setRentalIncome] = useState("")

  const loName = props.loName
  const loEmail = props.loEmail
  const loNMLS = props.loNMLS
  const loPhone = props.loPhone
  let avatarSrc = "/images/avatar.png"
  const email = props?.aboutInfo?.email
  const phone = props?.aboutInfo?.phone

  const handleLanguageChange = (value) => {
    setLanguage(value)
  }

  const fetchLoanLimits = () =>
    fetch("/data/loanlimits")
      .then((res) => {
        if (!res.ok) {
          console.log(res);
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        setLoanLimits(res)
      })
      .catch((err) => {
        console.error(err);
      });

  useEffect(() => {
    fetchLoanLimits()
  }, [])

  const getStates = () => {
    let options = [];
    let keys = Object.keys(loanLimits);
    options.push(<option value=""></option>);
    keys.forEach((x) => {
      options.push(<option value={com.capitalize(x)}> {com.capitalize(x)}</option>);
    });
    return options;
  };

  const getCounties = () => {
    let options = [];
    options.push(<option value=""></option>);
    try {
      if (loanLimits[propertyState.toUpperCase()]) {
        let keys = Object.keys(loanLimits[propertyState.toUpperCase()]);
        keys.forEach((x) => {
          options.push(<option value={com.capitalize(x)}> {com.capitalize(x)}</option>);
        });
      }
    } catch (error) {
      console.log(error)
    }

    return options;
  };

  const languageOptions = [
    { value: "english", label: "English" },
    { value: "chinese", label: "Chinese" },
  ]

  const qualifiedCounties = {
    "California": ["San Francisco", "Alameda", "San Mateo", "Santa Clara", "Sacramento", "Contra Costa", "Los Angeles", "Orange", "Riverside", "San Bernardino", "San Diego"],
    "Georgia": ["Barrow", "Bartow", "Cherokee", "Clayton", "Cobb", "DeKalb", "Douglas", "Fayette", "Forsyth", "Fulton", "Gwinnett", "Henry", "Paulding", "Rockdale", "Spalding"],
    "Massachusetts": ["Middlesex", "Norfolk", "Suffolk"],
    "Nevada": ["Clark"],
    "Texas": ["Collin", "Dallas", "Fort Bend", "Harris"],
    "Washington": ["King", "Pierce", "Snohomish"],
    "New York": ["Bronx", "Kings (Brooklyn)", "New York (Manhattan)", "Queens", "Richmond (Staten Island)"]
  }

  const visas = [
    st.VT_E_1,
    st.VT_E_2,
    st.VT_E_3,
    st.VT_F_1,
    st.VT_G_1,
    st.VT_G_2,
    st.VT_G_3,
    st.VT_G_4,
    st.VT_H_1B,
    st.VT_H_4,
    st.VT_L_1,
    st.VT_L_2,
    st.VT_O_1,
    st.VT_O_2,
    st.VT_O_3,
    st.VT_P_1,
    st.VT_P_2,
    st.VT_P_3,
    st.VT_P_4,
    st.VT_TN,
  ];

  const onStreetAddressCompletion = (address) => {
    let num = com.getSubaddress(address, "street_number", "long_name");

    let city = com.getSubaddress(address, "locality", "long_name");
    setPropertyCity(city)
    let state = com.getSubaddress(
      address,
      "administrative_area_level_1",
      "short_name"
    );

    let zip = com.getSubaddress(address, "postal_code", "long_name");
    setPropertyZip(zip)

    let county = com.getSubaddress(
      address,
      "administrative_area_level_2",
      "long_name"
    );
    setPropertyCounty(county)
    let longstate = com.getSubaddress(
      address,
      "administrative_area_level_1",
      "long_name"
    );
    console.log(longstate)
    setPropertyState(longstate)

    let short = com.getSubaddress(address, "route", "long_name");
    setPropertyAddress(num + " " + short)
    let stradd = num + " " + short + ", " + city + ", " + state + " " + zip;
    setDisplayedAddress(stradd)
  };


  const handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setShowError(false)
    setQualified(null)
    setUnqualifiedReason("")
    setMedianIncome(null)

    if (fico !== "" && com.safeParseInt(fico) < 660) {
      setQualified(false)
      setUnqualifiedReason("The program requires a minimum FICO score of 660.")
      return
    }
    if (downPayment !== "" && purchasePrice !== "") {
      let dp = com.safeParseInt(downPayment)
      let pp = com.safeParseInt(purchasePrice)
      let co = com.safeParseInt(cashOutAmount)

      let maxLtv = 0.8
      let maxLoanAmount = 3000000
      let loanAmount = purpose === "purchase" ? pp - dp : co + dp
      let currentLTV = purpose === "purchase" ? (pp - dp) / pp : (co + dp) / pp
      let minDSCR = 0.75
      let monthpay = loanAmount * (0.07/12)*Math.pow(1+0.07/12,360)/(Math.pow(1+0.07/12,360)-1)
      let monthtax = pp * 0.0125 / 12
      let monthpmi = loanAmount * 0.005 / 12
      let totalMonthPay = monthpay + monthtax + monthpmi
      let dscr = 0

      if (loanAmount > maxLoanAmount || loanAmount < 150000) {
        setQualified(false)
        setUnqualifiedReason("The program requires a loan amount from $100,000 to $3M.")
        return
      }
      if (currentLTV > maxLtv) {
        setQualified(false)
        setUnqualifiedReason("The maximum loan to value ratio(LTV) of this program is " + maxLtv * 100 + "%")
        return
      }
      if (rentalIncome !== "") {
        dscr = com.safeParseInt(rentalIncome)/totalMonthPay
        if (dscr < minDSCR){
          setQualified(false)
          setUnqualifiedReason("The minimum DSCR ratio of this program is " + minDSCR)
          return
        }
      } else{
        if (currentLTV >0.65){
          setQualified(false)
          setUnqualifiedReason("The maximum LTV ratio of No ratio DSCR is 65%")
          return
        }
      }
      if (firstTimeHB){
        if ((dscr <1 || currentLTV > 0.7) || (dscr <1 || fico <700 )){
          setQualified(false)
          setUnqualifiedReason("First time home buyer needs to have DSCR ratio > 1 with LTV < 70% or FICO > 700")
          return
        }
      }
      
      setQualified(true)
    }
  }
  const submitContact = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setLoadingContact(true)
    fetch("/borrower/gmcc/contactme", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Address: propertyAddress,
        State: propertyState,
        City: propertyCity,
        Zip: propertyZip,
        Units: units,
        From: "Universe",
        PropertyValue: purchasePrice,
        Downpayment: downPayment,
        FICO: fico === "" ? "-" : fico,
        Citizenship: citizenship,
        Visa: visa === "" ? "-" : visa,
        HasPastMortgageLate: hasPastMortgageLate,
        BorrowerName: borrowerName,
        BorrowerPhone: borrowerPhone,
        BorrowerEmail: borrowerEmail,
        BorrowerPreferredTimeZone: borrowerPreferredTimeZone,
        BorrowerPreferredTime: borrowerPreferredTime,

        LOID: props.loid,
      })
    })
      .then((res) => {
        if (!res.ok) {
          setLoadingContact(false)
          console.log(res);
          return
        }

        setLoadingContact(false)
        alert("Thanks for your submission. We will contact you shortly.")

      })
      .catch((err) => {
        console.error(err);
        setLoadingContact(false)
        alert("Some internal error happened. Please try again later.")
      });

  }

  const applyUrl = props.loid==="gmcc" ? 
  "https://idp.elliemae.com/authorize?client_id=srtrz0d2&site_id=1796774571&response_type=code&redirect_uri=https%3A%2F%2Fwww.gmccloan.com%2Fborrower-app%2Flogin%2F%3FfromLogin%3Dtrue%26siteId%3D1796774571&scope=ccbp%20cc&instance_id=be11078237&logo_url=https%3A%2F%2Fstore.asset.ellieservices.com%2Fc8e9e1d6-64c4-47f2-8e83-3fb89b5c506f&logo_alt_text=Logo&bust=26&apiBaseUrl=https://api.elliemae.com"
  :  
  props.appLink

  const contactMe = <Row className="mt-5">
 
    {typeof props.basicInfo === "undefined" && <Col></Col>}
    <Col md={6} xs={12} className="text-left">
      <div className="callbackTitle-1 mb-2">Request a call back from your loan officer</div>
      {loEmail !== null &&
        <div className="callback-lo">
          <div className="callbackTitle-3 mb-1"><img src="/images/loanofficer.svg" style={{ width: 30 }} />Your GMCC Loan Officer</div>
          <div className="callback-loinfo">
            {props.loid !== "gmcc" && <div className="callbackTitle-4">{loName}</div>}
            <div className="callbackTitle-4">{loEmail}</div>
            {props.loid !== "gmcc" && <div className="callbackTitle-4">{loPhone !== null ? com.fixPhoneInput(loPhone) : ""}</div>}
            <div className="callbackTitle-4">NMLS #{loNMLS}</div>
          </div>
        </div>}
      <Form onSubmit={submitContact}>
        <Form.Group controlId="name">
          <Form.Label className="callbackTitle-2">Your name</Form.Label>
          <Form.Control type="text" placeholder="Enter your name" onChange={e => setBorrowerName(e.target.value)} required />
        </Form.Group>
        <Form.Group controlId="phone">
          <Form.Label className="callbackTitle-2">Your phone number</Form.Label>
          <Form.Control type="text" placeholder="Enter your phone number" onChange={e => setBorrowerPhone(com.fixPhoneInput(e.target.value))} value={borrowerPhone} required />
        </Form.Group>
        <Form.Group controlId="email">
          <Form.Label className="callbackTitle-2">Your email</Form.Label>
          <Form.Control type="email" placeholder="Enter your email" onChange={e => setBorrowerEmail(e.target.value)} required />
        </Form.Group>
        <div className="d-flex w-100">
          <Form.Group controlId="email" className="w-100 mr-2">
            <Form.Label className="callbackTitle-2">Preferred contact time</Form.Label>
            <Form.Control
              as="select"
              size="sm"
              value={borrowerPreferredTime}
              onChange={(e) => setBorrowerPreferredTime(e.target.value)}
              type="select"
              required
            >
              <option value="">Select your preferred time</option>
              <option value="8am - 9am">8am - 9am</option>
              <option value="9am - 10am">9am - 10am</option>
              <option value="10am - 11am">10am - 11am</option>
              <option value="11am - 12pm">11am - 12pm</option>
              <option value="12pm - 1pm">12pm - 1pm</option>
              <option value="1pm - 2pm">1pm - 2pm</option>
              <option value="2pm - 3pm">2pm - 3pm</option>
              <option value="3pm - 4pm">3pm - 4pm</option>
              <option value="4pm - 5pm">4pm - 5pm</option>
              <option value="5pm - 6pm">5pm - 6pm</option>
              <option value="6pm - 7pm">6pm - 7pm</option>
              <option value="7pm - 8pm">7pm - 8pm</option>
              <option value="8pm - 9pm">8pm - 9pm</option>

            </Form.Control>
          </Form.Group>
          <Form.Group controlId="email" className=" w-100 mr-2">
            <Form.Label className="callbackTitle-2">Time zone</Form.Label>
            <Form.Control
              as="select"
              size="sm"
              value={borrowerPreferredTimeZone}
              onChange={(e) => setBorrowerPreferredTimeZone(e.target.value)}
              type="select"
              required
            >
              <option value="">Select your time zone</option>
              <option value="Eastern">Eastern UTC-5</option>
              <option value="Central">Central UTC-6</option>
              <option value="Mountain">Mountain UTC-7</option>
              <option value="Pacific">Pacific UTC-8</option>
            </Form.Control>
          </Form.Group>
        </div>
        <Button variant="zeitro-primary" type="submit" className="w-100" >
          {loadingContact ? <div className="gmccSpin"><Spin /></div> : "Submit"}
        </Button>
        <DividerWithText text={"Or"} />
        <Button variant="zeitro-primary" className="w-100" onClick={() => window.open(applyUrl, '_blank', 'noopener,noreferrer')}>
          Apply Now
        </Button>
      </Form>
    </Col>
    {typeof props.basicInfo !== "undefined" ?  <Col>
    <div className='request_right  contactMeRequest'>
        <div style={{ position: 'relative' }} className='green'>
          <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
            <circle cx="28" cy="28" r="28" fill="#E3F5E3" />
          </svg>
          <div className='quote'>
            “
          </div>
        </div>
        <div className='Dream'>
          Make a Dream.

        </div>
        <div className='request_info'>
          <div className='line' />

          <div className='touch'>

            <div className='width_line' />
            <div className='touch_text'>We’ll be in touch.Shortly!</div>
          </div>
        </div>
        <div className='information '>

          <div>
          <div style={{ display: 'flex' }}> 
                              <img className='info_img' src={typeof props.basicInfo.file !== "undefined" ? props.basicInfo.file[0].url : avatarSrc}></img>
                            <div className='info_span'>
                                <div>{props.basicInfo.firstname}</div>
                                <div>
                                    {props.basicInfo.lastname}
                                </div>
                            </div>
                        </div>
            <div>{email}</div>

          </div>


          <div >
            <div>NMLS {loNMLS}</div> <div > {phone}</div>
          </div>


        </div>
      </div>
    </Col> : <Col></Col>}

  </Row>

  const showAddressMessage = () => {
    if (unqualifiedReason.includes("not available in your area")) {
      return <div>
        <div>The program is only available in the following areas:</div>
        {
          Object.keys(qualifiedCounties).map((state, i) => {
            return <div key={i}>{state}: {qualifiedCounties[state].join(", ")}</div>
          }
          )
        }
      </div>
    }
  }

  const displayedLoanAmount = () => {
    if (purchasePrice === "" || downPayment === "") return ""
    let dp = com.safeParseInt(downPayment)
    let pp = com.safeParseInt(purchasePrice)

    let loanAmount = purpose === "purchase" ? pp - dp : dp
    if (purpose === "cashOutRefinance") loanAmount += com.safeParseInt(cashOutAmount)

    return <Row className="blueTips bold mb-2">
      <Col>
        <div>Your loan amount is ${com.commaize(loanAmount)}</div>
      </Col>
    </Row>
  }

  const displayFlyer = () => {

    if (language === "english") return <img src="/images/gmccflyers/GMCC_DSCRFlyer_zeitro.png" className="w-100" />
    if (language === "chinese") return <img src="/images/gmccflyers/GMCC_DSCRFlyer_zeitro.png" className="w-100" />
  }

  const getQualifiedTitle = () => {
    return "You may be qualified for the DSCR rental flow program!"
  }

  return (
    <>
      <Row className="qualifierWrap">
        <Col md={6} className="text-center">
          <div className="d-flex align-items-center mt-3" style={{ justifyContent: "center", marginBottom: "10px" }}>
            <div className="mr-2">Change language to</div>
            <Select defaultValue="english" onChange={handleLanguageChange} options={languageOptions} />
          </div>
          {displayFlyer()}

        </Col>
        <Col md={6} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
          <>
            <div className="title-3 text-center mt-3 mb-3">GMCC - DSCR Rental Flow Program</div>
            <div className="programHighlights">
              <div className="bold"><img src="/images/bulb.svg" style={{ width: 28 }} /> Program highlights</div>
              <ul>
                <li>Only rental cash flow.</li>
                <li>Min DSCR ratio 0.75, no ratio DSCR also available.</li>
                <li>Loan amount from $100K up to $3M.</li>
                <li>Min FICO 660, down payment at least 20%</li>
                <li>Available in all 49 states where GMCC is licensed </li>
              </ul>
            </div>
          </>
          <div className="hfaSubTitle text-center mb-4 ">
            Check your eligibility for special incentive programs with attractive rates and start your homeownership journey!
          </div>


          <div className="qualifierFormWrap">
            <Form onSubmit={handleSubmit} className="qualifierForm">
              <Row>
                <Col md={12} >
                  <AddressAutocomplete
                    id={"dscr" + "address"}
                    label="Property address"
                    required={true}
                    name="addessline"
                    defaultValue={displayedAddress}
                    onChange={(e) => setDisplayedAddress(e.target.value)}
                    pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                    placeholder="Please type your home address and select from the dropdown addresses"
                    goodfeedback="Looks good!"
                    badfeedback="Please provide your street address."
                    onCompletion={e => onStreetAddressCompletion(e)}
                    size="md"
                  />
                </Col>
              </Row>
              <Row className=" ">
                <Col xs={6}>
                  <Form.Label className="text-left">Property state</Form.Label>
                  <Form.Group >
                    <Form.Control
                      required
                      size="sm"
                      as="select"
                      placeholder="State"
                      onChange={(e) => {
                        setPropertyState(e.target.value, 'borrower');
                      }}
                      value={propertyState}
                    >
                      {getStates()}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group >
                    <Form.Label className="text-left">Property county</Form.Label>
                    <Form.Control
                      required
                      size="sm"
                      as="select"
                      onChange={(e) => {
                        setPropertyCounty(e.target.value, 'borrower');
                      }}
                      value={propertyCounty}
                    >
                      {getCounties()}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              {/* <Row>
                <Col>
                  <Form.Group controlId="purpose" className="">
                    <Form.Label className="text-left">Loan purpose</Form.Label>
                    <Form.Control
                      required
                      name="purpose"
                      as="select"
                      size="md"
                      defaultValue={purpose}
                      onChange={(e) => setPurpose(e.target.value)}
                    >
                      <option value="">...</option>
                      <option value="purchase">Purchase</option>
                      <option value="rateTermRefinance">Rate/Term refinance</option>
                      <option value="cashOutRefinance">Cash out refinance</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="purpose" className="">
                    <Form.Label className="text-left">Occupancy</Form.Label>
                    <Form.Control
                      required
                      name="occupancy"
                      as="select"
                      size="md"
                      defaultValue={occupancy}
                      onChange={(e) => setOccupancy(e.target.value)}
                    >
                      <option value="">...</option>
                      <option value="primary">Primary</option>
                      <option value="secondHome">Second home</option>
                      <option value="investment">Investment(rental)</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row> */}
              <Row>
                <Col md={purpose === "cashOutRefinance" ? 4 : 6} xs={12}>
                  <Form.Group>
                    <Form.Label>Property price</Form.Label>
                    <CommaizedInput value={purchasePrice} onChange={e => setPurchasePrice(e.target.value)} withDollarSign />
                  </Form.Group>
                </Col>
                <Col md={purpose === "cashOutRefinance" ? 4 : 6} xs={12}>
                  <Form.Group>
                    <Form.Label>{purpose === "purchase" ? "Down payment" : "Current balance"}</Form.Label>
                    <CommaizedInput className="gmccCalculatorInput" value={downPayment} onChange={e => setDownPayment(e.target.value)} withDollarSign />
                  </Form.Group>
                </Col>
                {purpose === "cashOutRefinance" && <Col md={4} xs={12}>
                  <Form.Group>
                    <Form.Label>Cash out amount</Form.Label>
                    <CommaizedInput className="gmccCalculatorInput" value={cashOutAmount} onChange={e => setCashOutAmount(e.target.value)} withDollarSign />
                  </Form.Group>
                </Col>
                }
              </Row>
              {displayedLoanAmount()}
              <Row>
                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Gross monthly rental income (Optional)</Form.Label>
                    <CommaizedInput value={rentalIncome} onChange={e => setRentalIncome(e.target.value)} withDollarSign />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>FICO</Form.Label>
                    <Form.Control required type="number" placeholder="" onChange={e => setFico(e.target.value)} />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col className="d-flex align-items-center">
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label={"I am first generation home buyer"} checked={firstTimeHB} onChange={(e)=>setFirstTimeHB(e.target.checked)} />
                </Form.Group>
                </Col>
              </Row>

              <Row className="ml-2">
                <Col className="text-center" >
                  <Button type="submit" variant="zeitro-primary">{loading ? <div className="gmccSpin"><Spin /></div> : "Check my eligibility"}</Button>
                </Col>
              </Row>

            </Form>

            {!loading && showError && <div className="mx-2 mt-3">
              <Alert message="Sorry, we're unable to process the information provided. Please verify your address and select an option from the dropdown list." type="warning" showIcon />
            </div>}
            {!loading && qualified === true && <Result
              status="success"
              title={getQualifiedTitle()}
              subTitle={
                "Additional restriction required and subject to underwriting approval. Please contact your GMCC agent for more details."
              }
            />}
            {!loading && qualified === false && <Result
              status="warning"
              title="You're not qualified for this program."
              subTitle={<div>
                <div>{unqualifiedReason}</div>
                {showAddressMessage()}
                <div>You might still be able to qualify for this program with exception, please contact the GMCC loan officer for more information.</div>
              </div>}
            />}
            <Divider />
            <Row className="qualifierDisclaimerWrap">
              <div className="disclaimerContainer">
                <div className="title-6 mb-2">
                  Disclaimer:
                </div>
                <div className="title-6">
                  All loans are subject to credit approval. Interest rates are subject to change daily and without notice. Current interest rates shown our indicative of market conditions and individual qualifications and will vary upon your lock-in period, loan type, credit score, purpose and loan to value.
                </div>
              </div>
            </Row>
          </div>
        </Col>
      </Row>
      <Divider />
      <div className="mb-5">
        {contactMe}
      </div>
    </>
  );
};

export function GMCCBankStatementQualifier(props) {}


export function GMCCSpecials(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("info@gmccloan.com");
  const [nmls, setNMLS] = useState("254895");
  const [phone, setPhone] = useState("6509961982");
  const [basicInfo, setBasicInfo] = useState({});
  const [aboutInfo, setAboutInfo] = useState({});
  const [landingUrl, setLandingUrl] = useState("");
  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState("celebrity15K")
  const [width, setWidth] = useState(window.innerWidth);
  const refCalculators = useRef(null);
  const refStart = useRef(null);
  const [displayWho, setDisplayWho] = useState("")
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [subscriberFirstName, setSubscriberFirstName] = useState("");
  const [subscriberLastName, setSubscriberLastName] = useState("");
  const [subscriberEmail, setSubscriberEmail] = useState("");
  const [subscriberPhone, setSubscriberPhone] = useState("");
  const [subscriberType, setSubscriberType] = useState("");
  const [loadingSubscribe, setLoadingSubscribe] = useState(false);
  const [subscribeSuccess, setSubscribeSuccess] = useState(false);
  const [inAboutUs, setInAboutUs] = useState(false);
  
  const getProfile = () => {
    setLoading(true);
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/los/getprofilesetup", {
      cache: 'no-cache',
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        "X-CustomerID": props.customerid,
        "X-LoID": props.loid,
      },
    }).then(response => {
      if (!response.ok) {
        console.log(response)
        setLoading(false);
      } else {
        response.json().then(js => {

          let lo = js
          let su = lo.LandingPageSetUp
          let name = lo.FirstName + " " + lo.LastName
          setBasicInfo({
            file: lo.Image === '' ? [] : [
              {
                url: lo.Image
              }
            ],
            firstname: lo.FirstName,
            lastname: lo.LastName,
            nmls: lo.NMLS,
            states: typeof su.States === "undefined" ? [] : su.States,
            tagsList: typeof su.TagsList === "undefined" ? [] : su.TagsList,
            popularTags: ['I speak Mandarin', 'Top 10 Loan offier', "Bay Area for 10+ years"],
            appLink: su.appLink === undefined ? "" : su.appLink,
          })
          setName(name)
          setEmail(lo.Email)
          setPhone(lo.Phone)
          setNMLS(lo.NMLS)
          setAboutInfo({
            introduction: lo.Introduction,
            phone: lo.Phone,
            email: lo.Email,
            companyWebsite: su.CompanyWebsite === undefined ? '' : su.CompanyWebsite,
            google: su.Google === undefined ? '' : su.Google,
            yelp: su.Yelp === undefined ? '' : su.Yelp,
            linkedin: su.Linkedin === undefined ? '' : su.Linkedin,
            twitter: su.Twitter === undefined ? "" : su.Twitter,
            tagline: su.tagline === undefined ? "" : su.tagline,

            companyIntroduction: su.companyIntroduction === undefined ? "" : su.companyIntroduction,
            companyLink: su.companyLink === undefined ? "" : su.companyLink,
            banner: su.banner === undefined ? "" : su.banner,
            paramsInfo: su.paramsInfo === undefined ? {
              title: '',
              paramsList: []
            } : su.paramsInfo,
            faqInfo: su.faqInfo === undefined ? [] : su.faqInfo
          })
          setLandingUrl(window.location.hostname.includes("localhost") ? "http://localhost:3001" + js.LandingUrl : "https://" + window.location.hostname + js.LandingUrl)
          setLoading(false);

        })
      }
    }).catch(error => {
      console.log("Revalidate catch error " + error)
    })
  };
  const isMobile = width <= 480;

  const { ref, inView, entry } = useInView({
    threshold: 0,
    rootMargin: "0px 0px -900px 0px",
    triggerOnce: false,
  });


  useEffect(()=>{
    getProfile()

    const handleResize = () => setWidth(window.innerWidth);
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
    
  }, [])

  const tabsItems = [
    {
      data:"celebrity15K",
      key: 'Grab your FREE $10-30K now – Hurry, limited offer!',
      label: '$5K-30K Grant',
      children:
        <div
          id="celebrity"
        >
          <CelebrityQualifier customerid={"gmcc"} loid={props.loid} program="celebrity15K"
            appLink={basicInfo.appLink} loEmail={email} loPhone={phone} loName={name} loNMLS={nmls} basicInfo={basicInfo} aboutInfo={aboutInfo} />
        </div>
    },
    {
      data:"zeroPercentDown",
      key: 'See if you qualify for a zero down payment now!',
      label: 'Zero Down',
      children:
        <div
          id="zeroPercentDown"
        >
          <CelebrityQualifier customerid={"gmcc"} loid={props.loid} program="zeroPercentDown"
            appLink={basicInfo.appLink} loEmail={email} loPhone={phone} loName={name} loNMLS={nmls}  basicInfo={basicInfo} aboutInfo={aboutInfo} />
        </div>
    },
    {
      data:'celebrityNoratio',
      key: 'Income or job? Not required!',
      label: <div>
        <div className="label">Employment Optional</div>
        <div className="label">- 40% Down</div>
      </div>,
      children:
        <div
          id="celebrityNoratio"
        >
          <UniverseQualifier customerid={"gmcc"} loid={props.loid} type="celebrityNoratio"
            appLink={basicInfo.appLink} loEmail={email} loPhone={phone} loName={name} loNMLS={nmls}  basicInfo={basicInfo} aboutInfo={aboutInfo} />
        </div>
    },
    {
      data:'celebrityLargerAssets',
      key: 'Ideal for self-employed, retirees, home enthusiasts, investors, or high assets individuals seeking financial growth.',
      label: <div>
        <div className="label">Employment Optional</div>
        <div className="label">- Affluent</div>
      </div>,
      children:
        <div
          id="celebrityLargerAssets"
        >
          <AffluentQualifier customerid={"gmcc"} loid={props.loid}
            appLink={basicInfo.appLink} loEmail={email} loPhone={phone} loName={name} loNMLS={nmls}  basicInfo={basicInfo} aboutInfo={aboutInfo} />
        </div>
    },
    // {
    //   data:'foreigners',
    //   key: 'Unlock your dream property in the U.S.Accepts all visas (F, B, J, etc.)',
    //   label: 'Foreign Nationals',
    //   children:
    //     <div
    //       id="foreigner"
    //     >
    //       <UniverseQualifier customerid={"gmcc"} loid={props.loid} type="foreigners"
    //         loEmail={email} loPhone={phone} loName={name} loNMLS={nmls}  basicInfo={basicInfo} aboutInfo={aboutInfo} />
    //     </div>
    // },
    {
      data:'cra',
      key: 'Affordable housing program for all. Enjoy community benefits with incentives.',
      label: 'Community Benefits',
      children:
        <div
          id="cra"
        >
          <CRACalculator customerid={"gmcc"} loid={props.loid}
            appLink={basicInfo.appLink} loEmail={email} loPhone={phone} loName={name} loNMLS={nmls}  basicInfo={basicInfo} aboutInfo={aboutInfo} />
        </div>
    },
    {
      data:'voe',
      key: 'Fast-track your loan with our VVOE for W-2, self-employed P&L support, and in-house funding!',
      label: 'Verbal VOE / Borrower Prepared P & L',
      children:
        <div id="voe">
          <GMCCHermesQualifier customerid={"gmcc"} loid={props.loid}
            appLink={basicInfo.appLink} loEmail={email} loPhone={phone} loName={name} loNMLS={nmls}   basicInfo={basicInfo} aboutInfo={aboutInfo}/>
        </div>
    },
    {
      data:'withoutSell',
      key: 'Non-QM or Full doc to help house upgrade or downsize without selling first. ',
      label: 'Buy without Sell First',
      children:
      <div id="buywithoutsell">
        <GMCCBuyWithoutSellQualifier customerid={"gmcc"} loid={props.loid}
          appLink={basicInfo.appLink} loEmail={email} loPhone={phone} loName={name} loNMLS={nmls}   basicInfo={basicInfo} aboutInfo={aboutInfo}/>
      </div>

    },
    {
      data:'DSCR',
      key: 'Unlock your real estate investment potential by leveraging rental income.',
      label: 'DSCR Rental Flow',
      children:
        <div id="dscr">
          <GMCCDSCRQualifier customerid={"gmcc"} loid={props.loid}
            appLink={basicInfo.appLink} loEmail={email} loPhone={phone} loName={name} loNMLS={nmls}   basicInfo={basicInfo} aboutInfo={aboutInfo}/>
        </div>
    },
    {
      data:'bankStatementSelfEmployed',
      key: 'Coming soon',
      label: 'Bank Statement Self Employed',
      children:
        <div id="bankstatement">
          <GMCCBankStatementQualifier customerid={"gmcc"} loid={props.loid}
            appLink={basicInfo.appLink} loEmail={email} loPhone={phone} loName={name} loNMLS={nmls}   basicInfo={basicInfo} aboutInfo={aboutInfo}/>
        </div>
    },
  ]

  const applyUrl = props.loid==="gmcc" ? 
  "https://idp.elliemae.com/authorize?client_id=srtrz0d2&site_id=1796774571&response_type=code&redirect_uri=https%3A%2F%2Fwww.gmccloan.com%2Fborrower-app%2Flogin%2F%3FfromLogin%3Dtrue%26siteId%3D1796774571&scope=ccbp%20cc&instance_id=be11078237&logo_url=https%3A%2F%2Fstore.asset.ellieservices.com%2Fc8e9e1d6-64c4-47f2-8e83-3fb89b5c506f&logo_alt_text=Logo&bust=26&apiBaseUrl=https://api.elliemae.com"
  :  
  basicInfo.appLink

  const backUrl = props.url
  const findIndexByValue = (array, value) => {
    for (let i=0; i< array.length; i++) {
      const item = array[i];
      if (Object.values(item).includes(value)) {
        return i;
      }
    }
    return false;
  }
  const handleClickCalculators = (tab) => {
    let index = findIndexByValue(tabsItems, tab)
    setIsModalOpen(!isModalOpen)
    setDisplayWho(index)
    // if(refCalculators.current){
    //   setActiveTab(tab)
    //   refCalculators.current.scrollIntoView({ behavior: "smooth" });
    // }
  }
  const backToTabs = () => {
    if(refStart.current){
      refStart.current.scrollIntoView({ behavior: "smooth" });
    }
  }
  const [isModalOpen, setIsModalOpen] = useState(false);

  const submitSubscribe = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setLoadingSubscribe(true)
    setSubscribeSuccess(false)
    let url = "/borrower/gmcc/subscribespecial"
    fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
          FirstName: subscriberFirstName,
          LastName: subscriberLastName,
          Email: subscriberEmail,
          Phone: subscriberPhone,
          LoID: window.location.host.includes("special.gmccloan.com")?"gmcc":props.loid,
          LoEmail: email,
          Type: subscriberType,
        })
    })
        .then((res) => {
            if (!res.ok) {
                setLoadingSubscribe(false)
                console.log(res);
                alert("Some internal error happened. Please try again later.")
                return
            }
            setLoadingSubscribe(false)
            setSubscribeSuccess(true)
        })
        .catch((err) => {
            console.error(err);
            setLoadingSubscribe(false)
            alert("Some internal error happened. Please try again later.")
        });

  }

  const handleShowSubscribeModal = () =>{
    setSubscriberEmail("")
    setSubscriberFirstName("")
    setSubscriberLastName("")
    setSubscriberPhone("")
    setSubscriberType("")
    setSubscribeSuccess(false)
    setShowSubscribeModal(true)
  }

  const getSubscribeModal = () => {
    return (
      <BootStrapModal
        className="contactMeModal"
        size="lg"
        backdropClassName="contactMeModalBackdrop"
        dialogClassName="contactMeModalDialog"
        contentClassName="contactMeModalContent"
        show={showSubscribeModal}
        onHide={() => setShowSubscribeModal(false)}
      >
        <BootStrapModal.Dialog>
          <BootStrapModal.Header closeButton>
          </BootStrapModal.Header>
          <BootStrapModal.Body>
            <div className="d-flex justify-content-center">
              <div style={{width:"70%"}}>
                <div style={{
                  fontSize:"24px",
                  fontWeight:"500",
                  marginBottom:"40px",
                  display:"flex",
                  justifyContent:"center"
                }}>
                  {!subscribeSuccess?"Subscribe to GMCC Special Programs":""}
                </div>
                {!subscribeSuccess?
                <Form 
                  className="subscribeForm"
                  onSubmit={submitSubscribe}
                >
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="fname">
                        <Form.Label>First name</Form.Label>
                        <Form.Control type="text" placeholder="Enter your first name" required onChange={e => setSubscriberFirstName(e.target.value)} />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="lname">
                        <Form.Label>Last name</Form.Label>
                        <Form.Control type="text" placeholder="Enter your last name" required onChange={e => setSubscriberLastName(e.target.value)} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} xs={12}>
                      <Form.Group controlId="email">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" placeholder="Enter your email address" required onChange={e => setSubscriberEmail(e.target.value)} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} xs={12}>
                      <Form.Group controlId="phone">
                        <Form.Label>Phone number</Form.Label>
                        <Form.Control type="text" placeholder="Optional" value={subscriberPhone} onChange={e => setSubscriberPhone(com.fixPhoneInput(e.target.value))} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} xs={12}>
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Label>Your are a</Form.Label>
                        <Form.Control
                          as="select"
                          size="sm"
                          value={subscriberType}
                          onChange={(e) => setSubscriberType(e.target.value)}
                          type="select"
                          required
                        >
                          <option value="">...</option>
                          <option value="borrower">Borrower</option>
                          <option value="realtor">Realtor</option>
                          <option value="loanofficer">Loan Officer</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} xs={12} className="w-100 d-flex justify-content-center">
                    <Button variant="zeitro-primary" className="w-100" type="submit">
                      {loadingSubscribe ? <div className="gmccSpin"><Spin /></div> : "Subscribe"}
                    </Button>
                    </Col>
                  </Row>
                </Form>
                :
                <div style={{
                  display:"flex",
                  flexDirection:"column",
                  alignItems:"center"
                }}>
                  <img src="/images/congratulation.png" alt="" />
                  <div className="successText">Thank you for your subscription.</div>
                </div>
                }
              </div>
            </div>
          </BootStrapModal.Body>
        </BootStrapModal.Dialog>
      </BootStrapModal>
    );
  }

  const handleClickBack = () => {
    if (inAboutUs) {
      setInAboutUs(false)
    } else {
      window.location.href = backUrl
    }
  }

  return (
    <div ref={ref}>
      {!props.fromLanding && <div className="landing-nav" style={{height: 60}}>
        <div className="d-flex align-items-center">
          {!window.location.host.includes("special.gmccloan.com") && <div style={{cursor:"pointer"}} onClick={handleClickBack}>
            <LeftOutlined className='mr-2 '/>
          </div>}
          {window.location.host.includes("special.gmccloan.com") && inAboutUs && <div style={{cursor:"pointer"}} onClick={() => setInAboutUs(false)}>
            <LeftOutlined className='mr-2 '/>
          </div>}
          <div className="menu-left">
            <img src='/images/gmccLogo.png' style={{height:38, marginRight:15}}/>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div>
            {!inAboutUs && <div className="specialNavBtn" onClick={() => setInAboutUs(true)}>{"ABOUT US"}</div>}
          </div>
          <Button variant='zeitro-primary' onClick={() => window.open(applyUrl,"_blank")}>Apply</Button>
        </div>
      </div>}
      {!inAboutUs?<div ref={refStart}>
        {!props.fromLanding && <div className="specialBanner">
          <div className="bannerLeftBg">
            <div className="bannerHeader redText">GMCC SPECIALS</div>
            <div className="bannerHeader">To Help Your Mortgage Needs</div>
            <div className="subscribe-CTA" onClick={handleShowSubscribeModal}>SUBSCRIBE</div>

          </div>
          <div className="bannerRightBg">
            {/* <div className="subscribe-CTA" onClick={handleShowSubscribeModal}>SUBSCRIBE</div> */}
          </div>
        </div>}
      <ProgramInitialGuide handleClickCalculators={handleClickCalculators} />
        {!isModalOpen ? <div className="tabList">
          {tabsItems.map((item, index) => {

            return (
              <div className="tab" key={index} onClick={() => {
                setIsModalOpen(!isModalOpen)
                setDisplayWho(index)

              }} >
                <div className="tab-top">
                  <div className="label">{item.label} </div>
                  <div><RightOutlined className="label" style={{ color: '#979797' }} /></div>
                </div>
                <div className="key">{item.key}</div>
              </div>

            )

          })}
        </div>
        :
        <div className="qualifier-Wraper">
          <div className="qualifierContainer">
            <div className="d-flex align-items-center label ml-4" style={{cursor:"pointer", color: 'rgb(110, 110, 112)', fontSize:"20px", fontWeight:"600" }} onClick={()=> setIsModalOpen(false)}><LeftOutlined className="label mr-2" style={{ color: '#979797' }}/>Go back</div>
            <div>{tabsItems[displayWho].children}</div>
          </div>
        </div>
        }
        {/* <Modal open={isModalOpen} width={'100vw'} onCancel={() => setIsModalOpen(false)} onOk={() => setIsModalOpen(false)}>
          {ModalComponent}
        </Modal> */}
      {!props.fromLanding && <>
        <hr/>
        <div className="p-5">

          <GMCCDisclaimer />
        </div>
      </>}
      {
        (isMobile && inView) && <FloatingBubble
        className="gmccFloatingLabel"
        axis='y'
        magnetic='x'
        style={{
          '--initial-position-bottom': '20px',
          '--initial-position-right': '0px',
          '--edge-distance': '0px',
        }}
        onClick={()=>backToTabs()}
      >
      <div className="d-flex align-items-center">
        <UpCircleFilled style={{color:"#1F3988"}} className="mx-1"  />
        <div style={{fontSize:10}}>Back to programs</div>
      </div>
        </FloatingBubble>
        
      }
      </div>
      :
      <div className="AboutUsPane">
        <div className="AboutUsTextWrap">
          <div className="introSubTitle">About GMCC</div>
          <div className="mt-4">General Mortgage Capital Corporation (GMCC) is a Direct Lender/Mortgage Lender headquartered in California and has been expanding nationwide. Our rental cash flow mortgage programs (DSCR) also covers 49 states including Washington DC, Florida, Georgia, Hawaii, New Hampshire, New York, Ohio, South Carolina, Virginia, Washington, etc. We do specialize in Prime Jumbo/Non-QM, Agency mortgage loans with an in-house underwriting ($100k to $4 million), in-house doc and funding (up to $10 million), for efficient turnaround times with over 600 Licensed Loan Officers to help. </div>
          <div className="introSubTitle">How We Work</div>
          <div className="mt-4">Our current headquarter is located in Burlingame, CA. We have expanded our mortgage-based solutions into a wide portfolio of home purchase, refinance, and home equity products. We are currently licensed in 49 states and have earned a number of awards and accolades along the way. </div>
          <div className="mt-4">GMCC is dedicated to making the mortgage process easy. Our culture is based on honesty, humility, integrity, and service.</div>
          <div className="mt-4">Our mission is simple: to provide you with sound advice and benefit to your financial needs while facilitating a straight-forward, transparent mortgage process for buyers, sellers, and realtors. </div>
          <div className="mt-4">The goal at GMCC is to be recognized as the leading and reputable mortgage company in the nation. We begin that process by earning your trust through knowledge and communication. You, as the customer, are our first priority and we pride ourselves on developing rewarding, long-term customer relationships. </div>
        </div>
        <div className="IntroImagesWrap">
          <img className="introImage" src="/images/gmccflyers/Intro1.png" alt="" />
          <img className="introImage" src="/images/gmccflyers/Intro2.png" alt="" />
          <img className="introImage" src="/images/gmccflyers/Intro3.png" alt="" />
        </div>
        <hr />
        <div className="px-3 pt-2" style={{borderTop: "1px solid #ECECEC"}}>
          <GMCCDisclaimer />
        </div>
      </div>
      }
      {showSubscribeModal && getSubscribeModal()}
    </div>
  )
}

export function GMCCForeigners(props){
  const [name, setName] = useState("");
  const [email, setEmail] = useState("info@gmccloan.com");
  const [nmls, setNMLS] = useState("254895");
  const [phone, setPhone] = useState("6509961982");
  const [loading, setLoading] = useState(false)
  const [width, setWidth] = useState(window.innerWidth);

  const backUrl = props.url
  function goToParentPath() {
    if (props.loid==="gmcc") return
    const currentPath = window.location.pathname; 
    const pathSegments = currentPath.split('/'); 
    pathSegments.pop(); 
    const targetPath = pathSegments.join('/'); 
    window.location.pathname = targetPath;
  }
  
  const getProfile = () => {
    setLoading(true);
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/los/getprofilesetup", {
      cache: 'no-cache',
      method: 'GET',
      headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache",
          "X-CustomerID": props.customerid,
          "X-LoID": props.loid,
      },
  }).then(response => {
      if (!response.ok) {
          console.log(response)
          setLoading(false);
      } else {
          response.json().then(js => {
              let lo = js
              let name = lo.FirstName + " " + lo.LastName
              setName(name)
              setEmail(lo.Email)
              setPhone(lo.Phone)
              setNMLS(lo.NMLS)
              setLoading(false);
          })
      }
  }).catch(error => {
      console.log("Revalidate catch error " + error)
  })
  };

  useEffect(()=>{
    getProfile()

    const handleResize = () => setWidth(window.innerWidth);
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
    
  }, [])


  return <div>
    <div style={{cursor:"pointer", display:"flex", fontSize:"16px",marginTop:"10px", marginLeft:"20px"}} onClick={()=> window.location.href = backUrl}>
            <LeftOutlined className='mr-2 '/> Go back
    </div>
    <div className="title-2 text-center mt-4 mb-2">GMCC - Foreign Nationals</div>
    <div className="text-center mb-1">Designed to support borrowers who are foreign nationals</div>
    <UniverseQualifier customerid="gmcc" loid={props.loid} type="foreigners" url="www.gmccloan.com"
      loEmail={email} loPhone={phone} loName={name} loNMLS={nmls} />
    <div className='p-5'><GMCCDisclaimer /></div>
</div>
}
export function GMCCUniversePage(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("info@gmccloan.com");
  const [nmls, setNMLS] = useState("254895");
  const [phone, setPhone] = useState("6509961982");
  const [loading, setLoading] = useState(false)
  const [width, setWidth] = useState(window.innerWidth);
  
  function goToParentPath() {
    if (props.loid==="gmcc") return
    const currentPath = window.location.pathname; 
    const pathSegments = currentPath.split('/'); 
    pathSegments.pop(); 
    const targetPath = pathSegments.join('/'); 
    window.location.pathname = targetPath;
  }
  
  const getProfile = () => {
    setLoading(true);
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/los/getprofilesetup", {
      cache: 'no-cache',
      method: 'GET',
      headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache",
          "X-CustomerID": props.customerid,
          "X-LoID": props.loid,
      },
  }).then(response => {
      if (!response.ok) {
          console.log(response)
          setLoading(false);
      } else {
          response.json().then(js => {
              let lo = js
              let name = lo.FirstName + " " + lo.LastName
              setName(name)
              setEmail(lo.Email)
              setPhone(lo.Phone)
              setNMLS(lo.NMLS)
              setLoading(false);
              
          })
      }
  }).catch(error => {
      console.log("Revalidate catch error " + error)
  })
  };

  useEffect(()=>{
    getProfile()

    const handleResize = () => setWidth(window.innerWidth);
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
    
  }, [])

  const tabsItems = [
    {
      key: '1',
      label: 'Universe Rate',
      children: 
      <div
      style={{ backgroundColor: "white" }}
      className="pb-2"
      id="universe"
      >
            <GMCCUniverseCalculator customerid={"gmcc"} loid={props.loid} 
             loEmail={email} loPhone={phone} loName={name} loNMLS={nmls} hideTitle={true}/>
      </div>
    },
    {
      key: '2',
      label: 'Employment optional - 40% Down',
      children: 
      <div
      style={{ backgroundColor: "white" }}
      className="pb-2"
      id="universe"
      >
            <div className="title-3 text-center mt-3 mb-3">GMCC - Employment optional - 40% Down</div>
            <UniverseQualifier type="celebrityNoratio" customerid={"gmcc"} loid={props.loid} 
             loEmail={email} loPhone={phone} loName={name} loNMLS={nmls}/>
      </div>
    },
    {
      key: '3',
      label: 'Foreign Nationals',
      children: 
      <div
      style={{ backgroundColor: "white" }}
      className="pb-2"
      id="foreigner"
      >
            <div className="title-3 text-center mt-3 mb-3">GMCC - Foreign Nationals Program</div>
            <UniverseQualifier type="foreigners" customerid={"gmcc"} loid={props.loid} 
             loEmail={email} loPhone={phone} loName={name} loNMLS={nmls}/>
      </div>
    },
  ]
  const applyUrl = props.loid==="gmcc" ? "https://app.gmccloan.com/services/preapp?customerid=gmcc&loid=83ab3e1e-c696-4bd5-b8ca-91f567925b99":  "https://app.gmccloan.com/services/preapp?customerid=gmcc&loid="+props.loid

  return (
    <>
      <Tabs
      defaultActiveKey="1"
      centered={true ? width>768:false}
      items={tabsItems}
      className="mb-5 gmccCalculatorTabs"
      size="large"
      tabBarExtraContent = {{
        "left":<div className="specialHeaderButton" onClick={goToParentPath}>Home</div>,
        "right":<div className="specialHeaderButton" onClick={()=>window.open(applyUrl, '_blank', 'noopener,noreferrer')}>Apply</div>
      }}
      />
      <hr/>
      <div className="p-5">
        <GMCCDisclaimer />
      </div>
    </>
  )
}

