import React, { useEffect, useState } from "react"
import { Drawer, Skeleton } from "antd"
import { CloseOutlined } from "@ant-design/icons"

export default function ChatHistory({ currentThreadID, openChatHistory, setOpenChatHistory, getHistoryList }) {

    const [loadingChatList, setLoadingChatList] = useState(false)
    const [chatList, setChatList] = useState([])
    const [curId, setCurId] = useState('')

    const getChatList = async () => {
        setLoadingChatList(true)
        let token = sessionStorage.getItem("ZeitroA")
        try {
            const response = await fetch("/los/mortgagegpt/chatlist", {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache",
                },
            })

            if (!response.ok) {
                setLoadingChatList(false)
                throw new Error(`HTTP error! Status: ${response.status}`)
            }
            const data = await response.json()
            setLoadingChatList(false)
            setChatList(data.chat_list)
        } catch (error) {
          
            setLoadingChatList(false)
            console.error("Fetch Error:", error)
        }
    }

    useEffect(() => {
        getChatList()
    }, [])


    const renderChatList = () => {
        const data = chatList.map(item => {
            

            return {
                thread: item[0],
                date: new Date(item[1]).toLocaleDateString(),
                titleFallback: item[2],
                title: item[2],
                type: item[4],

            }
        })
        // const processedData = chatList.reduce((acc, item) => {
        //     const [thread, dateString, titleFallback, title, type] = item


        //     const formattedDate = new Date(dateString).toLocaleDateString()

        //     const finalTitle = title || titleFallback


        //     if (!finalTitle) return acc

        //     if (!acc[formattedDate]) {
        //         acc[formattedDate] = []
        //     }
        //     acc[formattedDate].push({ title: finalTitle, threadId: thread, type })
        //     console.log(acc)



        // }, {})
       
        const handleClick = (threadId,type) => {
            setCurId(threadId)
            getHistoryList(threadId,type)
            // window.location.href = `/app/mortgagegpt/${threadId}`
        }

        return (
            <div className="text-left">
                {
                    data.map((item, index) => {
                        return <div key={index}>
                            <div className="title-5 py-3 px-4">{item.date === new Date().toLocaleDateString() ? 'TODAY' : item.date}</div>
                            <div className={item.thread === curId ? "chatHistoryTitleCurrent py-2 px-4 " : "chatHistoryTitle py-2 px-4 "} onClick={() => handleClick(item.thread, item.type)}>
                                <div style={{ color: "#222", fontSize: 16, fontWeight: 500 }}> {item.title}</div>
                                <div style={{ color: "#545459", fontSize: 14, fontWeight: "Inter", marginTop: 10 }}><img src="/images/pdfType.svg"></img> {item.type === '' ? 'FannieMae' : item.type}</div>
                            </div>
                        </div>
                    })
                }


            </div>
        )
    }



    return (
        <>
            {
                chatList.length == 0 ? <div style={{

                    background: "#EAEFFD",
                    color: "#1F2890",
                    borderRadius: '8px',
                    padding: "15px",
                    fontSize: 14,
                    fontFamily: "Inter",

                }}>You don't have any chats yet. Select a guideline to start your conversation.</div> : <div className="chatListWrap mt-2">{loadingChatList ? <Skeleton active /> : renderChatList()}</div>

            }
        </>
        // <Drawer
        //   className="chatListDrawer"
        //   title="Basic Drawer"
        //   placement="right"
        //   closable={true}
        //   onClose={() => setOpenChatHistory(false)}
        //   open={openChatHistory}
        //   getContainer={false}>
        //   <div className="bottomBorder">
        //     <div className="pt-4 pb-3 px-4 d-flex justify-content-between">
        //       <div className="title-2">Chat history</div>
        //       <CloseOutlined
        //         className="rowLink"
        //         style={{fontSize: 20}}
        //         onClick={() => setOpenChatHistory(false)}
        //       />
        //     </div>
        //   </div>

        //   <div className="chatListWrap mt-2">{loadingChatList ? <Skeleton active /> : renderChatList()}</div>

        // </Drawer>
    )
}
