import { set } from 'lodash';
import React, { useRef, useState } from 'react';
import {Button} from 'react-bootstrap';
import { Spin, Modal, Select, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const FlyerGenerator = (props) => {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [receiver, setReceiver] = useState('lo');
  const [borrowerName, setBorrowerName] = useState('');
  const [borrowerEmail, setBorrowerEmail] = useState('');
  const [messageText, setMessageText] = useState('');
  const [messageSubject, setMessageSubject] = useState('');
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [showRatesheet, setShowRatesheet] = useState(false);
  const canvasRef = useRef(null);
  const imageSrc = props.imgsrc;

  const GMCCPPTShareLink= "https://login.microsoftonline.com/9f605dae-ab54-4576-8337-e008c4b7b2ce/oauth2/authorize?client%5Fid=00000003%2D0000%2D0ff1%2Dce00%2D000000000000&response%5Fmode=form%5Fpost&response%5Ftype=code%20id%5Ftoken&resource=00000003%2D0000%2D0ff1%2Dce00%2D000000000000&scope=openid&nonce=E6B63F52352EA44257C07F1B4D9ED60019930B8D5F0A91EA%2D935407F503D4CDC91941A8A7313D55001E663B093A1A139F1ED67B923E15AD84&redirect%5Furi=https%3A%2F%2Fnetorgft1191593%2Esharepoint%2Ecom%2F%5Fforms%2Fdefault%2Easpx&state=OD0w&claims=%7B%22id%5Ftoken%22%3A%7B%22xms%5Fcc%22%3A%7B%22values%22%3A%5B%22CP1%22%5D%7D%7D%7D&wsucxt=1&cobrandid=11bd8083%2D87e0%2D41b5%2Dbb78%2D0bc43c8a8e8a&client%2Drequest%2Did=ee534aa1%2D207d%2D0000%2D2811%2D0aebf28ef794"
  const LoannexUrl = "https://loannex.com" 
  const EPPSUrl = "https://epps.elliemae.com/pricing/client/base/"

  let PPEUrl = ""
  let DiamondUrl = ""
  if (props.ratesheet.includes("Epps")){
    PPEUrl = EPPSUrl
  }
  if (props.ratesheet.includes("Loannex")){
    PPEUrl = LoannexUrl
  }
  if (props.ratesheet.includes("DiamondLink")){
    DiamondUrl = "https://hub.collateralanalytics.com/correspondentsearch"
  }


  const drawTextAndDownload = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const image = new Image();
    image.src = imageSrc;
    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);

      const disclaimer1 = `All special programs are subject to underwriting approval verification, guideline change without advance notice.`
      const disclaimer2 = `Contact me to clarify your qualification of the below program for this address.This does not represent credit decision.`
      let idLine = "NMLS# " + props.nmls + "  " + props.dre;
      if (props.dre === ""){
        idLine = "NMLS#  " + props.nmls
      }
      const contactLine = props.email + "  " + props.phone;
      const textMargin = 50; // Adjust this value to change the margin from the right edge
      const idLineWidth = ctx.measureText(idLine).width;
      const contactLineWidth = ctx.measureText(contactLine).width;
      const nameWidth = ctx.measureText(props.name).width;
      const addressWidth = ctx.measureText(props.addressInfo).width;
      const disclaimer1Width = ctx.measureText(disclaimer1).width;
      const disclaimer2Width = ctx.measureText(disclaimer2).width;
      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;

      const reTag = "Real Estate"
      const loTag = "Loan officer"
      const reID = "ID#"+"  "+props.reID
      const reContactLine1 = props.rePhone
      const reContactLine2 = props.reEmail
      const loContactLine1 = props.phone
      const loContactLine2 = props.email

      ctx.textAlign = 'center';
      ctx.textbaseline = 'middle';
      ctx.font = '600 48px Arial';
      ctx.fillStyle = 'black';
      //filltext to let text be centered horizontally

      if (props.hasRE) {

        // Realtor info in left half
        ctx.textAlign = 'left';
        ctx.font = '600 30px Arial';
        ctx.fillStyle = 'black';
        ctx.fillText(reTag, 30, 150);
        ctx.fillText(props.reName, 30, 180);
        ctx.font = '600 30px Arial';
        ctx.fillText(reID, 30, 210);
        ctx.fillText(reContactLine1, 30, 240);
        ctx.fillText(reContactLine2, 30, 270);

        // LO info in right half
        ctx.textAlign = 'right';
        ctx.font = '600 30px Arial';
        ctx.fillText(loTag, canvas.width - 30, 150);
        ctx.fillText(props.name, canvas.width - 30, 180);
        ctx.font = '600 30px Arial';
        ctx.fillText(idLine, canvas.width - 30, 210);
        ctx.fillText(loContactLine1, canvas.width - 30, 240);
        ctx.fillText(loContactLine2, canvas.width - 30, 270);

        // Address info and disclaimers at the bottom
        ctx.textAlign = 'center';
        ctx.font = '600 38px Arial';
        ctx.fillStyle = '#5D94FF';
        ctx.fillText(props.addressInfo, centerX, 330);

        ctx.font = '400 16px Arial';
        ctx.fillStyle = 'black';
        ctx.fillText(disclaimer1, centerX, 350);
        ctx.fillText(disclaimer2, centerX, 370);
      } else {
        ctx.fillText(props.name,(canvas.width - nameWidth)/2+40 , 170);
        ctx.font = '600 28px Arial';
        ctx.fillStyle = 'black';
        ctx.fillText(idLine, (canvas.width - idLineWidth)/2+40, 200);
        ctx.fillText(contactLine, (canvas.width - contactLineWidth)/2+100, 230);
        ctx.font = '600 40px Arial';
        ctx.fillStyle = '#5D94FF';
        ctx.fillText(props.addressInfo, (canvas.width - addressWidth)/2 + 100, 280);
        ctx.font = '400 16px Arial';
        ctx.fillStyle = 'black';
        ctx.fillText(disclaimer1, (canvas.width - disclaimer1Width)/2 + 240, 320);
        ctx.fillText(disclaimer2, (canvas.width - disclaimer2Width)/2 + 260, 340);

      }      

      const imageURL = canvas.toDataURL('image/png');
      setPreviewImage(imageURL);
      const link = document.createElement('a');
      link.download = `${props.programName}_Flyer.png`;
      link.href = canvas.toDataURL('image/png');
      link.click();
    };
  };
  const drawTextAndPreview = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const image = new Image();
    image.src = imageSrc;
    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);

      const disclaimer1 = `All special programs are subject to underwriting approval verification, guideline change without advance notice.`
      const disclaimer2 = `Contact me to clarify your qualification of the below program for this address.This does not represent credit decision.`
      let idLine = "NMLS# " + props.nmls + "  " + props.dre;
      if (props.dre === ""){
        idLine = "NMLS#  " + props.nmls
      }
      const contactLine = props.email + "  " + props.phone;
      const textMargin = 50; // Adjust this value to change the margin from the right edge
      const idLineWidth = ctx.measureText(idLine).width;
      const contactLineWidth = ctx.measureText(contactLine).width;
      const nameWidth = ctx.measureText(props.name).width;
      const addressWidth = ctx.measureText(props.addressInfo).width;
      const disclaimer1Width = ctx.measureText(disclaimer1).width;
      const disclaimer2Width = ctx.measureText(disclaimer2).width;

      const reTag = "Real Estate"
      const loTag = "Loan officer"
      const reID = "ID#"+"  "+props.reID
      const reContactLine1 = props.rePhone
      const reContactLine2 = props.reEmail
      const loContactLine1 = props.phone
      const loContactLine2 = props.email

      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;

      ctx.textAlign = 'center';
      ctx.textbaseline = 'middle';
      ctx.font = '600 48px Arial';
      ctx.fillStyle = 'black';
      //filltext to let text be centered horizontally

      if (props.hasRE) {

        // Realtor info in left half
        ctx.textAlign = 'left';
        ctx.font = '600 30px Arial';
        ctx.fillStyle = 'black';
        ctx.fillText(reTag, 70, 150);
        ctx.fillText(props.reName, 70, 180);
        ctx.font = '600 30px Arial';
        ctx.fillText(reID, 70, 210);
        ctx.fillText(reContactLine1, 70, 240);
        ctx.fillText(reContactLine2, 70, 270);

        // LO info in right half
        ctx.textAlign = 'right';
        ctx.font = '600 30px Arial';
        ctx.fillText(loTag, canvas.width - 70, 150);
        ctx.fillText(props.name, canvas.width - 70, 180);
        ctx.font = '600 30px Arial';
        ctx.fillText(idLine, canvas.width - 70, 210);
        ctx.fillText(loContactLine1, canvas.width - 70, 240);
        ctx.fillText(loContactLine2, canvas.width - 70, 270);

        // Address info and disclaimers at the bottom
        ctx.textAlign = 'center';
        ctx.font = '600 38px Arial';
        ctx.fillStyle = '#5D94FF';
        ctx.fillText(props.addressInfo, centerX, 330);

        ctx.font = '400 16px Arial';
        ctx.fillStyle = 'black';
        ctx.fillText(disclaimer1, centerX, 350);
        ctx.fillText(disclaimer2, centerX, 370);
      } else {
        ctx.fillText(props.name,(canvas.width - nameWidth)/2+40 , 170);
        ctx.font = '600 28px Arial';
        ctx.fillStyle = 'black';
        ctx.fillText(idLine, (canvas.width - idLineWidth)/2+40, 200);
        ctx.fillText(contactLine, (canvas.width - contactLineWidth)/2+100, 230);
        ctx.font = '600 40px Arial';
        ctx.fillStyle = '#5D94FF';
        ctx.fillText(props.addressInfo, (canvas.width - addressWidth)/2 + 100, 280);
        ctx.font = '400 16px Arial';
        ctx.fillStyle = 'black';
        ctx.fillText(disclaimer1, (canvas.width - disclaimer1Width)/2 + 240, 320);
        ctx.fillText(disclaimer2, (canvas.width - disclaimer2Width)/2 + 260, 340);

      }
      const imageURL = canvas.toDataURL();
      setPreviewImage(imageURL);
      setShowRatesheet(false);
      setPreviewModalOpen(true);
    };
  };

  const drawTextAndEmail = () => {
    setLoading(true);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let imageDataURL = "";
    const image = new Image();
    image.src = imageSrc;
    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);

      const disclaimer1 = `All special programs are subject to underwriting approval verification, guideline change without advance notice.`
      const disclaimer2 = `Contact me to clarify your qualification of the below program for this address.This does not represent credit decision.`
      let idLine = "NMLS# " + props.nmls + "  " + props.dre;
      if (props.dre === ""){
        idLine = "NMLS#  " + props.nmls
      }
      const contactLine = props.email + "  " + props.phone;
      const textMargin = 50; // Adjust this value to change the margin from the right edge
      const idLineWidth = ctx.measureText(idLine).width;
      const contactLineWidth = ctx.measureText(contactLine).width;
      const nameWidth = ctx.measureText(props.name).width;
      const addressWidth = ctx.measureText(props.addressInfo).width;
      const disclaimer1Width = ctx.measureText(disclaimer1).width;
      const disclaimer2Width = ctx.measureText(disclaimer2).width;
      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;

      const reTag = "Real Estate"
      const loTag = "Loan officer"
      const reID = "ID#"+"  "+props.reID
      const reContactLine1 = props.rePhone
      const reContactLine2 = props.reEmail
      const loContactLine1 = props.phone
      const loContactLine2 = props.email

      ctx.textAlign = 'center';
      ctx.textbaseline = 'middle';
      ctx.font = '600 48px Arial';
      ctx.fillStyle = 'black';
      //filltext to let text be centered horizontally

      if (props.hasRE) {

        // Realtor info in left half
        ctx.textAlign = 'left';
        ctx.font = '600 30px Arial';
        ctx.fillStyle = 'black';
        ctx.fillText(reTag, 30, 150);
        ctx.fillText(props.reName, 30, 180);
        ctx.font = '600 30px Arial';
        ctx.fillText(reID, 30, 210);
        ctx.fillText(reContactLine1, 30, 240);
        ctx.fillText(reContactLine2, 30, 270);

        // LO info in right half
        ctx.textAlign = 'right';
        ctx.font = '600 30px Arial';
        ctx.fillText(loTag, canvas.width - 30, 150);
        ctx.fillText(props.name, canvas.width - 30, 180);
        ctx.font = '600 30px Arial';
        ctx.fillText(idLine, canvas.width - 30, 210);
        ctx.fillText(loContactLine1, canvas.width - 30, 240);
        ctx.fillText(loContactLine2, canvas.width - 30, 270);

        // Address info and disclaimers at the bottom
        ctx.textAlign = 'center';
        ctx.font = '600 38px Arial';
        ctx.fillStyle = '#5D94FF';
        ctx.fillText(props.addressInfo, centerX, 330);

        ctx.font = '400 16px Arial';
        ctx.fillStyle = 'black';
        ctx.fillText(disclaimer1, centerX, 350);
        ctx.fillText(disclaimer2, centerX, 370);
      } else {
        ctx.fillText(props.name,(canvas.width - nameWidth)/2+40 , 170);
        ctx.font = '600 28px Arial';
        ctx.fillStyle = 'black';
        ctx.fillText(idLine, (canvas.width - idLineWidth)/2+40, 200);
        ctx.fillText(contactLine, (canvas.width - contactLineWidth)/2+100, 230);
        ctx.font = '600 40px Arial';
        ctx.fillStyle = '#5D94FF';
        ctx.fillText(props.addressInfo, (canvas.width - addressWidth)/2 + 100, 280);
        ctx.font = '400 16px Arial';
        ctx.fillStyle = 'black';
        ctx.fillText(disclaimer1, (canvas.width - disclaimer1Width)/2 + 240, 320);
        ctx.fillText(disclaimer2, (canvas.width - disclaimer2Width)/2 + 260, 340);

      }

      let dataURL = canvas.toDataURL('image/png');
      let base64Data = dataURL.replace(/^data:image\/png;base64,/, "");
      let body = {
        imageData: base64Data,
        programName: props.programName,
        loName: props.name,
        subjectAddr: props.addressInfo,
        loPhone: props.phone,
        loEmail: props.email,
        nmls: props.nmls,
        dre: props.dre,
        receiver: receiver,
        loID: props.loID,
        borrowerName: borrowerName,
        borrowerEmail: borrowerEmail,
        messageText: messageText,
        messageSubject: messageSubject,
      }
      fetch('/los/gmcc/sendflier', {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then((res) => {
        if (!res.ok) {
          setLoading(false);
          console.log(res);
          alert('Failed to send email, please try again later or contact support.');
          return;
        }

        setLoading(false);
        setIsModalOpen(false);
        alert('Flier sent successfully!');

      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        alert('Some internal error happened, please try again later or contact support.');
      });
      
    };
  }

  const priceItems = [
    {
      label: <div onClick={()=> window.open(EPPSUrl,"_blank")}>
        EPPS
      </div>,
      key: '1',
    },
    {
      label: <div onClick={()=> window.open(LoannexUrl,"_blank")}>
        Loannex
      </div>,
      key: '2',
    }
  ]

  return (
    <div>
      <Modal
        open={isModalOpen}
        onCancel={()=> setIsModalOpen(false)}
        onOk={drawTextAndEmail}
        title="Send Flier"
        okText="Send"
      >
        <div className='d-flex justify-content-center w-100'>
          { loading ? <Spin /> :
            <div className='w-100'>
              <div style={{fontWeight:"500", marginTop:"10px"}}>Email to :</div>
              <Select 
                style={{width: '100%'}}
                placeholder="Select a receiver"
                onChange={(value) => setReceiver(value)}
                value={receiver}
                options = {[
                  {value: 'lo', label: 'Myself'},
                  {value: 're', label: 'Realtor'},
                  {value: 'bo', label: 'Borrower'},
                ]}
              />
              {receiver === "bo" && <>
                <div style={{fontWeight:"500", marginTop:"10px"}}>Borrower Name:</div>
                <input className='sendFlierInput' type="text" value={borrowerName} onChange={(e) => setBorrowerName(e.target.value)} />
                <div style={{fontWeight:"500", marginTop:"10px"}}>Borrower Email:</div>
                <input className='sendFlierInput' type="email" value={borrowerEmail} onChange={(e) => setBorrowerEmail(e.target.value)} />
              </>}
              {receiver === "re" && <>
                <div style={{fontWeight:"500", marginTop:"10px"}}>Realtor Name:</div>
                <input className='sendFlierInput' type="text" value={borrowerName} onChange={(e) => setBorrowerName(e.target.value)} />
                <div style={{fontWeight:"500", marginTop:"10px"}}>Realtor Email:</div>
                <input className='sendFlierInput' type="email" value={borrowerEmail} onChange={(e) => setBorrowerEmail(e.target.value)} />
                <div style={{fontWeight:"500", marginTop:"10px"}}>Email Subject:</div>
                <input className='sendFlierInput' type="text" value={messageSubject} onChange={(e) => setMessageSubject(e.target.value)} />
                <div style={{fontWeight:"500", marginTop:"10px"}}>Message:</div>
                <textarea className='sendFlierInput' style={{height: '100px'}}
                  placeholder="Type your message here..."
                  value={messageText}
                  onChange={(e) => setMessageText(e.target.value)}
                />
                </>
              }
            </div>
          }
        </div>
      </Modal>
      <Modal
        className='flyerPreviewModal'
        rootClassName='flyerPreviewModalRoot'
        open={previewModalOpen}
        onCancel={()=> setPreviewModalOpen(false)}
        title="Flyer Preview"
        footer={null}
      >
        <div className='d-flex justify-content-center w-100'>
          <img className='w-100' src={showRatesheet? props.ratesheet : previewImage} />
        </div>
      </Modal>
      <canvas style={{display: props.display}} ref={canvasRef} ></canvas>
      <div className='d-flex'>
      <Button className={ props.buttonClass} onClick={drawTextAndPreview}>Preview</Button>
        <Button className={props.disabled? "flyerBtn-disabled": props.buttonClass} onClick={drawTextAndDownload}>Download</Button>
        <Button className={props.disabled? "flyerBtn-disabled": props.buttonClass} onClick={()=> setIsModalOpen(true)}>Email</Button>
        {props.ratesheet === "" ?
          ""
          :
          props.ratesheet.includes("Epps") || props.ratesheet.includes("Loannex") ?
            <Button className={props.buttonClass} onClick={()=> window.open(PPEUrl,"_blank")}>Pricing</Button>
            :
            props.ratesheet.includes("DiamondLink") ?
            <Button className={props.buttonClass} onClick={()=> window.open(DiamondUrl,"_blank")}>Pricing</Button>
            :
            props.ratesheet.includes("Multi") ?
            <Dropdown menu={{items: priceItems}}>
                <Button className={props.buttonClass}>
                  Pricing
                  <DownOutlined className='ml-1' />
                </Button>
            </Dropdown>
            :
            props.ratesheet.includes("Celebrity") ?
            <Button className={props.buttonClass} onClick={()=> props.gotoCelebrity()}>Pricing</Button>
            :
            <Button className={props.buttonClass} onClick={()=> {setPreviewModalOpen(true); setShowRatesheet(true)}}>Pricing</Button>
        }
        <Button className={props.buttonClass} onClick={()=> window.open(GMCCPPTShareLink, "_blank")}>Guideline</Button>
      </div>
    </div>
  );
};
export default FlyerGenerator;