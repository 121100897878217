import React, { useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import { Helmet } from 'react-helmet';
import { pdfjs } from 'react-pdf';
import Chat from './Chat';
import "./MortgageGPT.css";
import smartlookClient from "smartlook-client";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function MortgageGPT() {

  useEffect(() => {
    console.log("hostname", window.location.hostname)
    smartlookClient.init('702c7d241073da6ad788698d48d75342c29fa91b')
    smartlookClient.record({
      emails: true,
      forms: true,
      numbers: true,
      ips: true,
    })
  },[])

  // const sc = `window.smartlook||(function(d) {
  //   var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
  //   var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
  //   c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
  //   })(document);
  //   smartlook('init', '702c7d241073da6ad788698d48d75342c29fa91b', { region: 'eu' });
  //   smartlook('record', { forms: true, numbers: true, emails: true, ips: true });`

  return (
    <div className="">
      {/* {!window.location.hostname.includes("localhost") && (
        <Helmet>
          <script type="text/javascript">{sc}</script>
        </Helmet>
      )} */}
      <Chat />
    </div>
  );
}

export default MortgageGPT;
